<template>
	<div>
		<auth-container v-if="$route.name === 'DamList'" :page="page">
			<h2 class="mb-4">Dams</h2>

			<div class="mx-auto">
				<b-row class="mb-3" align-v="end">
					<b-col lg="6">
						<b-form inline>
							<b-input-group>
								<b-form-input v-model="table.filter" placeholder="Search..." @input="filterChg"></b-form-input>

								<b-input-group-append class="pointer mr-2">
									<b-input-group-text @click="clearFilter">
										<font-awesome-icon :icon="['fas', 'times']" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
							<b-button variant="primary" type="button" @click="table.showAdvancedFilter = !table.showAdvancedFilter">Advanced Filter</b-button>
						</b-form>
					</b-col>
					<b-col lg="6" class="text-right">
						<div class="ml-auto">
							Showing {{showingFirst}} - {{showingLast}}
							of {{table.total}}
						</div>
					</b-col>
				</b-row>

				<b-collapse v-model="table.showAdvancedFilter" class="my-3">
					<b-card bg-variant="light">
						<b-row>
							<b-col lg="4">
								<div class="form-group row">
									<label for="advancedFilter.swcd" class="col-lg-5 col-form-label">SWCD</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.swcd"
													 v-model="advancedFilter.swcd"
													 :data="advancedFilter.options.swcd"
													 :serializer="s => `${s.id} - ${s.name}`"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.county" class="col-lg-5 col-form-label">County</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.county"
													 v-model="advancedFilter.county"
													 :data="advancedFilter.options.county"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.wshed_name" class="col-lg-5 col-form-label">Watershed</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.wshed_name"
													 v-model="advancedFilter.wshed_name"
													 :data="advancedFilter.options.wshed_name"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.river_basin" class="col-lg-5 col-form-label">River Basin</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.river_basin"
													 v-model="advancedFilter.river_basin"
													 :data="advancedFilter.options.river_basin"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>
							</b-col>
							<b-col lg>
								<div class="form-group row">
									<label for="advancedFilter.tceqHazardClass" class="col-lg-3">TCEQ Hazard Class</label>
									<div class="col-lg-9">
										<b-form-checkbox-group v-model="advancedFilter.tceqHazardClass" :options="advancedFilter.options.tceqHazardClass"></b-form-checkbox-group>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.nrcs_haz" class="col-lg-3">NRCS Hazard Class</label>
									<div class="col-lg-9">
										<b-form-checkbox-group v-model="advancedFilter.nrcs_haz" :options="advancedFilter.options.nrcs_haz"></b-form-checkbox-group>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.dsgn_haz" class="col-lg-3">Hazard Design</label>
									<div class="col-lg-9">
										<b-form-checkbox-group v-model="advancedFilter.dsgn_haz" :options="advancedFilter.options.dsgn_haz"></b-form-checkbox-group>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.yr_comp.start" class="col-lg-3 col-form-label">Year Completed Range</label>
									<div class="col-lg-9">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-form-input v-model="advancedFilter.yr_comp.start" type="number" placeholder="Start"></b-form-input>
											</div>
											<div>
												<b-form-input v-model="advancedFilter.yr_comp.end" type="number" placeholder="End"></b-form-input>
											</div>
										</div>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.yearEvalLifeExpired.start" class="col-lg-3 col-form-label">Life Exp. Year Range</label>
									<div class="col-lg-9">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-form-input v-model="advancedFilter.yearEvalLifeExpired.start" type="number" placeholder="Start"></b-form-input>
											</div>
											<div>
												<b-form-input v-model="advancedFilter.yearEvalLifeExpired.end" type="number" placeholder="End"></b-form-input>
											</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
						<hr class="my-3" />
						<div>
							<b-button variant="primary" type="button" @click="get" class="mr-2">Apply</b-button>
							<b-button variant="secondary" type="button" @click="clearAdvancedFilter" class="mr-2">Clear</b-button>
							<b-button variant="secondary" type="button" @click="table.showAdvancedFilter = false">Close</b-button>
						</div>
					</b-card>
				</b-collapse>

				<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
					<b-tab title="Table">
						<b-table striped responsive table-class="table-fixed-height border-bottom table-grid" sticky-header="60vh"
								 small show-empty
								 :items="items" :busy="table.loading"
								 :fields="table.fields"
								 :no-local-sorting="true"
								 :sort-by.sync="table.sort"
								 :sort-desc.sync="table.reverse"
								 @sort-changed="sortChg">
							<template #table-busy>
								<div class="text-center my-4">
									<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
								</div>
							</template>
							<template #cell(edit)="data">
								<router-link :to="`${data.item.id}`" append>
									<font-awesome-icon :icon="['fas', 'edit']" />
								</router-link>
							</template>
							<template #cell(swcd)="data">
								<router-link v-if="data.value.id !== null" :to="`/swcds/${data.value.id}`">{{data.value.id}} - {{data.value.name}}</router-link>
							</template>
							<template #empty>
								<h4>No records found.</h4>
							</template>
							<template #emptyfiltered>
								<h4>No records found matching "{{table.filter}}".</h4>
							</template>
						</b-table>
					</b-tab>
					<b-tab title="Map" @click.prevent="renderMap">
						<div class="map-container w-100" style="height:500px">
							<l-map ref="leafmap" style="height: 100%; width:100%" :zoom="map.zoom" :center="map.center" @mousemove="mapMove" :bounds="map.bounds">
								<l-control-layers position="topright"></l-control-layers>
								<l-tile-layer v-for="tileProvider in mapBase.tileProviders"
											  :key="tileProvider.name"
											  :name="tileProvider.name"
											  :visible="tileProvider.visible"
											  :url="tileProvider.url"
											  :attribution="tileProvider.attribution"
											  layer-type="base" />

								<l-layer-group layer-type="overlay" name="SWCDs">
									<l-geo-json :geojson="map.layers.swcds.geojson" :options="map.layers.swcds.options" :options-style="map.layers.swcds.style"></l-geo-json>
								</l-layer-group>
								<l-layer-group layer-type="overlay" name="Dams">
									<l-geo-json :geojson="map.layers.dams.geojson" :options="map.layers.dams.options"></l-geo-json>
								</l-layer-group>

								<l-layer-group layer-type="overlay" v-for="(extraLayer, i) in map.additionalLayers" :key="i" :name="extraLayer.name" :ref="`extraLayer${i}`" :visible="false">
									<l-geo-json :geojson="extraLayer.geojson" :options="mapExtraLayersOptions" :options-style="mapExtraLayersStyle(i)"></l-geo-json>
								</l-layer-group>

								<l-control position="bottomleft">
									<div v-if="map.currentCoords !== undefined">Cursor position: {{map.currentCoords.lat | number(3)}}, {{map.currentCoords.lng | number(3)}}</div>
								</l-control>
							</l-map>
							<div v-if="map.loading" class="map-loading-overlay">
								<font-awesome-icon :icon="['fas', 'spinner']" spin size="3x" />
							</div>
						</div>
					</b-tab>
				</b-tabs>


			</div>

			<fixed-action-bar>
				<router-link v-if="!isReadOnly" to="create" append class="btn btn-success mr-2">
					Create
				</router-link>
				<json-to-csv :api-url="`dams/export`" :filename="`dams`" class="mr-2"
							 :ignore-fields="['id', 'swcdModel', 'omReimbursementRequestActivities', 'omProposalActivities', 'omInkindMatchActivities']" />
				<b-button v-if="isAuthorized(roleNames.superAdmin)" variant="primary" @click="page.showModal = true" class="mr-2">Import NRCS</b-button>
				<b-button v-if="isAuthorized(roleNames.superAdmin)" variant="primary" @click="page.showModal2 = true" class="mr-2">Import TX</b-button>
				<b-pagination class="ml-auto mb-0" v-if="table.total > table.itemsPerPage" size="md" :total-rows="table.total" v-model="table.currentPage" :per-page="table.itemsPerPage" @change="pageChg" />
			</fixed-action-bar>

			<b-modal v-model="page.showModal" title="Upload Dams" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<div slot="modal-footer">
					<json-to-csv :json-data="damTemplate" filename="dams-template" text="Download Template" class="mr-2" />
					<b-button type="button" variant="primary" @click="page.showModal = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.showModal2" title="Upload Dams Additional Information" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert2" text="Changes saved." />

				<vue-dropzone ref="addFile2" id="addFile2" :options="dropzoneOptions2" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<div slot="modal-footer">
					<json-to-csv :json-data="damTxTemplate" filename="dams-tx-template" text="Download Template" class="mr-2" />
					<b-button type="button" variant="primary" @click="page.showModal2 = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="modals.dam.show" :title="`Dam: ` + (modals.dam.item !== null ? `${modals.dam.item.nid_id} - ${modals.dam.item.name}` : '')" scrollable size="lg">
				<error-list :errors="modals.dam.errors"></error-list>
				<page-loading :loading="modals.dam.loading"></page-loading>

				<div v-if="!modals.dam.loading && modals.dam.errors.length < 1 && modals.dam.item !== null">
					<b-table-simple small>
						<b-tbody>
							<b-tr>
								<b-th>County</b-th>
								<b-td>{{modals.dam.item.county}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>SWCD</b-th>
								<b-td>{{modals.dam.item.swcd}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Watershed</b-th>
								<b-td>{{modals.dam.item.watershed}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>River Basin</b-th>
								<b-td>{{modals.dam.item.riverBasin}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Hazard Class</b-th>
								<b-td>{{modals.dam.item.hazardClass}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Hazard Design</b-th>
								<b-td>{{modals.dam.item.hazardDesign}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Year Completed</b-th>
								<b-td>{{modals.dam.item.yearCompleted}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Life Expiration Year</b-th>
								<b-td>{{modals.dam.item.lifeExpirationYear}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</div>

				<div slot="modal-footer">
					<b-button v-if="modals.dam.item !== null" type="button" variant="primary" :to="`/dams/${modals.dam.item.id}`" @click="modals.dam.show = false">Go to Dam</b-button>
					<b-button type="button" variant="secondary" @click="modals.dam.show = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import _ from 'underscore';

	import L from 'leaflet';
	import { LMap, LTileLayer, LControl, LControlLayers, LGeoJson, LLayerGroup } from 'vue2-leaflet';
	import 'leaflet/dist/leaflet.css';
	var bbox = require('geojson-bbox');

	export default {
		name: 'DamList',
		components: {
			vueDropzone: vue2Dropzone, LMap, LTileLayer, LControl, LControlLayers, LGeoJson, LLayerGroup
		},
		data() {
			return {
				apiUrl: 'dams',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					showModal: false,
					showModal2: false,
					tabIndex: 0
				},
				dropzoneOptions: {
					url: '/api/dams/upload/',
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				dropzoneOptions2: {
					url: '/api/dams/uploadtx/',
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'dam_name', label: 'Name', sortable: true },
						{ key: 'nid_id', label: 'NID_ID', sortable: true },
						{ key: 'swcd', label: 'SWCD', sortable: true },
						{ key: 'county', sortable: true },
						{ key: 'wshed_name', label: 'Watershed', sortable: true },
						{ key: 'tceqHazardClass', label: 'TCEQ Haz', sortable: true },
						{ key: 'nrcs_haz', label: 'NRSC Haz', sortable: true },
						{ key: 'dsgn_haz', label: 'Haz Design', sortable: true },
						{ key: 'yr_comp', label: 'Year Completed', sortable: true },
						{ key: 'river_basin', label: 'River Basin', sortable: true },
						{ key: 'yearEvalLifeExpired', label: 'Life Expiration Year', sortable: true },
						{ key: 'repairNeedCostTotal', label: 'Total Repair Need Cost', sortable: true, formatter: (value) => { return this.money(value); } },
						{ key: 'dateRepairCompleted', label: 'Date Repair Completed', sortable: true, formatter: (value) => { return this.valueOrNa(value); } },
						{ key: 'finalRepairCostTotal', label: 'Total Final Repair Cost', sortable: true, formatter: (value) => { return this.money(value); } }
					],
					hidden: ['id'],
					sort: 'dam_name',
					reverse: false,
					itemsPerPage: 100,
					filter: null,
					currentPage: 1,
					total: 0,
					loading: false,
					showAdvancedFilter: false
				},
				items: [],
				advancedFilter: {
					swcd: null,
					county: null,
					wshed_name: null,
					river_basin: null,
					tceqHazardClass: [],
					nrcs_haz: [],
					dsgn_haz: [],
					yr_comp: {
						start: null,
						end: null
					},
					yearEvalLifeExpired: {
						start: null,
						end: null
					},
					options: {
						swcd: [],
						county: [],
						wshed_name: [],
						river_basin: [],
						tceqHazardClass: ['H', 'L', 'S'],
						nrcs_haz: ['H', 'L', 'S'],
						dsgn_haz: ['H', 'L', 'S']
					}
				},
				map: {
					bounds: null,
					loading: false,
					currentCoords: undefined,
					layers: {
						swcds: {
							geojson: null,
							options: {},
							style: {
								weight: 1,
								opacity: 1,
								color: '#9dd123',
								fillOpacity: 0.5,
								fillColor: '#ceff5e'
							}
						},
						dams: {
							geojson: null,
							options: {},
							style: {
								radius: 4,
								weight: 1,
								opacity: 1,
								color: '#9b3ee8',
								fillOpacity: 0.8,
								fillColor: '#ca93f9'
							}
						}
					},
					additionalLayers: [],
					filter: {
						withOm: false,
						dam: null,
						swcd: null
					}
				},
				modals: {
					swcd: {
						show: false,
						loading: false,
						errors: [],
						item: null
					},
					dam: {
						show: false,
						loading: false,
						errors: [],
						item: null
					}
				},
				damTemplate: [{
					dam_name: null,
					other_names: null,
					former_names: null,
					fed_id: null,
					nid_id: null,
					long_deg: null,
					lat_deg: null,
					geodetic_loc: null,
					county: null,
					stream: null,
					nearest_town: null,
					dist_town: null,
					owner_name: null,
					owner_type: null,
					dam_dsgnr: null,
					nfdfp: null,
					dam_type: null,
					core: null,
					fndn: null,
					purposes: null,
					yr_comp: null,
					yr_mod: null,
					dam_len: null,
					dam_ht: null,
					str_ht: null,
					hydr_ht: null,
					max_disc: null,
					max_stor: null,
					norm_stor: null,
					surf_a: null,
					da: null,
					nrcs_haz: null,
					spwy_w: null,
					auth: null,
					wshed_no: null,
					wshed_name: null,
					serv_life: null,
					pop_risk: null,
					pop_acc: null,
					dsgn_haz: null,
					sed_stor: null,
					fld_stor: null,
					sur_stor: null,
					oth_stor: null,
					ps_type: null,
					as1_type: null,
					as2_type: null,
					as3_type: null,
					cond_ht: null,
					cond_w: null,
					no_cond: null,
					cool_water: null,
					ps_disch: null,
					river_basin: null,
					status: null,
					tceq_unsafe: null,
					swcd: null,
					repairType: null,
					repairNeedEstEngrServices: null,
					repairNeedEstConstruction: null,
					repairNeedCostTotal: null,
					dateRepairCompleted: null,
					finalRepairCostStateFunds: null,
					finalRepairCostOtherFunds: null,
					finalRepairCostTotal: null,
					comments: null,
					yearEvalLifeExpired: null
				}],
				damTxTemplate: [{
					nid_id: null,
					isOmEligible: null,
					isSrEligible: null,
					senateDistrict: null,
					houseDistrict: null,
					watershed: null,
					regionalFloodPlanningGroupId: null,
					regionalFloodPlanningGroupName: null,
					eapStatus: null,
					eapTriggerDate: null,
					eapNotes: null,
					wasDamAssessmentPerformed: null,
					damAssessmentCompletionDate: null,
					tceqHazardClass: null,
					inspectionAgency: null,
					inspectionDate: null,
					remedialOrderDate: null,
				}]
			}
		},
		async created() {
			await this.get(true);
			//this.renderMap();
		},
		watch: {
			'$route': 'get',
			advancedFilterCounty: _.debounce(function (query) { this.find('county', query) }, 500),
			advancedFilterWshedName: _.debounce(function (query) { this.find('wshed_name', query) }, 500),
			advancedFilterRiverBasin: _.debounce(function (query) { this.find('river_basin', query) }, 500),
			advancedFilterSwcd: _.debounce(function (query) { this.findSwcds(query) }, 500)
		},
		methods: {
			getDb() {
				let sort = this.table.reverse ? this.table.sort + '_desc' : this.table.sort;
				let filter = this.table.filter !== null ? `&filter=${encodeURIComponent(this.table.filter)}` : '';

				let filterComplex = '';
				let filterValues = [];
				if (!this.isNullOrEmpty(this.advancedFilter.swcd)) {
					let val = this.advancedFilter.swcd;
					let parts = this.advancedFilter.swcd.split('-');
					if (parts.length > 0) val = parts[0].trim();
					filterValues.push(`swcd:${val}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.county)) {
					filterValues.push(`county:${this.advancedFilter.county}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.wshed_name)) {
					filterValues.push(`wshed_name:${this.advancedFilter.wshed_name}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.river_basin)) {
					filterValues.push(`river_basin:${this.advancedFilter.river_basin}`);
				}
				if (this.advancedFilter.tceqHazardClass.length > 0) {
					filterValues.push(`tceqHazardClass:${this.advancedFilter.tceqHazardClass.join(',')}`);
				}
				if (this.advancedFilter.nrcs_haz.length > 0) {
					filterValues.push(`nrcs_haz:${this.advancedFilter.nrcs_haz.join(',')}`);
				}
				if (this.advancedFilter.dsgn_haz.length > 0) {
					filterValues.push(`dsgn_haz:${this.advancedFilter.dsgn_haz.join(',')}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.yr_comp.start) || !this.isNullOrEmpty(this.advancedFilter.yr_comp.end)) {
					let s = this.advancedFilter.yr_comp.start;
					let e = this.advancedFilter.yr_comp.end;
					s = s === null ? 'any' : s;
					e = e === null ? 'any' : e;
					filterValues.push(`yr_comp:${s},${e}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.yearEvalLifeExpired.start) || !this.isNullOrEmpty(this.advancedFilter.yearEvalLifeExpired.end)) {
					let s = this.advancedFilter.yearEvalLifeExpired.start;
					let e = this.advancedFilter.yearEvalLifeExpired.end;
					s = s === null ? 'any' : s;
					e = e === null ? 'any' : e;
					filterValues.push(`yearEvalLifeExpired:${s},${e}`);
				}

				if (filterValues.length > 0) filterComplex = '&filterComplex=' + filterValues.join('|');

				return this.$http.get(`${this.apiUrl}?sort=${sort}&page=${this.table.currentPage}&perPage=${this.table.itemsPerPage}${filter}${filterComplex}`, this.getTokenHeader());
			},
			async filterChg() {
				this.table.currentPage = 1;
				_.debounce(await this.get(), 500);
			},
			async pageChg(page) {
				this.table.currentPage = page;
				await this.get();
			},
			async sortChg(ctx) {
				this.table.sort = ctx.sortBy;
				this.table.reverse = ctx.sortDesc;
				this.table.specialSort = undefined;

				await this.get();
			},
			async get(init = false) {
				this.page.errors = [];
				this.table.loading = true;
				this.map.loading = true;

				try {
					const response = await this.getDb();
					this.log(response.data);

					if (response.data.table.items.length == 0 && this.table.currentPage > 1) {
						this.table.currentPage--;
						await this.get();
					}

					this.items = response.data.table.items;
					this.table.total = response.data.table.total;

					this.map.layers.swcds.geojson = response.data.geojson.swcds;
					this.map.layers.dams.geojson = response.data.geojson.dams;
					this.map.additionalLayers = response.data.geojson.layers;

					if (init) {
						this.map.layers.swcds.options = {
							onEachFeature: this.mapSwcdsOnEachFeature
						};
						this.map.layers.dams.options = {
							pointToLayer: (feature, latLng) => L.circleMarker(latLng, this.map.layers.dams.style),
							onEachFeature: this.mapDamsOnEachFeature
						};
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.table.loading = false;
				this.map.loading = false;
				this.page.loading = false;
			},
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				await this.$refs.damsTable.get();
				this.$refs.savedAlert.startAlert();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async findSwcds(query) {
				try {
					const response = await this.$http.get(`/swcds/find-id/${query}`, this.getTokenHeader());
					this.advancedFilter.options.swcd = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async clearFilter() {
				this.table.filter = null;
				await this.get();
			},
			async clearAdvancedFilter() {
				this.advancedFilter = {
					swcd: null,
					county: null,
					wshed_name: null,
					river_basin: null,
					tceqHazardClass: [],
					nrcs_haz: [],
					dsgn_haz: [],
					yr_comp: {
						start: null,
						end: null
					},
					yearEvalLifeExpired: {
						start: null,
						end: null
					},
					options: {
						swcd: [],
						county: [],
						wshed_name: [],
						river_basin: [],
						tceqHazardClass: ['H', 'L', 'S'],
						nrcs_haz: ['H', 'L', 'S'],
						dsgn_haz: ['H', 'L', 'S']
					}
				};

				await this.get();
			},
			mapMove(e) {
				this.map.currentCoords = e.latlng;
			},
			async mapSwcdClick(e) {
				this.modals.swcd.item = e.target.feature.properties;
				this.modals.swcd.errors = [];
				this.modals.swcd.show = true;
			},
			async mapDamClick(e) {
				this.modals.dam.item = e.target.feature.properties;
				this.modals.dam.errors = [];
				this.modals.dam.show = true;
			},
			renderMap() {
				window.dispatchEvent(new Event('resize'));
				var ref = this.$refs.leafmap;
				var self = this;
				this.$nextTick(function () {
					ref.mapObject.invalidateSize(true);
					self.setMapBounds();
					self.page.tabIndex = 1;
				});
			},
			setMapBounds() {
				if (!this.isNullOrEmpty(this.map.layers.swcds.geojson)) {
					let bounds = bbox(this.map.layers.swcds.geojson);
					this.log(bounds);
					this.map.bounds = L.latLngBounds([[bounds[3], bounds[2]], [bounds[1], bounds[0]]]);
				}
			},
			mapExtraLayersStyle(i) {
				let color = this.getMapLayerColor(i);

				return {
					weight: 1,
					opacity: 1,
					color: color,
					fillOpacity: 0.2,
					fillColor: color
				};
			}
		},
		computed: {
			showingFirst() {
				return (this.table.currentPage - 1) * this.table.itemsPerPage + 1
			},
			showingLast() {
				var max = (this.table.currentPage - 1) * this.table.itemsPerPage + this.table.itemsPerPage
				return max > this.table.total ? this.table.total : max;
			},
			advancedFilterCounty() {
				return this.advancedFilter.county;
			},
			advancedFilterWshedName() {
				return this.advancedFilter.wshed_name;
			},
			advancedFilterRiverBasin() {
				return this.advancedFilter.river_basin;
			},
			advancedFilterSwcd() {
				return this.advancedFilter.swcd;
			},
			mapDamsOnEachFeature() {
				return (feature, layer) => {
					let popText = `<div>Dam ${feature.properties.nid_id} - ${feature.properties.name}</div>`;

					layer.on({
						click: this.mapDamClick
					});

					layer.bindTooltip(
						popText,
						{ permanent: false, sticky: true });
				};
			},
			mapSwcdsOnEachFeature() {
				return (feature, layer) => {
					let popText = `<div>SWCD# ${feature.properties.id} - ${feature.properties.name}</div>`;

					/*layer.on({
						click: this.mapSwcdClick
					});*/

					layer.bindTooltip(
						popText,
						{ permanent: false, sticky: true });
				};
			},
			mapExtraLayersOptions() {
				return {
					onEachFeature: this.mapLayersOnEachFeature
				};
			},
			mapLayersOnEachFeature() {
				return (feature, layer) => {
					let label = this.isNullOrEmpty(feature.properties.label) ? "" : feature.properties.label;
					let popText = `<div><div><b>${label} ${feature.properties.name}</b></div>`;

					let keys = Object.keys(feature.properties);
					let ignoreKeys = ['id', 'name', 'label'];
					for (let k of keys) {
						if (!ignoreKeys.includes(k))
							popText += `<div>${k} - ${feature.properties[k]}</div>`;
					}
					popText += '</div>';

					layer.bindTooltip(
						popText,
						{ permanent: false, sticky: true });
				};
			}
		}
	}
</script>