<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<h3 class="h5 border-bottom my-4">Projects Referenced in the Work Order</h3>

			<b-row>
				<b-col md="6">
					<b-typeahead placeholder="Type to search projects by number, name, or dam..." :minMatchingChars="2"
								 :data="options.projects == null ? [] : options.projects"
								 v-model="query.project"
								 :serializer="s => s.name" @hit="query.projectObject = $event; addProjectRelation()"
								 :max-matches="100">
					</b-typeahead>

					<div>
						<b-button v-for="(p, i) in projectRelations" :key="i" variant="light" size="sm" class="text-left my-2 d-flex align-items-center" block 
							v-b-tooltip.hover="'Click to remove from work order'" @click="removeProjectRelation(p)">
							<span>{{p.name}}</span>
							<font-awesome-icon :icon="['fas', 'times']" class="text-danger ml-auto" />
						</b-button>
					</div>
				</b-col>
			</b-row>

			<h3 class="h5 border-bottom my-4">Work Order Information</h3>

			<b-row>
				<b-col md>
					<b-form-group label="Work Order #" :invalid-feedback="requiredFeedback($v.item.workOrderNumber)">
						<b-form-input type="text" required
									  v-model.trim="$v.item.workOrderNumber.$model" :state="getValidState($v.item.workOrderNumber)" />
					</b-form-group>

					<b-form-group label="Revision #" :invalid-feedback="requiredFeedback($v.item.revision)">
						<b-form-input type="number"
									  v-model.trim.number="$v.item.revision.$model" :state="getValidState($v.item.revision)" />
					</b-form-group>

					<b-form-group label="PO#" :invalid-feedback="requiredFeedback($v.item.purchaseOrder)">
						<b-form-input type="text"
									  v-model.trim="$v.item.purchaseOrder.$model" :state="getValidState($v.item.purchaseOrder)" />
					</b-form-group>

					<b-form-group label="PO Issuance Date" :invalid-feedback="requiredFeedback($v.item.purchaseOrderDate)">
						<b-form-input type="date" v-model.trim="$v.item.purchaseOrderDate.$model" :state="getValidState($v.item.purchaseOrderDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Name" :invalid-feedback="requiredFeedback($v.item.name)">
						<b-form-input type="text"
									  v-model.trim="$v.item.name.$model" :state="getValidState($v.item.name)" />
					</b-form-group>

					<b-form-group label="Project Manager" :invalid-feedback="requiredFeedback($v.item.projectManager)">
						<b-typeahead v-model.trim="$v.item.projectManager.$model"
									 :data="options.projectManager"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Contractor" :invalid-feedback="requiredFeedback($v.item.contractor)">
						<b-typeahead v-model.trim="$v.item.contractor.$model"
									 :data="options.contractor"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Primary Point of Contact" :invalid-feedback="requiredFeedback($v.item.pocPrimary)">
						<b-typeahead v-model.trim="$v.item.pocPrimary.$model"
									 :data="options.pocPrimary"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Secondary Point of Contact" :invalid-feedback="requiredFeedback($v.item.pocSecondary)">
						<b-typeahead v-model.trim="$v.item.pocSecondary.$model"
									 :data="options.pocSecondary"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Funding Type" :invalid-feedback="requiredFeedback($v.item.fundingType)">
						<b-typeahead v-model.trim="$v.item.fundingType.$model"
									 :data="setOptions.fundingTypes"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Funding Subcategory" :invalid-feedback="requiredFeedback($v.item.fundingSubcategory)">
						<b-typeahead v-model.trim="$v.item.fundingSubcategory.$model"
									 :data="setOptions.fundingSubcategories"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Fiscal Year" :invalid-feedback="requiredFeedback($v.item.fundingFiscalYear)">
						<b-form-input type="number"
									  v-model.trim.number="$v.item.fundingFiscalYear.$model" :state="getValidState($v.item.fundingFiscalYear)" />
					</b-form-group>

					<b-form-group label="Project Type" :invalid-feedback="requiredFeedback($v.item.projectType)">
						<b-typeahead v-model.trim="$v.item.projectType.$model"
									 :data="setOptions.projectTypes"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Project Subtype" :invalid-feedback="requiredFeedback($v.item.projectSubtype)">
						<b-typeahead v-model.trim="$v.item.projectSubtype.$model"
									 :data="setOptions.projectSubtypes"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Execution Date" :invalid-feedback="requiredFeedback($v.item.executionDate)">
						<b-form-input type="date" v-model.trim="$v.item.executionDate.$model" :state="getValidState($v.item.executionDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Expiration Date" :invalid-feedback="requiredFeedback($v.item.expirationDate)">
						<b-form-input type="date" v-model.trim="$v.item.expirationDate.$model" :state="getValidState($v.item.expirationDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Completion Date" :invalid-feedback="requiredFeedback($v.item.completionDate)">
						<b-form-input type="date" v-model.trim="$v.item.completionDate.$model" :state="getValidState($v.item.completionDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<hr class="my-4" />

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)" class="mb-4">
				<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="5"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, integer } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrWorkOrderForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			projects: {
				type: Array,
				required: true
			},
			setOptions: {
				type: Object,
				default: () => {
					return {
						fundingTypes: [],
						fundingSubcategories: [],
						projectTypes: [],
						projectSubtypes: []
					}
				}
			}
		},
		data() {
			return {
				apiUrl: 'srworkorders',
				editRoute: 'SrWorkOrderEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					projects: [],
					projectManager: [],
					contractor: [],
					pocPrimary: [],
					pocSecondary: []
				},
				query: {
					project: '',
					projectObject: null
				},
				projectRelations: []
			}
		},
		computed: {
			queryProject() {
				return this.query.project;
			},
			itemProjectManager() {
				return this.item.projectManager;
			},
			itemContractor() {
				return this.item.contractor;
			},
			itemPocPrimary() {
				return this.item.pocPrimary;
			},
			itemPocSecondary() {
				return this.item.pocSecondary;
			},
			projectRelationIds() {
				let ids = [];
				for (let p of this.projectRelations) {
					ids.push(p.id);
				}
				return ids;
			}
		},
		validations: {
			item: {
				workOrderNumber: { required },
				revision: { integer },
				purchaseOrder: {},
				purchaseOrderDate: {},
				name: {},
				executionDate: {},
				expirationDate: {},
				projectManager: {},
				contractor: {},
				pocPrimary: {},
				pocSecondary: {},
				fundingType: {},
				fundingSubcategory: {},
				fundingFiscalYear: {},
				projectType: {},
				projectSubtype: {},
				notes: {},
				completionDate: {}
			}
		},
		watch: {
			queryProject: _.debounce(function (query) { this.findProjects(query) }, 500),
			itemProjectManager: _.debounce(function (query) { this.find('projectManager', query) }, 500),
			itemContractor: _.debounce(function (query) { this.find('contractor', query) }, 500),
			itemPocPrimary: _.debounce(function (query) { this.find('pocPrimary', query) }, 500),
			itemPocSecondary: _.debounce(function (query) { this.find('pocSecondary', query) }, 500)
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				this.projectRelations = this.projects;

				if (this.isUpdate) {
					this.item.executionDate = this.dateForForm(this.item.executionDate);
					this.item.expirationDate = this.dateForForm(this.item.expirationDate);
					this.item.purchaseOrderDate = this.dateForForm(this.item.purchaseOrderDate);
					this.item.completionDate = this.dateForForm(this.item.completionDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else if (this.projectRelationIds.length < 1) {
					this.page.errors.push('You must add at least one project to the work order.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							let data = {
								item1: this.item,
								item2: this.projectRelationIds
							};
							const response = await this.$http.post(this.apiUrl, data, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: {
								item1: this.item,
								item2: this.projectRelationIds
							},
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async findProjects(query) {
				try {
					//const response = await this.$http.get(`/srprojects/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					const response = await this.$http.post(`/srprojects/find-id-post`, { value: query }, this.getTokenHeader());
					this.options.projects = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			addProjectRelation() {
				if (!this.isNullOrEmpty(this.query.projectObject)) {
					this.projectRelations.push(this.query.projectObject);
					this.query.project = '';
					this.query.projectObject = null;
				}
			},
			removeProjectRelation(p) {
				this.projectRelations = this.projectRelations.filter(function (el) { return el.id !== p.id; });
			}
		}
	}
</script>
