<template>
	<auth-container :page="page">
		<h2 class="mb-4">Dam Information</h2>

		<dam-form :item="item"></dam-form>
	</auth-container>
</template>

<script>
	import DamForm from './DamForm.vue';
	export default {
		name: 'DamCreate',
		components: {
			DamForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					id: 0,
					dam_name: null,
					nid_id: null,
					county: null,
					long_deg: 0,
					lat_deg: 0
				}
			}
		}
	}
</script>