<template>
	<div>
		<auth-container v-if="$route.name === 'SponsorList'" :page="page">
			<h2 class="mb-4">Sponsors</h2>

			<grid-view ref="gridTable" api-url="omsponsors" small use-filter :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
					   collection-description="sponsors" show-create
					   item-name="sponsor"
					   allow-export export-file-name="om-sponsors" :export-ignore-fields="['omReimbursementRequests','omProposals','omInkindMatches']">
				<b-button v-if="isAuthorized(roleNames.superAdmin)" variant="primary" @click="page.showModal = true">Import</b-button>
			</grid-view>

			<b-modal v-model="page.showModal" title="Upload Sponsors" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<p class="mt-3">
					Upload a csv of sponsors with the following columns (headers on first line):<br />
					<code>
						Id, Name, Type, Address, Address2, City, Zip, Phone, Fax, SponsorEmail, ContactName, ContactEmail, Other
					</code>.
				</p>

				<div slot="modal-footer">
					<b-button type="button" variant="primary" @click="page.showModal = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'SponsorList',
		components: {
			GridView, vueDropzone: vue2Dropzone
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					showModal: false
				},
				dropzoneOptions: {
					url: '/api/omsponsors/upload/',
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'id', label: 'Sponsor #', sortable: true },
						{ key: 'type', sortable: true },
						{ key: 'name', sortable: true },
						{ key: 'address', sortable: true },
						{ key: 'city', sortable: true },
						{ key: 'zip', sortable: true }
					],
					sort: 'id',
					reverse: false,
					itemsPerPage: 100
				}
			}
		},
		methods: {
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				await this.$refs.gridTable.get();
				this.$refs.savedAlert.startAlert();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			}
		}
	}
</script>