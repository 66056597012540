<template>
	<div>
		<auth-container :page="page" v-if="$route.name === 'SrProjectEdit'">
			<h2 class="mb-4">Project #{{item.projectNumber}}</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview">
					<b-row>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Project #</b-th>
										<b-td>{{valueOrNa(item.projectNumber)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Project Name</b-th>
										<b-td>{{valueOrNa(item.name)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Expected Completion Date</b-th>
										<b-td>{{item.expectedCompletionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Completion Letter Submission Date</b-th>
										<b-td>{{item.completionSubmissionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Dam</b-th>
										<b-td>
											<span v-if="isNullOrEmpty(application.damId)">N/A</span>
											<router-link v-else :to="`/dams/${application.damId}`">{{dam.name}}</router-link>
										</b-td>
									</b-tr>
									<b-tr v-if="!isNullOrEmpty(application.damId)">
										<b-th><font-awesome-icon :icon="['fas', 'arrow-right']" fixed-width class="mx-3" /> Total Repair Need Cost</b-th>
										<b-td>
											{{money(dam.repairNeedCostTotal)}}
										</b-td>
									</b-tr>
									<b-tr v-if="!isNullOrEmpty(application.damId)">
										<b-th><font-awesome-icon :icon="['fas', 'arrow-right']" fixed-width class="mx-3" /> Repair Completed Date</b-th>
										<b-td>
											{{valueOrNa(dam.dateRepairCompleted)}}
										</b-td>
									</b-tr>
									<b-tr v-if="!isNullOrEmpty(application.damId)">
										<b-th><font-awesome-icon :icon="['fas', 'arrow-right']" fixed-width class="mx-3" /> Total Final Repair Cost</b-th>
										<b-td>
											{{money(dam.finalRepairCostTotal)}}
										</b-td>
									</b-tr>
									<b-tr>
										<b-th>Overall Status</b-th>
										<b-td>{{valueOrNa(item.status)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Status Notes</b-th>
										<b-td>{{valueOrNa(item.statusNote, 'None')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Land Rights Status</b-th>
										<b-td>{{valueOrNa(item.landRightsStatus)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Land Rights Status Changed Date</b-th>
										<b-td>{{item.landRightsStatusDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Land Rights Status Notes</b-th>
										<b-td>{{valueOrNa(item.landRightsNotes, 'None')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>CoE 404 Permit Status</b-th>
										<b-td>{{valueOrNa(item.coe404PermitStatus)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>CoE 404 Permit Status Changed Date</b-th>
										<b-td>{{item.coeStatusDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>CoE 404 Permit Status Notes</b-th>
										<b-td>{{valueOrNa(item.coeNotes, 'None')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>General Notes</b-th>
										<b-td>{{valueOrNa(item.notes, 'None')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Category</b-th>
										<b-td>{{valueOrNa(item.category)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Current Phase</b-th>
										<b-td>{{valueOrNa(item.phase)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>A&amp;E Assignment</b-th>
										<b-td>{{valueOrNa(item.aeAssignment)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Project Manager</b-th>
										<b-td>{{valueOrNa(item.projectManager)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Flood Control Program Staff</b-th>
										<b-td>{{valueOrNa(item.fcpStaff)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>TPWD ARRP Status</b-th>
										<b-td>{{valueOrNa(item.tpwdArrpStatus)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>TPWD ARRP Status Changed Date</b-th>
										<b-td>{{item.tpwdArrpStatusDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>TPWD ARRP Status Notes</b-th>
										<b-td>{{valueOrNa(item.tpwdArrpNotes, 'None')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>O&amp;M Agreement Status</b-th>
										<b-td>{{valueOrNa(item.omAgreementStatus)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>O&amp;M Agreement Status Changed Date</b-th>
										<b-td>{{item.omAgreementStatusDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>O&amp;M Agreement Status Notes</b-th>
										<b-td>{{valueOrNa(item.omAgreementNotes, 'None')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Sponsor Status</b-th>
										<b-td>{{valueOrNa(item.sponsorStatus)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Sponsor Status Notes</b-th>
										<b-td>{{valueOrNa(item.sponsorStatusNote, 'None')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
					</b-row>

					<h3 class="mb-3">Plan</h3>
					<p v-if="isNullOrEmpty(plan)">
						There is no plan for this project. <router-link v-if="!isReadOnly" :to="`/sr/plans/create?appId=${itemId}`">Create a plan.</router-link>
					</p>
					<div v-else>
						<b-table-simple small responsive class="border-bottom">
							<b-thead class="bg-light">
								<b-tr>
									<b-th></b-th>
									<b-th>Total Cost</b-th>
									<b-th>Design Subtotal</b-th>
									<b-th>Land Rights Subtotal</b-th>
									<b-th>Construction Subtotal</b-th>
									<b-th>Federal Subtotal</b-th>
									<b-th>State Subtotal</b-th>
									<b-th>SLO Subtotal</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr>
									<b-td>
										<router-link :to="`/sr/plans/${itemId}`" title="View/Edit">
											<font-awesome-icon :icon="['fas', 'edit']" />
										</router-link>
									</b-td>
									<b-td>${{plan.totalCost | number(2)}}</b-td>
									<b-td>${{plan.designSubtotal | number(2)}}</b-td>
									<b-td>${{plan.landRightsSubtotal | number(2)}}</b-td>
									<b-td>${{plan.constructionSubtotal | number(2)}}</b-td>
									<b-td>${{plan.federalSubtotal | number(2)}}</b-td>
									<b-td>${{plan.stateSubtotal | number(2)}}</b-td>
									<b-td>${{plan.sponsorsSubtotal | number(2)}}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</div>

					<h3 class="mb-3">Application</h3>
					<div>
						<b-table-simple small responsive class="border-bottom">
							<b-thead class="bg-light">
								<b-tr>
									<b-th></b-th>
									<b-th>App. #</b-th>
									<b-th>Date Received</b-th>
									<b-th>Request Type</b-th>
									<b-th>Funding Cycle</b-th>
									<b-th>SLO</b-th>
									<b-th>Primary Name</b-th>
									<b-th>Alternate Name</b-th>
									<b-th>Total Est. Cost</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr>
									<b-td>
										<router-link :to="`/sr/applications/${itemId}`" title="View/Edit">
											<font-awesome-icon :icon="['fas', 'edit']" />
										</router-link>
									</b-td>
									<b-td>{{valueOrNa(application.appNum)}}</b-td>
									<b-td>{{application.receivedDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									<b-td>{{valueOrNa(application.requestType)}}</b-td>
									<b-td>{{valueOrNa(application.fundCycle)}}</b-td>
									<b-td>{{valueOrNa(application.appSlo)}}</b-td>
									<b-td>{{valueOrNa(application.primaryName)}}</b-td>
									<b-td>{{valueOrNa(application.alternateName)}}</b-td>
									<b-td>{{money(application.totalEstimatedCost)}}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</div>



					<fixed-action-bar>
						<b-button type="button" variant="primary" to="print" append target="_blank" class="mr-2">Print</b-button>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<sr-project-form ref="itemForm" :item="item" :dam="dam" :application="application" :set-options="setOptions" is-update @change="changesSaved"></sr-project-form>
				</b-tab>
				<b-tab title="Files">
					<file-manager :apiUrl="apiUrl" :files="files" :itemId="itemId" itemLabel="application" @change="filesChanged"></file-manager>
				</b-tab>
				<b-tab title="Change Log">
					<log-viewer db-table="SrProjects" :item-id="itemId"></log-viewer>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this project?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import FileManager from '@/components/FileManager';
	import SrProjectForm from './SrProjectForm.vue';

	export default {
		name: 'SrProjectEdit',
		components: {
			SrProjectForm, FileManager
		},
		data() {
			return {
				apiUrl: 'srprojects',
				listRoute: 'SrProjectList',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				item: {},
				application: {},
				dam: {},
				plan: {},
				files: [],
				setOptions: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.project;
					this.dam = response.data.dam;
					this.plan = response.data.plan;
					this.files = response.data.files;
					this.application = response.data.application;
					this.setOptions = response.data.setOptions;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
				this.page.tabIndex = 1;
			},
			async filesChanged() {
				await this.get();
				this.page.tabIndex = 2;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			}
		}
	}
</script>