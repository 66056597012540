<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Construction Phase</h2>

		<sr-construction-form :item="item" :project="project" :agreement="null"></sr-construction-form>
	</auth-container>
</template>

<script>
	import SrConstructionForm from './SrConstructionForm.vue';
	export default {
		name: 'SrConstructionCreate',
		components: {
			SrConstructionForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					applicationId: null,
					bidEngEst: 0,
					bidEngEstDate: null,
					bidEngEstNotes: null,
					baseContractAmount: 0,
					stateCostSharePercent: 0,
					sponsorAdminCostSharePercent: 0,
					agreementPoNum: null,
					finalBid: 0,
					finalBidDate: null,
					inspectingAgent: null,
					finalCost: 0,
					asBuiltReceivedDate: null,
					asBuiltNotes: null,
				},
				project: null
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				if (!this.isNullOrEmpty(this.$route.query.appId)) {
					try {
						const response = await this.$http.get(`srprojects/getname/${this.$route.query.appId}`, this.getTokenHeader());
						this.project = response.data;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.loading = false;
			}
		}
	}
</script>