<template>
	<div>
		<auth-container v-if="$route.name === 'UserList'" :page="page" require-admin>
			<h2 class="mb-4">Users</h2>

			<grid-view ref="userTable" api-url="users" use-filter small
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
					   collection-description="users" show-create
					   item-name="user">
				<b-button v-if="isAuthorized(roleNames.superAdmin)" variant="primary" @click="page.showModal = true">Import</b-button>
			</grid-view>

			<b-modal v-model="page.showModal" title="Upload Users" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<p class="mt-3">
					Upload a csv of users with the following columns (headers on first line):
					<code>UserName, Email, FirstName, LastName, Title, Password</code>.
					Passwords must be at least 10 characters with a number and special character.
				</p>

				<div slot="modal-footer">
					<b-button type="button" variant="primary" @click="page.showModal = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'UserList',
		components: {
			GridView, vueDropzone: vue2Dropzone
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					showModal: false
				},
				dropzoneOptions: {
					url: '/api/users/upload/',
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'isAdmin', label: '' },
						{ key: 'userName', sortable: true },
						{ key: 'lastLoginDate', label: 'Last Log-in', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } },
						{ key: 'email', sortable: true },
						{ key: 'firstName', sortable: true },
						{ key: 'lastName', sortable: true },
						{ key: 'title', sortable: true },
						{ key: 'registrationDate', label: 'Registered', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'isLockedOut', label: 'Locked?', sortable: true }
					],
					sort: 'lastLoginDate',
					reverse: true,
					itemsPerPage: 50
				}
			}
		},
		methods: {
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				await this.$refs.userTable.get();
				this.$refs.savedAlert.startAlert();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			}
		}
	}
</script>