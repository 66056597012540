<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col v-for="field in fields" :key="field.name" :md="field.fullWidth ? 12 : 6">
					<b-form-group :label="field.label + (field.required ? ' (required)' : '')" :invalid-feedback="requiredFeedback($v.item[field.name])">
						<b-form-input :type="['integer','decimal'].includes(field.type) ? 'number': field.type" :readonly="isUpdate && field.readOnly"
									  :required="field.required" :step="field.type === 'decimal' ? 'any' : '1'"
									  v-model.trim="$v.item[field.name].$model" :state="getValidState($v.item[field.name])" />
					</b-form-group>
				</b-col>
			</b-row>

			<div v-if="checkboxFields.length > 0">
				<hr class="my-4" />
				<b-row>
					<b-col md="4" v-for="field in checkboxFields" :key="field.name">
						<b-form-checkbox v-model.trim="$v.item[field.name].$model" :state="getValidState($v.item[field.name])">{{field.label}}</b-form-checkbox>
					</b-col>
				</b-row>
				<hr class="my-4" />
			</div>

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)">
				<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="5"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
				<b-button v-if="isAuthorized(roleNames.admin) && isUpdate" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Upload Dams" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this entry?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';

	export default {
		name: 'SrContactForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				apiUrl: 'srcontacts',
				editRoute: 'SrContactEdit',
				listRoute: 'SrContactList',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				fields: [
					{ name: 'firstName', label: 'First Name', type: 'text', required: false, readOnly: false },
					{ name: 'lastName', label: 'Last Name', type: 'text', required: false, readOnly: false },
					{ name: 'nickname', label: 'Nickname', type: 'text', required: false, readOnly: false },
					{ name: 'category', label: 'Category', type: 'text', required: false, readOnly: false },
					{ name: 'organization', label: 'Organization', type: 'text', required: false, readOnly: false },
					{ name: 'jobTitle', label: 'Job Title', type: 'text', required: false, readOnly: false },
					{ name: 'officePhone', label: 'Office Phone', type: 'text', required: false, readOnly: false },
					{ name: 'officePhoneExt', label: 'Office Phone Extension', type: 'text', required: false, readOnly: false },
					{ name: 'mobilePhone', label: 'Mobile Phone', type: 'text', required: false, readOnly: false },
					{ name: 'email', label: 'Email', type: 'email', required: false, readOnly: false },
					{ name: 'alternateEmails', label: 'Alternate Emails', type: 'text', required: false, readOnly: false, fullWidth: true },
					{ name: 'addressNumber', label: 'Address Street Number', type: 'text', required: false, readOnly: false },
					{ name: 'addressStreet', label: 'Address Street', type: 'text', required: false, readOnly: false },
					{ name: 'addressSub', label: 'Address Sub', type: 'text', required: false, readOnly: false },
					{ name: 'addressSuite', label: 'Address Suite', type: 'text', required: false, readOnly: false },
					{ name: 'city', label: 'City', type: 'text', required: false, readOnly: false },
					{ name: 'state', label: 'State', type: 'text', required: false, readOnly: false },
					{ name: 'zip', label: 'Zip', type: 'text', required: false, readOnly: false }
				],
				checkboxFields: [
					{ name: 'sponNotice', label: 'Sponsor Notice?' }
				]
			}
		},
		validations() {
			let item = {};
			for (let field of this.fields) {
				if (field.type === 'decimal') {
					if (field.required) item[field.name] = { required, decimal };
					else item[field.name] = { decimal };
				} else if (field.type === 'integer') {
					if (field.required) item[field.name] = { required, integer };
					else item[field.name] = { integer };
				} else if (field.type === 'text' || field.type === 'email') {
					if (field.required) item[field.name] = { required };
					else item[field.name] = {};
				} else {
					item[field.name] = {};
				}
			}
			for (let field of this.checkboxFields) {
				item[field.name] = {};
			}

			item.notes = {}
			return { item: item };
		},
		methods: {
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;
				this.logValidations();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.item.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
				if (this.$v.$invalid) {
					for (let field of this.fields) {
						let invalid = this.$v.item[field.name].$invalid;
						this.log(`${field.name}: ${invalid}`);
					}
				}
			}
		}
	}
</script>
