<template>
	<div>
		<auth-container :page="page" v-if="$route.name === 'SrApplicationEdit'">
			<h2 class="mb-4">Application #{{item.appNum}}</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview">
					<b-row>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Request Type</b-th>
										<b-td>{{valueOrNa(item.requestType)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Dam</b-th>
										<b-td>
											<span v-if="isNullOrEmpty(item.damId)">N/A</span>
											<router-link v-else :to="`/dams/${item.damId}`">{{dam.name}}</router-link>
										</b-td>
									</b-tr>
									<b-tr>
										<b-th>Application SLO</b-th>
										<b-td>{{valueOrNa(item.appSlo)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Primary Name</b-th>
										<b-td>{{valueOrNa(item.primaryName)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Alternate Name</b-th>
										<b-td>{{valueOrNa(item.alternateName)}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Date Received</b-th>
										<b-td>{{item.receivedDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Funding Cycle</b-th>
										<b-td>{{valueOrNa(item.fundCycle)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Score</b-th>
										<b-td>{{valueOrNa(item.score)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Score Date</b-th>
										<b-td>{{item.scoreDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Notes</b-th>
										<b-td>{{valueOrNa(item.notes, 'None')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
					</b-row>

					<h3 class="mb-3">Cost Estimates</h3>
					<b-table-simple small responsive class="border-bottom">
						<b-thead class="bg-light">
							<b-tr>
								<b-th>Total Estimated Cost</b-th>
								<b-th>Estimated Federal Cost Share</b-th>
								<b-th>Estimated State Cost Share</b-th>
								<b-th>Estimated Sponsors/Applicants Cost Share</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr>
								<b-td>${{item.totalEstimatedCost | number(2)}}</b-td>
								<b-td>${{item.federalMatch | number(2)}}</b-td>
								<b-td>${{item.stateMatch | number(2)}}</b-td>
								<b-td>${{item.sponsorMatch | number(2)}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<h3 class="mb-3">Plan</h3>
					<p v-if="isNullOrEmpty(plan)">
						There is no plan for this application. <router-link v-if="!isReadOnly" :to="`/sr/plans/create?appId=${itemId}`">Create a plan.</router-link>
					</p>
					<div v-else>
						<b-table-simple small responsive class="border-bottom">
							<b-thead class="bg-light">
								<b-tr>
									<b-th></b-th>
									<b-th>Total Cost</b-th>
									<b-th>Design Subtotal</b-th>
									<b-th>Land Rights Subtotal</b-th>
									<b-th>Construction Subtotal</b-th>
									<b-th>Federal Subtotal</b-th>
									<b-th>State Subtotal</b-th>
									<b-th>SLO Subtotal</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr>
									<b-td>
										<router-link :to="`/sr/plans/${itemId}`" title="View/Edit">
											<font-awesome-icon :icon="['fas', 'edit']" />
										</router-link>
									</b-td>
									<b-td>${{plan.totalCost | number(2)}}</b-td>
									<b-td>${{plan.designSubtotal | number(2)}}</b-td>
									<b-td>${{plan.landRightsSubtotal | number(2)}}</b-td>
									<b-td>${{plan.constructionSubtotal | number(2)}}</b-td>
									<b-td>${{plan.federalSubtotal | number(2)}}</b-td>
									<b-td>${{plan.stateSubtotal | number(2)}}</b-td>
									<b-td>${{plan.sponsorsSubtotal | number(2)}}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</div>

					<h3 class="mb-3">Project</h3>
					<p v-if="isNullOrEmpty(project)">
						There is no project for this application. <router-link v-if="!isReadOnly" :to="`/sr/projects/create?appId=${itemId}`">Create a project.</router-link>
					</p>
					<div v-else>
						<b-table-simple small responsive class="border-bottom">
							<b-thead class="bg-light">
								<b-tr>
									<b-th></b-th>
									<b-th>Project #</b-th>
									<b-th>Status</b-th>
									<b-th>Category</b-th>
									<b-th>Phase</b-th>
									<b-th>Name</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr>
									<b-td>
										<router-link :to="`/sr/projects/${itemId}`" title="View/Edit">
											<font-awesome-icon :icon="['fas', 'edit']" />
										</router-link>
									</b-td>
									<b-td>{{project.projectNumber}}</b-td>
									<b-td>{{project.status}}</b-td>
									<b-td>{{project.category}}</b-td>
									<b-td>{{project.phase}}</b-td>
									<b-td>{{project.name}}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</div>

					<fixed-action-bar>
						<b-button v-if="isNullOrEmpty(item.plan) && !isReadOnly" type="button" variant="success" :to="`/sr/plans/create?appId=${itemId}`" class="mr-2">Create Plan</b-button>
						<b-button v-else-if="isNullOrEmpty(item.project) && !isReadOnly" type="button" variant="success" :to="`/sr/projects/create?appId=${itemId}`" class="mr-2">Create Project</b-button>
						<b-button type="button" variant="primary" to="print" append target="_blank" class="mr-2">Print</b-button>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<sr-application-form ref="itemForm" :item="item" :dam="dam" :set-options="setOptions" is-update @change="changesSaved"></sr-application-form>
				</b-tab>
				<b-tab title="Files">
					<file-manager :apiUrl="apiUrl" :files="files" :itemId="itemId" itemLabel="application" @change="filesChanged"></file-manager>
				</b-tab>
				<b-tab title="Change Log">
					<log-viewer db-table="SrApplications" :item-id="itemId"></log-viewer>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this application?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import FileManager from '@/components/FileManager';
	import SrApplicationForm from './SrApplicationForm.vue';

	export default {
		name: 'SrApplicationEdit',
		components: {
			SrApplicationForm, FileManager
		},
		data() {
			return {
				apiUrl: 'srapplications',
				listRoute: 'SrApplicationList',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				item: {},
				dam: {},
				plan: {},
				project: {},
				files: [],
				setOptions: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.application;
					this.dam = response.data.dam;
					this.plan = response.data.plan;
					this.project = response.data.project;
					this.files = response.data.files;
					this.setOptions = response.data.setOptions;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
				this.page.tabIndex = 1;
			},
			async filesChanged() {
				await this.get();
				this.page.tabIndex = 2;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			}
		}
	}
</script>