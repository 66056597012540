<template>
	<auth-container :page="page">
		<h2 class="mb-4">Contact Information</h2>

		<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
			<b-tab title="Edit">
				<sr-contact-form ref="itemForm" :item="item" is-update @change="changesSaved"></sr-contact-form>
			</b-tab>
			<b-tab title="Change Log">
				<log-viewer db-table="SrContacts" :item-id="itemId"></log-viewer>
			</b-tab>
		</b-tabs>
	</auth-container>
</template>

<script>
	import SrContactForm from './SrContactForm.vue';

	export default {
		name: 'SrContactEdit',
		components: {
			SrContactForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0
				},
				item: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`srcontacts/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.contact;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			}
		}
	}
</script>