<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col md>
					<b-form-group label="Project" :invalid-feedback="requiredFeedback($v.query.project)">
						<b-typeahead v-model.trim="$v.query.project.$model" required placeholder="Type to search projects by number, name, or dam..."
									 :data="options.projects == null ? [] : options.projects"
									 :serializer="s => s.name" @hit="item.applicationId = $event.id;"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Designer" :invalid-feedback="requiredFeedback($v.item.designer)">
						<b-typeahead v-model.trim="$v.item.designer.$model"
									 :data="options.designer"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>
				</b-col>
			</b-row>

			<h3 class="h5 border-bottom my-4">30% Milestone</h3>

			<b-row>
				<b-col md>
					<b-form-group label="Agent" :invalid-feedback="requiredFeedback($v.item.m30Agent)">
						<b-typeahead v-model.trim="$v.item.m30Agent.$model"
									 :data="setOptions.m30Agents"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Status" :invalid-feedback="requiredFeedback($v.item.m30Status)">
						<b-typeahead v-model.trim="$v.item.m30Status.$model"
									 :data="setOptions.m30Statuses"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Date" :invalid-feedback="requiredFeedback($v.item.m30Date)">
						<b-form-input type="date" v-model.trim="$v.item.m30Date.$model" :state="getValidState($v.item.m30Date)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.m30Notes)" class="mb-5">
				<b-form-textarea v-model.trim="$v.item.m30Notes.$model" :state="getValidState($v.item.m30Notes)" rows="3"></b-form-textarea>
			</b-form-group>

			<h3 class="h5 border-bottom my-4">60% Milestone</h3>

			<b-row>
				<b-col md>
					<b-form-group label="Agent" :invalid-feedback="requiredFeedback($v.item.m60Agent)">
						<b-typeahead v-model.trim="$v.item.m60Agent.$model"
									 :data="setOptions.m60Agents"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Status" :invalid-feedback="requiredFeedback($v.item.m60Status)">
						<b-typeahead v-model.trim="$v.item.m60Status.$model"
									 :data="setOptions.m60Statuses"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Date" :invalid-feedback="requiredFeedback($v.item.m60Date)">
						<b-form-input type="date" v-model.trim="$v.item.m60Date.$model" :state="getValidState($v.item.m60Date)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.m60Notes)" class="mb-5">
				<b-form-textarea v-model.trim="$v.item.m60Notes.$model" :state="getValidState($v.item.m60Notes)" rows="3"></b-form-textarea>
			</b-form-group>

			<h3 class="h5 border-bottom my-4">90% Milestone</h3>

			<b-row>
				<b-col md>
					<b-form-group label="Agent" :invalid-feedback="requiredFeedback($v.item.m90Agent)">
						<b-typeahead v-model.trim="$v.item.m90Agent.$model"
									 :data="setOptions.m90Agents"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Status" :invalid-feedback="requiredFeedback($v.item.m90Status)">
						<b-typeahead v-model.trim="$v.item.m90Status.$model"
									 :data="setOptions.m90Statuses"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Date" :invalid-feedback="requiredFeedback($v.item.m90Date)">
						<b-form-input type="date" v-model.trim="$v.item.m90Date.$model" :state="getValidState($v.item.m90Date)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.m90Notes)" class="mb-5">
				<b-form-textarea v-model.trim="$v.item.m90Notes.$model" :state="getValidState($v.item.m90Notes)" rows="3"></b-form-textarea>
			</b-form-group>

			<h3 class="h5 border-bottom my-4">Final Design</h3>

			<b-row>
				<b-col md>
					<b-form-group label="Agent" :invalid-feedback="requiredFeedback($v.item.finalAgent)">
						<b-typeahead v-model.trim="$v.item.finalAgent.$model"
									 :data="setOptions.finalAgents"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Status" :invalid-feedback="requiredFeedback($v.item.finalStatus)">
						<b-typeahead v-model.trim="$v.item.finalStatus.$model"
									 :data="setOptions.finalStatuses"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Date" :invalid-feedback="requiredFeedback($v.item.finalDate)">
						<b-form-input type="date" v-model.trim="$v.item.finalDate.$model" :state="getValidState($v.item.finalDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Final Construction Documents (CD) Date Received" :invalid-feedback="requiredFeedback($v.item.finalContractDate)">
				<b-form-input type="date" v-model.trim="$v.item.finalContractDate.$model" :state="getValidState($v.item.finalContractDate)"></b-form-input>
			</b-form-group>

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.finalNotes)" class="mb-4">
				<b-form-textarea v-model.trim="$v.item.finalNotes.$model" :state="getValidState($v.item.finalNotes)" rows="3"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, decimal, integer } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrDesignForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			project: {
				type: Object,
				required: false
			},
			setOptions: {
				type: Object,
				default: () => {
					return {
						m30Agents: [],
						m30Statuses: [],
						m60Agents: [],
						m60Statuses: [],
						m90Agents: [],
						m90Statuses: [],
						finalAgents: [],
						finalStatuses: []
					}
				}
			}
		},
		data() {
			return {
				apiUrl: 'srdesigns',
				editRoute: 'SrDesignEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					projects: [],
					designer: []
				},
				query: {
					project: ''
				}
			}
		},
		computed: {
			queryProject() {
				return this.query.project;
			},
			itemDesigner() {
				return this.item.designer;
			}
		},
		validations: {
			item: {
				applicationId: { required, integer },
				designer: {},
				m30Agent: {},
				m30Status: {},
				m30Date: {},
				m30Notes: {},
				m60Agent: {},
				m60Status: {},
				m60Date: {},
				m60Notes: {},
				m90Agent: {},
				m90Status: {},
				m90Date: {},
				m90Notes: {},
				finalAgent: {},
				finalStatus: {},
				finalDate: {},
				finalNotes: {},
				finalContractDate: {}
			},
			query: {
				project: { required }
			}
		},
		watch: {
			queryProject: _.debounce(function (query) { this.findProjects(query) }, 500),
			itemDesigner: _.debounce(function (query) { this.find('designer', query) }, 500)
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				this.log("HI");
				this.log(this.setOptions);
				if (!this.isNullOrEmpty(this.project)) {
					this.query.project = this.project.name;
					this.item.applicationId = this.project.id;
				}

				if (this.isUpdate) {
					this.item.m30Date = this.dateForForm(this.item.m30Date);
					this.item.m60Date = this.dateForForm(this.item.m60Date);
					this.item.m90Date = this.dateForForm(this.item.m90Date);
					this.item.finalDate = this.dateForForm(this.item.finalDate);
					this.item.finalContractDate = this.dateForForm(this.item.finalContractDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.applicationId}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async findProjects(query) {
				try {
					//const response = await this.$http.get(`/srprojects/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					const response = await this.$http.post(`/srprojects/find-id-post`, { value: query }, this.getTokenHeader());
					this.options.projects = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>
