<template>
	<div>
		<auth-container v-if="$route.name === 'MapLayerList'" :page="page">
			<h2 class="mb-4">Map Layers</h2>

			<p>
				This section provides a way to add shape layers to maps, then process the location of a dam within the polygons.
				The web application supports GeoJson shape files, and they must be saved in a particular way so that the CRS is compatible and
				the polygons are simplified enough to not take too long to load through the web. <b>It is recommended to leave this section 
				to the developer, however, instructions are below if needed.</b>
			</p>
			<p>
				The developer has a Python script utilizing QGIS to convert shapefiles (.shp) into GeoJson, with options to simplify the polygons and save in 
				the correct CRS. This may be <a href="https://drive.google.com/file/d/1bLs9NK7fJ4hIyBy6shMfB3gt0uelfIje/view?usp=share_link" target="_blank">downloaded here</a>. 
				After uploading the GeoJson file of your map, it is necessary to use the "Process Dam Coordinate Matching" button below.
				On-the-fly processing of a point within a polygon can be expensive, so processing this ahead of time saves load time for the maps.
			</p>

			<grid-view ref="mapLayersTable" api-url="mapLayers" small use-filter :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
					   collection-description="map layers" show-create
					   item-name="map layer">
				<b-button v-if="isAuthorized(roleNames.superAdmin)" variant="primary" @click="page.showModal = true" class="mr-2">Import</b-button>
				<b-button v-if="isAuthorized(roleNames.superAdmin)" variant="primary" @click="pairs.show = true">Process Dam Coordinate Matching</b-button>
			</grid-view>

			<b-modal v-model="page.showModal" title="Upload Map Layer" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<p>
					Upload a GeoJson file containing layer data. Must be in the WGS84 coordinate system. Contact developer for help.
				</p>

				<b-form-file v-model="page.fileInput" @change="selectFile" class="mb-2"
							 accept=".geojson"
							 placeholder="Choose a file or drop it here..."
							 drop-placeholder="Drop file here..."></b-form-file>


				<b-form-group label="Layer name (required)" class="mt-3">
					<b-form-input type="text" required v-model="layerUpload.layerName" />
				</b-form-group>

				<div class="mb-4">
					<b-form-checkbox v-model="layerUpload.deleteExisting">Delete existing items matching layer name?</b-form-checkbox>
				</div>

				<b-form-group label="Name field property from GeoJson (required)">
					<b-form-input type="text" required v-model="layerUpload.nameField" />
				</b-form-group>
				<b-form-group label="Name field display label">
					<b-form-input type="text" v-model="layerUpload.nameLabel" />
				</b-form-group>
				<b-form-group label="Additional fields to display (comma separated)">
					<b-form-input type="text" v-model="layerUpload.additionalFields" />
				</b-form-group>
				<b-form-group label="Display labels for additional fields above (comma separated)">
					<b-form-input type="text" v-model="layerUpload.additionalLabels" />
				</b-form-group>

				<div slot="modal-footer">
					<save-button type="button" variant="primary" @click.native="uploadFile" :disabled="page.saving" text="Upload"></save-button>
					<b-button type="button" variant="secondary" @click="page.showModal = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="pairs.show" title="Process Map Layers and Dams" scrollable size="lg">
				<error-list :errors="pairs.errors"></error-list>

				<p>
					Enter a layer name to process (re-process) polygons with dam coordinates.
					Existing data for the layer will be replaced.
				</p>

				<b-form-group label="Layer name (required)" class="mt-3">
					<b-form-input type="text" required v-model="pairs.layerName" />
				</b-form-group>

				<div slot="modal-footer">
					<save-button type="button" variant="primary" @click.native="processDamPairs" :disabled="pairs.saving" text="Process"></save-button>
					<b-button type="button" variant="secondary" @click="pairs.show = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';

	export default {
		name: 'MapLayerList',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					showModal: false,
					fileInput: null
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'layerName', label: 'LayerName', sortable: true },
						{ key: 'label', label: 'Label', sortable: true },
						{ key: 'name', label: 'Name', sortable: true }
					],
					sort: 'id',
					reverse: false,
					itemsPerPage: 100
				},
				layerUpload: {
					file: null,
					layerName: null,
					nameField: null,
					nameLabel: null,
					additionalFields: null,
					additionalLabels: null,
					deleteExisting: true
				},
				pairs: {
					errors: [],
					saving: false,
					show: false,
					layerName: null
				}
			}
		},
		methods: {
			selectFile(event) {
				this.layerUpload.file = event.target.files[0];
			},
			async uploadFile() {
				this.page.saving = true;
				this.page.saveErrors = [];

				if (this.layerUpload.file === null) {
					this.page.saveErrors.push('Please select a file to upload.');
				}
				else if (this.isNullOrEmpty(this.layerUpload.layerName) || this.isNullOrEmpty(this.layerUpload.nameField)) {
					this.page.saveErrors.push('Please enter the layer name and name field.');
				} else {
					try {
						let formData = new FormData();
						formData.append('file', this.layerUpload.file);
						formData.append('layerName', this.layerUpload.layerName);
						formData.append('nameField', this.layerUpload.nameField);
						formData.append('nameLabel', this.layerUpload.nameLabel);
						formData.append('additionalFields', this.layerUpload.additionalFields);
						formData.append('additionalLabels', this.layerUpload.additionalLabels);
						formData.append('deleteExisting', this.layerUpload.deleteExisting);

						const response = await this.$http.post(`mapLayers/upload`, formData, this.getTokenHeaderMultiPart());
						this.log(response.data);
						this.page.fileInput = null;
						this.page.layerUpload = {
							file: null,
							layerName: null,
							nameField: null,
							nameLabel: null,
							additionalFields: null,
							additionalLabels: null,
							deleteExisting: true
						};

						this.page.showModal = false;
						await this.$refs.mapLayersTable.get();
					} catch (error) {
						this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async processDamPairs() {
				this.pairs.saving = true;
				this.pairs.errors = [];

				try {
					let data = { value: this.pairs.layerName };
					const response = await this.$http.post(`maplayers/processdampairs`, data, this.getTokenHeader());
					this.log(response.data);

					this.pairs.show = false;
				} catch (error) {
					this.pairs.errors = this.logError(error);
				}

				this.pairs.saving = false;
			}
		}
	}
</script>