<template>
	<auth-container :page="page">
		<h2 class="mb-4">Sponsor # {{item.id}}</h2>

		<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
			<b-tab title="Overview" active>
				<b-row>
					<b-col md>
						<b-table-simple small>
							<b-tbody>
								<b-tr>
									<b-th>Type/Number</b-th>
									<b-td>{{item.type}} {{item.id}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Name</b-th>
									<b-td>{{item.name}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Location</b-th>
									<b-td>
										<div>{{item.address}}</div>
										<div>{{item.address2}}</div>
										<div>{{item.city}}, TX {{item.zip}}</div>
									</b-td>
								</b-tr>
								<b-tr>
									<b-th>Phone</b-th>
									<b-td>{{valueOrNa(item.phone)}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Fax</b-th>
									<b-td>{{valueOrNa(item.fax)}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Sponsor Email</b-th>
									<b-td>
										<span v-if="isNullOrEmpty(item.sponsorEmail)">N/A</span>
										<a v-else :href="`mailto:${item.sponsorEmail}`">{{item.sponsorEmail}}</a>
									</b-td>
								</b-tr>
								<b-tr>
									<b-th>Contact Name</b-th>
									<b-td>{{valueOrNa(item.contactName)}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Contact Email</b-th>
									<b-td>
										<span v-if="isNullOrEmpty(item.contactEmail)">N/A</span>
										<a v-else :href="`mailto:${item.contactEmail}`">{{item.contactEmail}}</a>
									</b-td>
								</b-tr>
								<b-tr>
									<b-th>Other Associated Sponsor</b-th>
									<b-td>{{valueOrNa(item.other)}}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</b-col>
					<b-col md v-if="map.show">
						<div v-if="!map.loading" class="map-container w-100" style="height:400px">
							<l-map ref="leafmap" style="height: 100%; width:100%" :zoom="map.zoom" :center="map.center">
								<l-control-layers position="topright"></l-control-layers>
								<l-tile-layer v-for="tileProvider in mapBase.tileProviders"
											  :key="tileProvider.name"
											  :name="tileProvider.name"
											  :visible="tileProvider.visible"
											  :url="tileProvider.url"
											  :attribution="tileProvider.attribution"
											  layer-type="base" />

								<l-circle-marker :lat-lng="map.layers.point.coordinates"
												 :radius="map.layers.point.style.radius"
												 :color="map.layers.point.style.color"
												 :fill-color="map.layers.point.style.fillColor">
									<l-tooltip>
										<div v-if="!addressIsPOBox">
											<div>{{item.address}}</div>
											<div>{{item.address2}}</div>
										</div>
										<div>{{item.city}}, TX {{item.zip}}</div>
									</l-tooltip>
								</l-circle-marker>
							</l-map>
						</div>
					</b-col>
				</b-row>

				<fixed-action-bar>
					<back-button />
					<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
				</fixed-action-bar>
			</b-tab>
			<b-tab title="Edit" v-if="!isReadOnly">
				<sponsor-form ref="sponsorForm" :item="item" is-update @change="changesSaved"></sponsor-form>
			</b-tab>
			<b-tab title="Change Log">
				<grid-view :api-url="`home/logs/Sponsors/${itemId}`" use-filter small :fields="table.fields"
						   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
						   collection-description="change log"
						   item-name="log">
					<back-button />
				</grid-view>
			</b-tab>
		</b-tabs>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this sponsor?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</auth-container>
</template>

<script>
	import { LMap, LTileLayer, LControlLayers, LCircleMarker, LTooltip } from 'vue2-leaflet';
	import 'leaflet/dist/leaflet.css';
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';
	import SponsorForm from './SponsorForm.vue';

	export default {
		name: 'SponsorEdit',
		components: {
			SponsorForm, LMap, LTileLayer, LControlLayers, LCircleMarker, LTooltip, GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				item: {},
				map: {
					show: true,
					loading: false,
					zoom: 6,
					center: [31.224, -98.897],
					layers: {
						point: {
							coordinates: [31.224, -98.897],
							options: {},
							style: {
								radius: 6,
								weight: 1,
								opacity: 1,
								color: '#9b3ee8',
								fillOpacity: 0.8,
								fillColor: '#ca93f9'
							}
						}
					}
				},
				table: {
					fields: [
						{ key: 'activityDate', label: 'Date', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } },
						{ key: 'userName', label: 'User', sortable: true },
						{ key: 'description', label: 'Description', sortable: true }
					],
					sort: 'activityDate',
					reverse: true,
					itemsPerPage: 100
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`omsponsors/${this.$route.params.id}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.sponsor;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
				this.map.loading = true;

				try {
					let locatorService = 'https://nominatim.openstreetmap.org/search.php?format=jsonv2&q=';
					let addressLine = `${this.item.city},TX ${this.item.zip}`;
					if (!this.addressIsPOBox) {
						addressLine = `${this.item.address}, ${addressLine}`;
					}
					const locationResponse = await this.$httpRoot.get(`${locatorService}${encodeURIComponent(addressLine)}`);
					this.log(locationResponse);
					if (locationResponse.data.length > 0) {
						let location = locationResponse.data[0];
						let coords = [Number(location.lat), Number(location.lon)];
						this.map.center = coords;
						this.map.layers.point.coordinates = coords;
					}
				} catch (error) {
					this.logError(error);
					this.map.show = false;
				}

				this.map.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.page.tabIndex = 1;
				this.$refs.sponsorForm.launchToast();
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`omsponsors/${this.$route.params.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: 'SponsorList' });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			},
			addressIsPOBox() {
				if (!this.item.address) return false;
				return (this.item.address.startsWith('PO') || this.item.address.startsWith('P.O'));
			}
		}
	}
</script>