<template>
	<div>
		<auth-container v-if="$route.name === 'SrWorkOrderList'" :page="page">
			<h2 class="mb-4">Work Orders</h2>

			<div class="mx-auto">
				<b-row class="mb-3" align-v="end">
					<b-col lg="6">
						<b-form inline>
							<b-input-group>
								<b-form-input v-model="table.filter" placeholder="Search..." @input="filterChg"></b-form-input>

								<b-input-group-append class="pointer mr-2">
									<b-input-group-text @click="clearFilter">
										<font-awesome-icon :icon="['fas', 'times']" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
							<b-button variant="primary" type="button" @click="table.showAdvancedFilter = !table.showAdvancedFilter">Advanced Filter</b-button>
						</b-form>
					</b-col>
					<b-col lg="6" class="text-right">
						<div class="ml-auto">
							Showing {{showingFirst}} - {{showingLast}}
							of {{table.total}}
						</div>
					</b-col>
				</b-row>

				<b-collapse v-model="table.showAdvancedFilter" class="my-3">
					<b-card bg-variant="light">
						<b-row>
							<b-col lg="4">
								<div class="form-group row">
									<label for="advancedFilter.workOrderNumber" class="col-lg-5 col-form-label">Work Order #</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.workOrderNumber"
													 v-model="advancedFilter.workOrderNumber"
													 :data="advancedFilter.options.workOrderNumber"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.dam" class="col-lg-5 col-form-label">Dam NID_ID</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.dam"
													 v-model="advancedFilter.dam"
													 :data="advancedFilter.options.dam"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.projectNumber" class="col-lg-5 col-form-label">Project #</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.projectNumber"
													 v-model="advancedFilter.projectNumber"
													 :data="advancedFilter.options.projectNumber"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.projectName" class="col-lg-5 col-form-label">Project Name</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.projectName"
													 v-model="advancedFilter.projectName"
													 :data="advancedFilter.options.projectName"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.purchaseOrder" class="col-lg-5 col-form-label">PO#</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.purchaseOrder"
													 v-model="advancedFilter.purchaseOrder"
													 :data="advancedFilter.options.purchaseOrder"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.projectManager" class="col-lg-5 col-form-label">Project Manager</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.projectManager"
													 v-model="advancedFilter.projectManager"
													 :data="advancedFilter.options.projectManager"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.contractor" class="col-lg-5 col-form-label">Contractor</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.contractor"
													 v-model="advancedFilter.contractor"
													 :data="advancedFilter.options.contractor"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.pocPrimary" class="col-lg-5 col-form-label">Point of Contact</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.pocPrimary"
													 v-model="advancedFilter.pocPrimary"
													 :data="advancedFilter.options.pocPrimary"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>
							</b-col>
							<b-col lg>
								<div class="form-group row">
									<label for="advancedFilter.fundingType" class="col-lg-3 col-form-label">Funding Type</label>
									<div class="col-lg-9">
										<b-typeahead id="advancedFilter.fundingType" style="max-width:260px"
													 v-model="advancedFilter.fundingType"
													 :data="setOptions.fundingTypes"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.fundingSubcategory" class="col-lg-3 col-form-label">Funding Subcategory</label>
									<div class="col-lg-9">
										<b-typeahead id="advancedFilter.fundingSubcategory" style="max-width:260px"
													 v-model="advancedFilter.fundingSubcategory"
													 :data="setOptions.fundingSubcategories"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.fundingFiscalYear" class="col-lg-3 col-form-label">Fiscal Year</label>
									<div class="col-lg-9">
										<b-typeahead id="advancedFilter.fundingFiscalYear" style="max-width:260px"
													 v-model="advancedFilter.fundingFiscalYear"
													 :data="advancedFilter.options.fundingFiscalYear"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.projectType" class="col-lg-3 col-form-label">Project Type</label>
									<div class="col-lg-9">
										<b-typeahead id="advancedFilter.projectType" style="max-width:260px"
													 v-model="advancedFilter.projectType"
													 :data="setOptions.projectTypes"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.fundingType" class="col-lg-3 col-form-label">Project Subtype</label>
									<div class="col-lg-9">
										<b-typeahead id="advancedFilter.projectSubtype" style="max-width:260px"
													 v-model="advancedFilter.projectSubtype"
													 :data="setOptions.projectSubtypes"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.executionDate.start" class="col-lg-3 col-form-label">Execution Date</label>
									<div class="col-lg-9">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter.executionDate.start" v-model="advancedFilter.executionDate.start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.executionDate.start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter.executionDate.end" v-model="advancedFilter.executionDate.end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.executionDate.end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.expirationDate.start" class="col-lg-3 col-form-label">Expiration Date</label>
									<div class="col-lg-9">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter.expirationDate.start" v-model="advancedFilter.expirationDate.start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.expirationDate.start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter.expirationDate.end" v-model="advancedFilter.expirationDate.end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.expirationDate.end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.completionDate.start" class="col-lg-3 col-form-label">Completion Date</label>
									<div class="col-lg-9">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter.completionDate.start" v-model="advancedFilter.completionDate.start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.completionDate.start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter.completionDate.end" v-model="advancedFilter.completionDate.end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.completionDate.end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
						<hr class="my-3" />
						<div>
							<b-button variant="primary" type="button" @click="get" class="mr-2">Apply</b-button>
							<b-button variant="secondary" type="button" @click="clearAdvancedFilter" class="mr-2">Clear</b-button>
							<b-button variant="secondary" type="button" @click="table.showAdvancedFilter = false">Close</b-button>
						</div>
					</b-card>
				</b-collapse>

				<b-table striped responsive table-class="table-fixed-height border-bottom table-grid" sticky-header="60vh"
						 small show-empty
						 :items="items" :busy="table.loading"
						 :fields="table.fields"
						 :no-local-sorting="true"
						 :sort-by.sync="table.sort"
						 :sort-desc.sync="table.reverse"
						 @sort-changed="sortChg">
					<template #table-busy>
						<div class="text-center my-4">
							<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
						</div>
					</template>
					<template #cell(edit)="data">
						<router-link :to="`${data.item.id}`" append>
							<font-awesome-icon :icon="['fas', 'edit']" />
						</router-link>
					</template>
					<template #empty>
						<h4>No records found.</h4>
					</template>
					<template #emptyfiltered>
						<h4>No records found matching "{{table.filter}}".</h4>
					</template>
				</b-table>
			</div>

			<fixed-action-bar>
				<router-link v-if="!isReadOnly" to="create" append class="btn btn-success mr-2">
					Create
				</router-link>
				<json-to-csv :api-url="`${apiUrl}/export`" :filename="apiUrl" class="mr-2" />
				<b-button v-if="isAuthorized(roleNames.superAdmin)" variant="primary" @click="page.showModal = true" class="mr-2">Import</b-button>
				<b-pagination class="ml-auto mb-0" v-if="table.total > table.itemsPerPage" size="md" :total-rows="table.total" v-model="table.currentPage" :per-page="table.itemsPerPage" @change="pageChg" />
			</fixed-action-bar>

			<b-modal v-model="page.showModal" title="Upload" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<p class="mt-3">
					<strong>Recommended developer upload only.</strong>
				</p>

				<div slot="modal-footer">
					<json-to-csv :json-data="template" :filename="`${apiUrl}-template`" text="Download Template" class="mr-2" />
					<b-button type="button" variant="primary" @click="page.showModal = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import _ from 'underscore';

	export default {
		name: 'SrWorkOrderList',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				apiUrl: 'srworkorders',
				page: {
					errors: [],
					loading: true,
					showLogin: false,
					saveErrors: [],
					showModal: false
				},
				dropzoneOptions: {
					url: '/api/srworkorders/upload/',
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				table: {
					fields: [
						{ key: 'edit', label: '', stickyColumn: true },
						{ key: 'workOrderNumber', label: 'Work Order #', sortable: true },
						{ key: 'dams', sortable: true },
						{ key: 'projects', sortable: true },
						{ key: 'revision', sortable: true },
						{ key: 'purchaseOrder', label: 'PO#', sortable: true },
						{ key: 'purchaseOrderDate', label: 'PO Date', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'name', sortable: true },
						{ key: 'executionDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'expirationDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'completionDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'projectManager', sortable: true },
						{ key: 'contractor', sortable: true },
						{ key: 'pocPrimary', label: 'Primary POC', sortable: true },
						{ key: 'pocSecondary', label: 'Secondary POC', sortable: true },
						{ key: 'fundingType', sortable: true },
						{ key: 'fundingSubcategory', sortable: true },
						{ key: 'fundingFiscalYear', label: 'FY', sortable: true },
						{ key: 'projectType', sortable: true },
						{ key: 'projectSubtype', sortable: true }
					],
					sort: 'workOrderNumber',
					reverse: false,
					itemsPerPage: 100,
					filter: null,
					currentPage: 1,
					total: 0,
					loading: false,
					showAdvancedFilter: false
				},
				items: [],
				advancedFilter: {
					dam: null,
					projectName: null,
					projectNumber: null,
					workOrderNumber: null,
					purchaseOrder: null,
					projectManager: null,
					contractor: null,
					pocPrimary: null,
					pocSecondary: null,
					fundingType: null,
					fundingSubcategory: null,
					fundingFiscalYear: null,
					projectType: null,
					projectSubtype: null,
					executionDate: {
						start: null,
						end: null
					},
					expirationDate: {
						start: null,
						end: null
					},
					completionDate: {
						start: null,
						end: null
					},
					options: {
						dam: [],
						projectName: [],
						projectNumber: [],
						workOrderNumber: [],
						purchaseOrder: [],
						projectManager: [],
						contractor: [],
						pocPrimary: [],
						fundingFiscalYear: []
					}
				},
				setOptions: {
					fundingTypes: [],
					fundingSubcategories: [],
					projectTypes: [],
					projectSubtypes: []
				},
				template: [{
					workOrderNumber: null,
					revision: null,
					purchaseOrder: null,
					purchaseOrderDate: null,
					name: null,
					executionDate: null,
					expirationDate: null,
					completionDate: null,
					projectManager: null,
					contractor: null,
					pocPrimary: null,
					pocSecondary: null,
					fundingType: null,
					fundingSubcategory: null,
					fundingFiscalYear: null,
					projectType: null,
					projectSubtype: null,
					notes: null
				}]
			}
		},
		computed: {
			showingFirst() {
				return this.table.total === 0 ? 0 : (this.table.currentPage - 1) * this.table.itemsPerPage + 1
			},
			showingLast() {
				var max = (this.table.currentPage - 1) * this.table.itemsPerPage + this.table.itemsPerPage
				return max > this.table.total ? this.table.total : max;
			},
			advancedFilterDam() {
				return this.advancedFilter.dam;
			},
			advancedFilterProjectName() {
				return this.advancedFilter.projectName;
			},
			advancedFilterProjectNumber() {
				return this.advancedFilter.projectNumber;
			},
			advancedFilterWorkOrderNumber() {
				return this.advancedFilter.workOrderNumber;
			},
			advancedFilterPurchaseOrder() {
				return this.advancedFilter.purchaseOrder;
			},
			advancedFilterFundingFiscalYear() {
				return this.advancedFilter.fundingFiscalYear;
			},
			advancedFilterProjectManager() {
				return this.advancedFilter.projectManager;
			},
			advancedFilterContractor() {
				return this.advancedFilter.contractor;
			},
			advancedFilterPocPrimary() {
				return this.advancedFilter.pocPrimary;
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get',
			advancedFilterDam: _.debounce(function (query) { this.findDams('nid_id', query) }, 500),
			advancedFilterProjectName: _.debounce(function (query) { this.find('projectName', query) }, 500),
			advancedFilterProjectNumber: _.debounce(function (query) { this.find('projectNumber', query) }, 500),
			advancedFilterWorkOrderNumber: _.debounce(function (query) { this.find('workOrderNumber', query) }, 500),
			advancedFilterPurchaseOrder: _.debounce(function (query) { this.find('purchaseOrder', query) }, 500),
			advancedFilterFundingFiscalYear: _.debounce(function (query) { this.find('fundingFiscalYear', query) }, 500),
			advancedFilterProjectManager: _.debounce(function (query) { this.find('projectManager', query) }, 500),
			advancedFilterContractor: _.debounce(function (query) { this.find('contractor', query) }, 500),
			advancedFilterPocPrimary: _.debounce(function (query) { this.find('pocPrimary', query) }, 500)
		},
		methods: {
			getDb() {
				let sort = this.table.reverse ? this.table.sort + '_desc' : this.table.sort;
				let filter = this.table.filter !== null ? `&filter=${encodeURIComponent(this.table.filter)}` : '';

				let filterComplex = '';
				let filterValues = [];
				if (!this.isNullOrEmpty(this.advancedFilter.dam)) {
					filterValues.push(`dam:${this.advancedFilter.dam}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.projectName)) {
					filterValues.push(`projectName:${this.advancedFilter.projectName}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.projectNumber)) {
					filterValues.push(`projectNumber:${this.advancedFilter.projectNumber}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.workOrderNumber)) {
					filterValues.push(`workOrderNumber:${this.advancedFilter.workOrderNumber}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.purchaseOrder)) {
					filterValues.push(`purchaseOrder:${this.advancedFilter.purchaseOrder}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.fundingFiscalYear)) {
					filterValues.push(`fundingFiscalYear:${this.advancedFilter.fundingFiscalYear}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.projectManager)) {
					filterValues.push(`projectManager:${this.advancedFilter.projectManager}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.contractor)) {
					filterValues.push(`contractor:${this.advancedFilter.contractor}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.pocPrimary)) {
					filterValues.push(`pocPrimary:${this.advancedFilter.pocPrimary}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.fundingType)) {
					filterValues.push(`fundingType:${this.advancedFilter.fundingType}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.fundingSubcategory)) {
					filterValues.push(`fundingSubcategory:${this.advancedFilter.fundingSubcategory}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.projectType)) {
					filterValues.push(`projectType:${this.advancedFilter.projectType}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.projectSubtype)) {
					filterValues.push(`projectSubtype:${this.advancedFilter.projectSubtype}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.executionDate.start) || !this.isNullOrEmpty(this.advancedFilter.executionDate.end)) {
					let s = this.advancedFilter.executionDate.start;
					let e = this.advancedFilter.executionDate.end;
					s = s === null ? 'any' : s;
					e = e === null ? 'any' : e;
					filterValues.push(`executionDate:${s},${e}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.expirationDate.start) || !this.isNullOrEmpty(this.advancedFilter.expirationDate.end)) {
					let s = this.advancedFilter.expirationDate.start;
					let e = this.advancedFilter.expirationDate.end;
					s = s === null ? 'any' : s;
					e = e === null ? 'any' : e;
					filterValues.push(`expirationDate:${s},${e}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.completionDate.start) || !this.isNullOrEmpty(this.advancedFilter.completionDate.end)) {
					let s = this.advancedFilter.completionDate.start;
					let e = this.advancedFilter.completionDate.end;
					s = s === null ? 'any' : s;
					e = e === null ? 'any' : e;
					filterValues.push(`completionDate:${s},${e}`);
				}

				if (filterValues.length > 0) filterComplex = '&filterComplex=' + filterValues.join('|');

				return this.$http.get(`${this.apiUrl}?sort=${sort}&page=${this.table.currentPage}&perPage=${this.table.itemsPerPage}${filter}${filterComplex}`, this.getTokenHeader());
			},
			async filterChg() {
				this.table.currentPage = 1;
				_.debounce(await this.get(), 500);
			},
			async pageChg(page) {
				this.table.currentPage = page;
				await this.get();
			},
			async sortChg(ctx) {
				this.table.sort = ctx.sortBy;
				this.table.reverse = ctx.sortDesc;
				this.table.specialSort = undefined;

				await this.get();
			},
			async get() {
				this.page.errors = [];
				this.table.loading = true;

				try {
					const response1 = await this.$http.get(`${this.apiUrl}/options`, this.getTokenHeader());
					this.setOptions = response1.data;

					const response = await this.getDb();
					this.log(response.data);

					if (response.data.items.length == 0 && this.table.currentPage > 1) {
						this.table.currentPage--;
						await this.get();
					}

					this.items = response.data.items;
					this.table.total = response.data.total;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.table.loading = false;
				this.page.loading = false;
			},
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				await this.get();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async findDams(field, query) {
				try {
					const response = await this.$http.get(`/dams/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options.dam = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async clearFilter() {
				this.table.filter = null;
				await this.get();
			},
			async clearAdvancedFilter() {
				this.advancedFilter = {
					dam: null,
					projectName: null,
					projectNumber: null,
					workOrderNumber: null,
					purchaseOrder: null,
					projectManager: null,
					contractor: null,
					pocPrimary: null,
					pocSecondary: null,
					fundingType: null,
					fundingSubcategory: null,
					fundingFiscalYear: null,
					projectType: null,
					projectSubtype: null,
					executionDate: {
						start: null,
						end: null
					},
					expirationDate: {
						start: null,
						end: null
					},
					completionDate: {
						start: null,
						end: null
					},
					options: {
						dam: [],
						projectName: [],
						projectNumber: [],
						workOrderNumber: [],
						purchaseOrder: [],
						projectManager: [],
						contractor: [],
						pocPrimary: [],
						fundingFiscalYear: []
					}
				};

				await this.get();
			}
		}
	}
</script>