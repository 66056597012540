<template>
	<div>
		<grid-view :api-url="`home/logs/${dbTable}/${itemId}`" use-filter small :fields="table.fields"
				   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
				   collection-description="change log"
				   item-name="log">
			<back-button />
		</grid-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'LogViewer',
		components: {
			GridView
		},
		props: {
			dbTable: {
				type: String,
				required: true
			},
			itemId: {
				required: true
			}
		},
		data() {
			return {
				table: {
					fields: [
						{ key: 'activityDate', label: 'Date', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } },
						{ key: 'userName', label: 'User', sortable: true },
						{ key: 'description', label: 'Description', sortable: true }
					],
					sort: 'activityDate',
					reverse: true,
					itemsPerPage: 100
				}
			}
		}
	}
</script>
