<template>
	<div>
		<auth-container :page="page" v-if="$route.name === 'SrAgreementEdit'">
			<h2 class="mb-4">Agreement #{{item.agreementNumber}}</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview">
					<b-row>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>State PO#</b-th>
										<b-td>{{valueOrNa(item.statePo)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Agreement Appropriation Fund Source ID</b-th>
										<b-td>{{valueOrNa(item.fundSourceId)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Fiscal Year Funds Were Obligated</b-th>
										<b-td>{{valueOrNa(item.fundFiscalYear)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Offeror</b-th>
										<b-td>{{valueOrNa(item.offeror)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Offeree</b-th>
										<b-td>{{valueOrNa(item.offeree)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Contracting Entity</b-th>
										<b-td>{{valueOrNa(item.contractor)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Agreement Type</b-th>
										<b-td>{{valueOrNa(item.agreementType)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Status</b-th>
										<b-td>{{valueOrNa(item.status)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Amendment #</b-th>
										<b-td>{{valueOrNa(item.amendmentNumber)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Amendment Notes</b-th>
										<b-td>{{valueOrNa(item.amendmentNotes, 'None')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Amendment Date</b-th>
										<b-td>{{item.amendmentDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Execution Date</b-th>
										<b-td>{{item.executionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Expiration Date</b-th>
										<b-td>{{item.expirationDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Last Revised Date</b-th>
										<b-td>{{item.lastRevisedDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Notes</b-th>
										<b-td>{{valueOrNa(item.notes, 'None')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col md>
							<b-table-simple small class="table-definitions" bordered>
								<b-tbody>
									<b-tr class="bg-light">
										<b-th>Project</b-th>
										<b-th>Dam</b-th>
									</b-tr>
									<b-tr v-for="(p, i) in projects" :key="i">
										<b-td><router-link :to="`/sr/projects/${p.projectId}`">#{{p.projectNumber}} - {{p.projectName}}</router-link></b-td>
										<b-td>
											<span v-if="isNullOrEmpty(p.damId)">N/A</span>
											<router-link v-else :to="`/dams/${p.damId}`">{{p.damName}}</router-link>
										</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>

							<b-table-simple small class="table-definitions" bordered>
								<b-tbody>
									<b-tr>
										<b-th class="bg-light">Maximum Contract Price</b-th>
										<b-td class="text-right" colspan="2">{{money(item.maxContractPrice)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th class="bg-light" style="border-bottom-width:3px">Any Amount Not Cost-Shared</b-th>
										<b-td class="text-right" colspan="2" style="border-bottom-width:3px">{{money(item.additionalAmount)}}</b-td>
									</b-tr>
									<b-tr class="bg-light">
										<b-th>Cost Share</b-th>
										<b-th class="text-right">Reimbursable %</b-th>
										<b-th class="text-right">Max Reimbursable Amount</b-th>
									</b-tr>
									<b-tr>
										<b-th class="bg-light">Total Construction</b-th>
										<b-td class="text-right">{{item.constructionPercent | number(2)}}%</b-td>
										<b-td class="text-right">{{money(item.constructionMaxAmount)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th class="bg-light">Professional Services</b-th>
										<b-td class="text-right">{{item.profServicesPercent | number(2)}}%</b-td>
										<b-td class="text-right">{{money(item.profServicesMaxAmount)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th class="bg-light">Administrative Expenses</b-th>
										<b-td class="text-right">{{item.adminPercent | number(2)}}%</b-td>
										<b-td class="text-right">{{money(item.adminMaxAmount)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th class="bg-light">Land Rights</b-th>
										<b-td class="text-right">{{item.landRightsPercent | number(2)}}%</b-td>
										<b-td class="text-right">{{money(item.landRightsMaxAmount)}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
					</b-row>

					<fixed-action-bar>
						<b-button type="button" variant="primary" to="print" append target="_blank" class="mr-2">Print</b-button>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Reimbursements">
					<b-table striped responsive small show-empty :fields="reimbursements.fields" :items="reimbursements.items">
						<template #cell(edit)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'edit']" class="pointer text-primary" @click="editSubItem(data.item.id)" />
						</template>
						<template #cell(dam)="data">
							<router-link :to="`/dams/${data.value.id}`">{{data.value.name}}</router-link>
						</template>
						<template #cell(delete)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'times']" class="pointer text-danger" @click="deleteSubItem(data.item.id)" />
						</template>
						<template #empty>
							<p class="my-2">This agreement does not have any reimbursements entered.</p>
						</template>

						<template #custom-foot>
							<b-tr class="bg-light border-bottom font-weight-bold">
								<b-td colspan="4">Total</b-td>
								<b-td class="text-right">${{ sum(reimbursements.items, 'stateConstructionCost') | number(2)}}</b-td>
								<b-td></b-td>
								<b-td class="text-right">${{ sum(reimbursements.items, 'stateLandRights') | number(2)}}</b-td>
								<b-td></b-td>
								<b-td class="text-right">${{ sum(reimbursements.items, 'stateAdminCost') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(reimbursements.items, 'federalConstructionCost') | number(2)}}</b-td>
								<b-td></b-td>
								<b-td class="text-right">${{ sum(reimbursements.items, 'federalLandRights') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(reimbursements.items, 'sponsorConstructionCost') | number(2)}}</b-td>
								<b-td></b-td>
							</b-tr>
						</template>
					</b-table>

					<fixed-action-bar>
						<b-button type="button" variant="success" @click="createSubItem" class="mr-2">Create Reimbursement</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<sr-agreement-form ref="itemForm" :item="item" :projects="projectRelations" :set-options="setOptions" is-update @change="changesSaved"></sr-agreement-form>
				</b-tab>
				<b-tab title="Files">
					<file-manager :apiUrl="apiUrl" :files="files" :itemId="itemId" itemLabel="application" @change="filesChanged"></file-manager>
				</b-tab>
				<b-tab title="Change Log">
					<log-viewer db-table="SrAgreements" :item-id="itemId"></log-viewer>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this project?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.deleteSubItem.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.deleteSubItem.errors"></error-list>

				<p>
					Are you sure you want to delete this reimbursement?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.deleteSubItem.saving" @click.native="confirmDeleteSubItem" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.deleteSubItem.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="modal.show" title="Reimbursement Information" scrollable size="lg" hide-footer>
				<error-list :errors="modal.errors"></error-list>

				<sr-agreement-reimbursement-form :item="modal.item" :dams="damsForForm" :is-update="modal.isUpdate" @change="modalSaved" @cancel="cancelModal"></sr-agreement-reimbursement-form>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import FileManager from '@/components/FileManager';
	import SrAgreementForm from './SrAgreementForm.vue';
	import SrAgreementReimbursementForm from './SrAgreementReimbursementForm.vue';

	export default {
		name: 'SrAgreementEdit',
		components: {
			SrAgreementForm, SrAgreementReimbursementForm, FileManager
		},
		data() {
			return {
				apiUrl: 'sragreements',
				listRoute: 'SrAgreementList',
				subItemApiUrl: 'sragreementreimbursements',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					deleteSubItem: {
						show: false,
						errors: [],
						saving: false,
						id: null
					}
				},
				item: {},
				projects: [],
				files: [],
				setOptions: {},
				reimbursements: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'dam', label: 'Dam', sortable: true },
						{ key: 'invoiceNumber', label: 'Invoice#', sortable: true },
						{ key: 'invoicedDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'stateConstructionCost', label: 'State Constr.', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'wasStateRetainageRequested', label: 'State Retainage?', sortable: true, class: 'text-center', formatter: (value) => { return this.boolToText(value); } },
						{ key: 'stateLandRights', label: 'State LR', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'wasAdminRequestedInAdvance', label: 'Admin Req. in Advance?', sortable: true, class: 'text-center', formatter: (value) => { return this.boolToText(value); } },
						{ key: 'stateAdminCost', label: 'State Admin', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'federalConstructionCost', label: 'Fed. Constr.', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'wasFederalRetainageRequested', label: 'Fed. Retainage?', sortable: true, class: 'text-center', formatter: (value) => { return this.boolToText(value); } },
						{ key: 'federalLandRights', label: 'Fed. LR', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'sponsorConstructionCost', label: 'Sponsor Constr.', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'delete', label: '' }
					],
					items: []
				},
				modal: {
					item: {},
					show: false,
					loading: false,
					errors: [],
					isUpdate: false
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.agreement;
					this.projects = response.data.projects;
					this.files = response.data.files;
					this.setOptions = response.data.setOptions;
					this.reimbursements.items = response.data.reimbursements;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
				this.page.tabIndex = 2;
			},
			async filesChanged() {
				await this.get();
				this.page.tabIndex = 3;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			deleteSubItem(id) {
				this.page.deleteSubItem.id = id;
				this.page.deleteSubItem.show = true;
			},
			async confirmDeleteSubItem() {
				if (this.page.deleteSubItem.id !== null) {
					this.page.deleteSubItem.errors = [];
					this.page.deleteSubItem.saving = true;

					try {
						await this.$http.delete(`${this.subItemApiUrl}/${this.page.deleteSubItem.id}`, this.getTokenHeader());
						this.page.deleteSubItem.show = false;
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deleteSubItem.errors = this.logError(error);
					}

					this.page.deleteSubItem.saving = false;
				}
			},
			async editSubItem(id) {
				await this.getModal(id);
				this.modal.show = true;
			},
			createSubItem() {
				this.resetModal();
				this.modal.show = true;
			},
			async modalSaved() {
				this.modal.show = false;
				this.resetModal();
				await this.get();
				this.$bvToast.show('subitem-saved');
			},
			cancelModal() {
				this.modal.show = false;
				this.resetModal();
			},
			resetModal() {
				this.modal.item = {
					agreementId: Number(this.itemId),
					damId: null,
					invoiceNumber: null,
					invoicedDate: null,
					stateConstructionCost: 0,
					wasStateRetainageRequested: false,
					stateLandRights: 0,
					wasAdminRequestedInAdvance: false,
					stateAdminCost: 0,
					federalConstructionCost: 0,
					wasFederalRetainageRequested: false,
					federalLandRights: 0,
					sponsorConstructionCost: 0
				}
				this.modal.isUpdate = false;
			},
			async getModal(id) {
				this.modal.loading = true;
				this.modal.errors = [];

				try {
					const response = await this.$http.get(`${this.subItemApiUrl}/${id}`, this.getTokenHeader());
					this.log(response.data);
					this.modal.item = response.data.item;
					this.modal.isUpdate = true;
				} catch (error) {
					this.modal.errors = this.logError(error);
				}

				this.modal.loading = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			},
			projectRelations() {
				let relations = [];
				for (let p of this.projects) {
					relations.push({ id: p.projectId, name: p.display });
				}
				return relations;
			},
			damsForForm() {
				let dams = [];
				for (let p of this.projects) {
					dams.push({ value: p.damId, text: p.display });
				}
				return dams;
			}
		}
	}
</script>