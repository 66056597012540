<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control Structural Repair Program<br />Project #{{$parent.item.projectNumber}}</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-row>
				<b-col md>
					<b-table-simple small class="table-definitions">
						<b-tbody>
							<b-tr>
								<b-th>Project #</b-th>
								<b-td>{{valueOrNa($parent.item.projectNumber)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Project Name</b-th>
								<b-td>{{valueOrNa($parent.item.name)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Expected Completion Date</b-th>
								<b-td>{{$parent.item.expectedCompletionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Completion Letter Submission Date</b-th>
								<b-td>{{$parent.item.completionSubmissionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Dam</b-th>
								<b-td>
									<span v-if="isNullOrEmpty($parent.application.damId)">N/A</span>
									<span v-else>{{$parent.dam.name}}</span>
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Category</b-th>
								<b-td>{{valueOrNa($parent.item.category)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Phase</b-th>
								<b-td>{{valueOrNa($parent.item.phase)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>A&amp;E Assignment</b-th>
								<b-td>{{valueOrNa($parent.item.aeAssignment)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Project Manager</b-th>
								<b-td>{{valueOrNa($parent.item.projectManager)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Flood Control Program Staff</b-th>
								<b-td>{{valueOrNa($parent.item.fcpStaff)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Overall Status</b-th>
								<b-td>{{valueOrNa($parent.item.status)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Status Notes</b-th>
								<b-td>{{valueOrNa($parent.item.statusNote, 'None')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Land Rights Status</b-th>
								<b-td>{{valueOrNa($parent.item.landRightsStatus)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Land Rights Status Changed Date</b-th>
								<b-td>{{$parent.item.landRightsStatusDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Land Rights Status Notes</b-th>
								<b-td>{{valueOrNa($parent.item.landRightsNotes, 'None')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>CoE 404 Permit Status</b-th>
								<b-td>{{valueOrNa($parent.item.coe404PermitStatus)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>CoE 404 Permit Status Changed Date</b-th>
								<b-td>{{$parent.item.coeStatusDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>CoE 404 Permit Status Notes</b-th>
								<b-td>{{valueOrNa($parent.item.coeNotes, 'None')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>TPWD ARRP Status</b-th>
								<b-td>{{valueOrNa($parent.item.tpwdArrpStatus)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>TPWD ARRP Status Changed Date</b-th>
								<b-td>{{$parent.item.tpwdArrpStatusDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>TPWD ARRP Status Notes</b-th>
								<b-td>{{valueOrNa($parent.item.tpwdArrpNotes, 'None')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>O&amp;M Agreement Status</b-th>
								<b-td>{{valueOrNa($parent.item.omAgreementStatus)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>O&amp;M Agreement Status Changed Date</b-th>
								<b-td>{{$parent.item.omAgreementStatusDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>O&amp;M Agreement Status Notes</b-th>
								<b-td>{{valueOrNa($parent.item.omAgreementNotes, 'None')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Sponsor Status</b-th>
								<b-td>{{valueOrNa($parent.item.sponsorStatus)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Sponsor Status Notes</b-th>
								<b-td>{{valueOrNa($parent.item.sponsorStatusNote, 'None')}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'SrProjectPrint',
		components: { PrintMenu }
	}
</script>