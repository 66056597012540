<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control Structural Repair Program<br />Construction Phase for Project #{{$parent.project.projectNumber}}</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-table-simple small class="table-definitions">
				<b-tbody>
					<b-tr>
						<b-th>Dam</b-th>
						<b-td>
							<span v-if="isNullOrEmpty($parent.dam.id)">N/A</span>
							<span v-else>{{$parent.dam.name}}</span>
						</b-td>
					</b-tr>
					<b-tr>
						<b-th>Project</b-th>
						<b-td>
							#{{$parent.project.projectNumber}} - {{$parent.project.name}}
						</b-td>
					</b-tr>
					<b-tr>
						<b-th>Engineering Estimate</b-th>
						<b-td>{{money($parent.item.bidEngEst)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Engineering Estimate Date</b-th>
						<b-td>{{$parent.item.bidEngEstDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Engineering Estimate Notes</b-th>
						<b-td>{{valueOrNa($parent.item.bidEngEstNotes)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Base Contract Amount</b-th>
						<b-td>{{money($parent.item.baseContractAmount)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>State Portion of Cost-Share</b-th>
						<b-td>{{money(stateCostShareAmount)}} ({{$parent.item.stateCostSharePercent}}%)</b-td>
					</b-tr>
					<b-tr>
						<b-th>In-Kind Paid to Sponsors</b-th>
						<b-td>{{money(sponsorAdminCostShareAmount)}} ({{$parent.item.sponsorAdminCostSharePercent}}%)</b-td>
					</b-tr>
					<b-tr>
						<b-th>Total State Funds</b-th>
						<b-td>{{money(totalStateFunds)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Sponsor Responsible</b-th>
						<b-td>{{money(sponsorResponsible)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Agreement/PO#</b-th>
						<b-td>{{$parent.item.agreementPoNum}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Accepted Final Bid</b-th>
						<b-td>{{money($parent.item.finalBid)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Accepted Final Bid Date</b-th>
						<b-td>{{$parent.item.finalBidDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Inspecting Agent</b-th>
						<b-td>{{valueOrNa($parent.item.inspectingAgent)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Final Construction Cost</b-th>
						<b-td>{{money($parent.item.finalCost)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>As-Built Plans Received Date</b-th>
						<b-td>{{$parent.item.asBuiltReceivedDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>As-Built Plans Notes</b-th>
						<b-td>{{valueOrNa($parent.item.asBuiltNotes)}}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>

			
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'SrConstructionPrint',
		components: { PrintMenu }
	}
</script>