<template>
	<div>
		<auth-container v-if="$route.name === 'SrConstructionList'" :page="page">
			<h2 class="mb-4">Construction Phases</h2>

			<div class="mx-auto">
				<b-row class="mb-3" align-v="end">
					<b-col lg="6">
						<b-form inline>
							<b-input-group>
								<b-form-input v-model="table.filter" placeholder="Search..." @input="filterChg"></b-form-input>

								<b-input-group-append class="pointer mr-2">
									<b-input-group-text @click="clearFilter">
										<font-awesome-icon :icon="['fas', 'times']" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
							<b-button variant="primary" type="button" @click="table.showAdvancedFilter = !table.showAdvancedFilter">Advanced Filter</b-button>
						</b-form>
					</b-col>
					<b-col lg="6" class="text-right">
						<div class="ml-auto">
							Showing {{showingFirst}} - {{showingLast}}
							of {{table.total}}
						</div>
					</b-col>
				</b-row>

				<b-collapse v-model="table.showAdvancedFilter" class="my-3">
					<b-card bg-variant="light">
						<b-row>
							<b-col lg="4">
								<div class="form-group row">
									<label for="advancedFilter.projectNumber" class="col-lg-5 col-form-label">Project #</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.projectNumber"
													 v-model="advancedFilter.projectNumber"
													 :data="advancedFilter.options.projectNumber"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.projectName" class="col-lg-5 col-form-label">Project Name</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.projectName"
													 v-model="advancedFilter.projectName"
													 :data="advancedFilter.options.projectName"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.dam" class="col-lg-5 col-form-label">Dam NID_ID</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.dam"
													 v-model="advancedFilter.dam"
													 :data="advancedFilter.options.dam"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.agreementPoNum" class="col-lg-5 col-form-label">Agreement/PO#</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.agreementPoNum"
													 v-model="advancedFilter.agreementPoNum"
													 :data="advancedFilter.options.agreementPoNum"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>
							</b-col>
							<b-col lg>
								<div class="form-group row" v-for="field in filterDateFields" :key="field.value">
									<label for="advancedFilter[field.value].start" class="col-lg-4 col-form-label">{{field.text}}</label>
									<div class="col-lg-8">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter[field.value].start" v-model="advancedFilter[field.value].start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter[field.value].start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter[field.value].end" v-model="advancedFilter[field.value].end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter[field.value].end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
						<hr class="my-3" />
						<div>
							<b-button variant="primary" type="button" @click="get" class="mr-2">Apply</b-button>
							<b-button variant="secondary" type="button" @click="clearAdvancedFilter" class="mr-2">Clear</b-button>
							<b-button variant="secondary" type="button" @click="table.showAdvancedFilter = false">Close</b-button>
						</div>
					</b-card>
				</b-collapse>

				<b-table striped responsive table-class="table-fixed-height border-bottom table-grid" sticky-header="60vh"
						 small show-empty
						 :items="items" :busy="table.loading"
						 :fields="table.fields"
						 :no-local-sorting="true"
						 :sort-by.sync="table.sort"
						 :sort-desc.sync="table.reverse"
						 @sort-changed="sortChg">
					<template #table-busy>
						<div class="text-center my-4">
							<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
						</div>
					</template>
					<template #cell(edit)="data">
						<router-link :to="`${data.item.applicationId}`" append>
							<font-awesome-icon :icon="['fas', 'edit']" />
						</router-link>
					</template>
					<template #cell(projectNumber)="data">
						<router-link :to="`/sr/projects/${data.item.applicationId}`">{{data.value}}</router-link>
					</template>
					<template #cell(damId)="data">
						<router-link :to="`/dams/${data.value}`">{{data.item.nid_id}}</router-link>
					</template>
					<template #empty>
						<h4>No records found.</h4>
					</template>
					<template #emptyfiltered>
						<h4>No records found matching "{{table.filter}}".</h4>
					</template>
				</b-table>
			</div>

			<fixed-action-bar>
				<router-link v-if="!isReadOnly" to="create" append class="btn btn-success mr-2">
					Create
				</router-link>
				<json-to-csv :api-url="`${apiUrl}/export`" :filename="apiUrl" class="mr-2" />
				<b-button v-if="isAuthorized(roleNames.superAdmin)" variant="primary" @click="page.showModal = true" class="mr-2">Import</b-button>
				<b-pagination class="ml-auto mb-0" v-if="table.total > table.itemsPerPage" size="md" :total-rows="table.total" v-model="table.currentPage" :per-page="table.itemsPerPage" @change="pageChg" />
			</fixed-action-bar>

			<b-modal v-model="page.showModal" title="Upload" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<p class="mt-3">
					<strong>Recommended developer upload only.</strong>
				</p>

				<div slot="modal-footer">
					<json-to-csv :json-data="template" :filename="`${apiUrl}-template`" text="Download Template" class="mr-2" />
					<b-button type="button" variant="primary" @click="page.showModal = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import _ from 'underscore';

	export default {
		name: 'SrConstructionList',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				apiUrl: 'srconstructions',
				page: {
					errors: [],
					loading: true,
					showLogin: false,
					saveErrors: [],
					showModal: false
				},
				dropzoneOptions: {
					url: '/api/srconstructions/upload/',
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				table: {
					fields: [
						{ key: 'edit', label: '', stickyColumn: true },
						{ key: 'projectNumber', label: 'Project #', sortable: true },
						{ key: 'damId', label: 'NID_ID', sortable: true },
						{ key: 'bidEngEst', label: 'Eng. Est. Bid', sortable: true, formatter: (value) => { return this.money(value); } },
						{ key: 'bidEngEstDate', label: 'Bid Date', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'bidEngEstNotes', label: 'Bid Notes', sortable: true },
						{ key: 'baseContractAmount', label: 'Base Contract', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'stateCostSharePercent', label: 'State Cost-Share %', sortable: true, class: 'text-right', formatter: (value) => { return this.isNullOrEmpty(value) ? '' : value + '%'; } },
						{ key: 'stateCostShareAmount', label: 'State Amount', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'sponsorAdminCostSharePercent', label: 'Sponsor Cost-Share %', sortable: true, class: 'text-right', formatter: (value) => { return this.isNullOrEmpty(value) ? '' : value + '%'; } },
						{ key: 'sponsorAdminCostShareAmount', label: 'Sponsor Amount', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'totalStateFunds', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'sponsorResponsible', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'agreementPoNum', label: 'Agreement/PO#', sortable: true },
						{ key: 'finalBid', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'finalBidDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'inspectingAgent', sortable: true },
						{ key: 'finalCost', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'asBuiltReceivedDate', label: 'As-Built Received', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'asBuiltNotes', label: 'As-Built Notes', sortable: true },
					],
					sort: 'projectNumber',
					reverse: true,
					itemsPerPage: 100,
					filter: null,
					currentPage: 1,
					total: 0,
					loading: false,
					showAdvancedFilter: false
				},
				items: [],
				advancedFilter: {
					dam: null,
					projectNumber: null,
					projectName: null,
					agreementPoNum: null,
					inspectingAgent: null,
					bidEngEstDate: {
						start: null,
						end: null
					},
					finalBidDate: {
						start: null,
						end: null
					},
					asBuiltReceivedDate: {
						start: null,
						end: null
					},
					options: {
						projectNumber: [],
						projectName: [],
						agreementPoNum: [],
						inspectingAgent: [],
						dam: []
					}
				},
				template: [{
					projectNumber: null,
					bidEngEst: null,
					bidEngEstDate: null,
					bidEngEstNotes: null,
					baseContractAmount: null,
					stateCostSharePercent: null,
					sponsorAdminCostSharePercent: null,
					agreementPoNum: null,
					finalBid: null,
					finalBidDate: null,
					inspectingAgent: null,
					finalCost: null,
					asBuiltReceivedDate: null,
					asBuiltNotes: null,
				}],
				filterDateFields: [
					{ value: 'bidEngEstDate', text: 'Date of Engineering Estimate' },
					{ value: 'finalBidDate', text: 'Date of Accepted Bid' },
					{ value: 'asBuiltReceivedDate', text: 'Date As-Built Plans Received' }
				]
			}
		},
		computed: {
			showingFirst() {
				return (this.table.currentPage - 1) * this.table.itemsPerPage + 1
			},
			showingLast() {
				var max = (this.table.currentPage - 1) * this.table.itemsPerPage + this.table.itemsPerPage
				return max > this.table.total ? this.table.total : max;
			},
			advancedFilterProjectNumber() {
				return this.advancedFilter.projectNumber;
			},
			advancedFilterProjectName() {
				return this.advancedFilter.projectName;
			},
			advancedFilterAgreementNumber() {
				return this.advancedFilter.agreementPoNum;
			},
			advancedFilterInspectingAgent() {
				return this.advancedFilter.inspectingAgent;
			},
			advancedFilterDam() {
				return this.advancedFilter.dam;
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get',
			advancedFilterProjectNumber: _.debounce(function (query) { this.find('projectNumber', query) }, 500),
			advancedFilterProjectName: _.debounce(function (query) { this.find('projectName', query) }, 500),
			advancedFilterAgreementNumber: _.debounce(function (query) { this.find('agreementPoNum', query) }, 500),
			advancedFilterInspectingAgent: _.debounce(function (query) { this.find('inspectingAgent', query) }, 500),
			advancedFilterDam: _.debounce(function (query) { this.findDams('nid_id', query) }, 500)
		},
		methods: {
			getDb() {
				let sort = this.table.reverse ? this.table.sort + '_desc' : this.table.sort;
				let filter = this.table.filter !== null ? `&filter=${encodeURIComponent(this.table.filter)}` : '';

				let filterComplex = '';
				let filterValues = [];

				let stringFields = ['dam', 'projectNumber', 'projectName', 'agreementPoNum', 'inspectingAgent'];
				let twoPartFields = ['bidEngEstDate', 'finalBidDate', 'asBuiltReceivedDate'];

				for (let f of stringFields) {
					if (!this.isNullOrEmpty(this.advancedFilter[f])) {
						filterValues.push(`${f}:${this.advancedFilter[f]}`);
					}
				}

				for (let f of twoPartFields) {
					if (!this.isNullOrEmpty(this.advancedFilter[f].start) || !this.isNullOrEmpty(this.advancedFilter[f].end)) {
						let s = this.advancedFilter[f].start;
						let e = this.advancedFilter[f].end;
						s = s === null ? 'any' : s;
						e = e === null ? 'any' : e;
						filterValues.push(`${f}:${s},${e}`);
					}
				}

				if (filterValues.length > 0) filterComplex = '&filterComplex=' + filterValues.join('|');

				return this.$http.get(`${this.apiUrl}?sort=${sort}&page=${this.table.currentPage}&perPage=${this.table.itemsPerPage}${filter}${filterComplex}`, this.getTokenHeader());
			},
			async filterChg() {
				this.table.currentPage = 1;
				_.debounce(await this.get(), 500);
			},
			async pageChg(page) {
				this.table.currentPage = page;
				await this.get();
			},
			async sortChg(ctx) {
				this.table.sort = ctx.sortBy;
				this.table.reverse = ctx.sortDesc;
				this.table.specialSort = undefined;

				await this.get();
			},
			async get() {
				this.page.errors = [];
				this.table.loading = true;

				try {
					const response = await this.getDb();
					this.log(response.data);

					if (response.data.items.length == 0 && this.table.currentPage > 1) {
						this.table.currentPage--;
						await this.get();
					}

					this.items = response.data.items;
					this.table.total = response.data.total;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.table.loading = false;
				this.page.loading = false;
			},
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				await this.get();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async findDams(field, query) {
				try {
					const response = await this.$http.get(`/dams/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options.dam = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async clearFilter() {
				this.table.filter = null;
				await this.get();
			},
			async clearAdvancedFilter() {
				this.advancedFilter = {
					dam: null,
					projectNumber: null,
					projectName: null,
					agreementPoNum: null,
					inspectingAgent: null,
					bidEngEstDate: {
						start: null,
						end: null
					},
					finalBidDate: {
						start: null,
						end: null
					},
					asBuiltReceivedDate: {
						start: null,
						end: null
					},
					options: {
						projectNumber: [],
						projectName: [],
						agreementPoNum: [],
						inspectingAgent: [],
						dam: []
					}
				};

				await this.get();
			}
		}
	}
</script>