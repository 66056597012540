<template>
	<div>
		<b-button v-if="!optionButtonBelow && maps.items.length > 0" class="mb-3 d-print-none" type="button" variant="secondary" @click="page.editColors.show = true">Map Options</b-button>

		<b-card v-if="tabs" no-body class="mb-3">
			<b-tabs pills card>
				<b-tab v-for="(map, i) in maps.items" :key="i" :title="map.title.text">
					<div>
						<highcharts :constructor-type="'mapChart'" :options="map" class="map"></highcharts>
					</div>
				</b-tab>
			</b-tabs>
		</b-card>

		<b-row v-else class="mb-3">
			<b-col :md="numCols" v-for="(map, i) in maps.items" :key="i">
				<div class="mb-3">
					<highcharts :constructor-type="'mapChart'" :options="map" class="map"></highcharts>
				</div>
			</b-col>
		</b-row>

		<b-button v-if="optionButtonBelow && maps.items.length > 0" class="d-print-none" type="button" variant="secondary" @click="page.editColors.show = true">Map Options</b-button>

		<b-modal v-model="page.editColors.show" size="lg" title="Change map colors">
			<b-row>
				<b-col md>
					<b-form-group label="Minimum value color">
						<b-form-input type="color" v-model="colors.min"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Median value color">
						<b-form-input type="color" v-model="colors.mid"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Maximum value color">
						<b-form-input type="color" v-model="colors.max"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<div class="mb-2">
						<b-button @click="reverseColors" variant="light" typeof="button">Reverse min/max colors</b-button>
					</div>
					<div v-if="!showLabels">
						<b-form-checkbox v-model="page.showLabels" switch>
							Show labels
						</b-form-checkbox>
					</div>
				</b-col>
			</b-row>

			<div slot="modal-footer">
				<save-button type="button" :saving="false" @click.native="drawMaps(); page.editColors.show = false" text="Ok" />
				<b-button type="button" variant="secondary" @click="page.editColors.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	export default {
		name: 'MapVisualizer',
		props: {
			colorOverride: {
				type: Object,
				default: null
			},
			mapData: {
				type: Array,
				default: () => []
			},
			numCols: {
				type: Number,
				default: 6
			},
			optionButtonBelow: {
				type: Boolean,
				default: false
			},
			showLabels: {
				type: Boolean,
				default: false
			},
			mapHeight: {
				type: Number,
				default: null
			},
			valueFormat: {
				type: String,
				default: 'money'
			},
			tabs: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					editColors: {
						show: false
					},
					showLabels: false
				},
				geojson: {
					swcds: {},
					layers: []
				},
				colors: {
					min: '#f2f2d0',
					mid: '#9be02c',
					max: '#24a00b',
					hover: '#70dbe5',
					scale: 'linear'
				},
				maps: {
					items: []
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			mapData(newVal, oldVal) {
				this.log(oldVal);
				this.log(newVal);
				this.drawMaps(false);
			}
		},

		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('home', this.getTokenHeader());
					this.log(response.data);
					this.geojson.swcds = response.data.swcds;
					this.geojson.layers = response.data.layers;

					if (this.colorOverride !== null) {
						this.colors = this.colorOverride;
					}

					this.drawMaps(true);
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			reverseColors() {
				var max = this.colors.max;
				this.colors.max = this.colors.min;
				this.colors.min = max;
			},
			getColorAxis(map, initialize = false) {
				if (initialize && (map.maxValue - map.minValue) >= 10000 && map.minValue > 0) {
					this.colors.scale = 'logarithmic';
				}

				return {
					type: !map.allowLogAxis ? 'linear' : this.colors.scale,
					allowNegativeLog: true,
					minColor: this.colors.min,
					maxColor: this.colors.max,
					stops: [
						[0, this.colors.min],
						[0.1, this.colors.mid],
						[1, this.colors.max]
					]
				}
			},
			getGeojsonLayer(mapTitle) {
				let filtered = this.geojson.layers.filter(function (el) { return mapTitle.includes(el.name); });
				if (filtered.length > 0) return filtered[0].geojson;
				return this.geojson.swcds;
			},
			drawMaps(initialize = false) {
				this.maps.items = [];

				for (var i = 0; i < this.mapData.length; i++) {
					var map = this.mapData[i];

					let pointFormat = '{point.properties.label} {point.properties.name}: {point.value}';
					if (this.valueFormat === 'money') pointFormat = '{point.properties.label} {point.properties.name}: ${point.value:,.2f}';
					if (map.mapTitle.includes('SWCD')) {
						pointFormat = '{point.code} - {point.properties.name}: {point.value}';
						if (this.valueFormat === 'money') pointFormat = '{point.code} - {point.properties.name}: ${point.value:,.2f}';
					}

					this.maps.items.push(
						{
							chart: {
								height: this.mapHeight
							},
							title: { text: map.mapTitle },
							legend: {
								title: {
									text: map.legendTitle
								}
							},
							exporting: {
								buttons: {
									contextButton: {
										menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
									}
								}
							},
							colorAxis: this.getColorAxis(map, initialize),
							xAxis: {
								min: map.xAxisMin,
								max: map.xAxisMax
							},
							yAxis: {
								min: map.yAxisMin,
								max: map.yAxisMax
							},
							mapNavigation: {
								enabled: true
							},
							series: [{
								data: map.mapData,
								mapData: this.getGeojsonLayer(map.mapTitle),
								joinBy: ['id', 'code'],
								name: map.mapDataValuesName,
								states: {
									hover: {
										color: this.colors.hover
									}
								},
								dataLabels: {
									enabled: this.showLabels || this.page.showLabels,
									format: '{point.value}'
								},
								tooltip: {
									pointFormat: pointFormat
								}
							}]
						}
					)
				}
				this.log(this.maps.items);
			}
		}
	}
</script>
