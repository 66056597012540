<template>
	<div>
		<auth-container :page="page" v-if="$route.name === 'SrInvoiceHubEdit'">
			<h2 class="mb-4">HUB Log</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview">
					<b-table-simple small class="table-definitions">
						<b-tbody>
							<b-tr>
								<b-th>Invoice #</b-th>
								<b-td>
									<router-link :to="`/sr/workorders/invoices/${invoice.id}`">{{invoice.name}}</router-link>
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Contractor</b-th>
								<b-td>{{valueOrNa(item.contractor)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>VID/EIN of Contractor</b-th>
								<b-td>{{valueOrNa(item.vidEin)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Amount</b-th>
								<b-td>{{money(item.amount)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Notes</b-th>
								<b-td>{{valueOrNa(item.notes, 'None')}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<fixed-action-bar>
						<b-button type="button" variant="primary" to="print" append target="_blank" class="mr-2">Print</b-button>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<sr-invoice-hub-form ref="itemForm" :item="item" :invoice="invoice" is-update @change="changesSaved"></sr-invoice-hub-form>
				</b-tab>
				<b-tab title="Files">
					<file-manager :apiUrl="apiUrl" :files="files" :itemId="itemId" itemLabel="application" @change="filesChanged"></file-manager>
				</b-tab>
				<b-tab title="Change Log">
					<log-viewer db-table="SrWorkOrderInvoices" :item-id="itemId"></log-viewer>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this invoice?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import FileManager from '@/components/FileManager';
	import SrInvoiceHubForm from './SrInvoiceHubForm.vue';

	export default {
		name: 'SrInvoiceHubEdit',
		components: {
			SrInvoiceHubForm, FileManager
		},
		data() {
			return {
				apiUrl: 'srworkorderinvoicehubs',
				listRoute: 'SrInvoiceHubList',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				item: {},
				invoice: {},
				files: []
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.hub;
					this.invoice = response.data.invoice;
					this.files = response.data.files;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
				this.page.tabIndex = 1;
			},
			async filesChanged() {
				await this.get();
				this.page.tabIndex = 2;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			}
		}
	}
</script>