<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<h3 class="h5 border-bottom my-4">Projects Referenced in the Agreement</h3>

			<b-row>
				<b-col md="6">
					<b-typeahead placeholder="Type to search projects by number, name, or dam..." :minMatchingChars="2"
								 :data="options.projects == null ? [] : options.projects"
								 v-model="query.project"
								 :serializer="s => s.name" @hit="query.projectObject = $event; addProjectRelation()"
								 :max-matches="100">
					</b-typeahead>

					<div>
						<b-button v-for="(p, i) in projectRelations" :key="i" variant="light" size="sm" class="text-left my-2 d-flex align-items-center" block v-b-tooltip.hover="'Click to remove from agreement'" @click="removeProjectRelation(p)">
							<span>{{p.name}}</span>
							<font-awesome-icon :icon="['fas', 'times']" class="text-danger ml-auto" />
						</b-button>
					</div>
				</b-col>
			</b-row>

			<h3 class="h5 border-bottom my-4">Agreement Information</h3>

			<b-row>
				<b-col md>
					<b-form-group label="Agreement #" :invalid-feedback="requiredFeedback($v.item.agreementNumber)">
						<b-form-input type="text" required 
									  v-model.trim="$v.item.agreementNumber.$model" :state="getValidState($v.item.agreementNumber)" />
					</b-form-group>

					<b-form-group label="State PO#" :invalid-feedback="requiredFeedback($v.item.statePo)">
						<b-form-input type="text"
									  v-model.trim="$v.item.statePo.$model" :state="getValidState($v.item.statePo)" />
					</b-form-group>

					<b-form-group label="Agreement Appropriation Fund Source ID" :invalid-feedback="requiredFeedback($v.item.fundSourceId)">
						<b-typeahead v-model.trim="$v.item.fundSourceId.$model"
									 :data="setOptions.fundSourceIds"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Fiscal Year Funds Were Obligated" :invalid-feedback="requiredFeedback($v.item.fundFiscalYear)">
						<b-form-input type="number"
									  v-model.trim.number="$v.item.fundFiscalYear.$model" :state="getValidState($v.item.fundFiscalYear)" />
					</b-form-group>

					<b-form-group label="Offeror" :invalid-feedback="requiredFeedback($v.item.offeror)">
						<b-typeahead v-model.trim="$v.item.offeror.$model"
									 :data="setOptions.offerors"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Offeree" :invalid-feedback="requiredFeedback($v.item.offeree)">
						<b-typeahead v-model.trim="$v.item.offeree.$model"
									 :data="setOptions.offerees"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Contracting Entity" :invalid-feedback="requiredFeedback($v.item.contractor)">
						<b-typeahead v-model.trim="$v.item.contractor.$model"
									 :data="options.contractor"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Agreement Type" :invalid-feedback="requiredFeedback($v.item.agreementType)">
						<b-typeahead v-model.trim="$v.item.agreementType.$model"
									 :data="setOptions.agreementTypes"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Status" :invalid-feedback="requiredFeedback($v.item.status)">
						<b-typeahead v-model.trim="$v.item.status.$model"
									 :data="setOptions.statuses"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Amendment #" :invalid-feedback="requiredFeedback($v.item.amendmentNumber)">
						<b-form-input type="number"
									  v-model.trim.number="$v.item.amendmentNumber.$model" :state="getValidState($v.item.amendmentNumber)" />
					</b-form-group>

					<b-form-group label="Amendment Date" :invalid-feedback="requiredFeedback($v.item.amendmentDate)">
						<b-form-input type="date" v-model.trim="$v.item.amendmentDate.$model" :state="getValidState($v.item.amendmentDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Amendment Notes" :invalid-feedback="requiredFeedback($v.item.amendmentNotes)" class="mb-4">
						<b-form-textarea v-model.trim="$v.item.amendmentNotes.$model" :state="getValidState($v.item.amendmentNotes)" rows="3"></b-form-textarea>
					</b-form-group>

					<b-form-group label="Execution Date" :invalid-feedback="requiredFeedback($v.item.executionDate)">
						<b-form-input type="date" v-model.trim="$v.item.executionDate.$model" :state="getValidState($v.item.executionDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Expiration Date" :invalid-feedback="requiredFeedback($v.item.expirationDate)">
						<b-form-input type="date" v-model.trim="$v.item.expirationDate.$model" :state="getValidState($v.item.expirationDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Last Revised Date" :invalid-feedback="requiredFeedback($v.item.lastRevisedDate)">
						<b-form-input type="date" v-model.trim="$v.item.lastRevisedDate.$model" :state="getValidState($v.item.lastRevisedDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<hr class="my-4" />

			<b-row>
				<b-col md>
					<b-form-group label="Maximum Contract Price" :invalid-feedback="requiredFeedback($v.item.maxContractPrice)">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any"
										  v-model.trim.number="$v.item.maxContractPrice.$model" :state="getValidState($v.item.maxContractPrice)" />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Any Amount Not Cost-Shared" :invalid-feedback="requiredFeedback($v.item.additionalAmount)">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any"
										  v-model.trim.number="$v.item.additionalAmount.$model" :state="getValidState($v.item.additionalAmount)" />
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<h3 class="h5 border-bottom my-4">Total Construction Cost Share</h3>
					<b-row>
						<b-col md>
							<b-form-group label="Reimbursable %" :invalid-feedback="requiredFeedback($v.item.constructionPercent)">
								<b-input-group append="%">
									<b-form-input type="number" step="any"
												  v-model.trim.number="$v.item.constructionPercent.$model" :state="getValidState($v.item.constructionPercent)" />
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Maximum Reimbursable Amount" :invalid-feedback="requiredFeedback($v.item.constructionMaxAmount)">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any"
												  v-model.trim.number="$v.item.constructionMaxAmount.$model" :state="getValidState($v.item.constructionMaxAmount)" />
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<h3 class="h5 border-bottom my-4">Professional Services Cost Share</h3>
					<b-row>
						<b-col md>
							<b-form-group label="Reimbursable %" :invalid-feedback="requiredFeedback($v.item.profServicesPercent)">
								<b-input-group append="%">
									<b-form-input type="number" step="any"
												  v-model.trim.number="$v.item.profServicesPercent.$model" :state="getValidState($v.item.profServicesPercent)" />
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Maximum Reimbursable Amount" :invalid-feedback="requiredFeedback($v.item.profServicesMaxAmount)">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any"
												  v-model.trim.number="$v.item.profServicesMaxAmount.$model" :state="getValidState($v.item.profServicesMaxAmount)" />
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
				</b-col>
				<b-col md>
					<h3 class="h5 border-bottom my-4">Administrative Cost Share Expenses</h3>
					<b-row>
						<b-col md>
							<b-form-group label="Reimbursable %" :invalid-feedback="requiredFeedback($v.item.adminPercent)">
								<b-input-group append="%">
									<b-form-input type="number" step="any"
												  v-model.trim.number="$v.item.adminPercent.$model" :state="getValidState($v.item.adminPercent)" />
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Maximum Reimbursable Amount" :invalid-feedback="requiredFeedback($v.item.adminMaxAmount)">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any"
												  v-model.trim.number="$v.item.adminMaxAmount.$model" :state="getValidState($v.item.adminMaxAmount)" />
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<h3 class="h5 border-bottom my-4">Land Rights Cost Share</h3>
					<b-row>
						<b-col md>
							<b-form-group label="Reimbursable %" :invalid-feedback="requiredFeedback($v.item.landRightsPercent)">
								<b-input-group append="%">
									<b-form-input type="number" step="any"
												  v-model.trim.number="$v.item.landRightsPercent.$model" :state="getValidState($v.item.landRightsPercent)" />
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Maximum Reimbursable Amount" :invalid-feedback="requiredFeedback($v.item.landRightsMaxAmount)">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any"
												  v-model.trim.number="$v.item.landRightsMaxAmount.$model" :state="getValidState($v.item.landRightsMaxAmount)" />
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
				</b-col>
			</b-row>

			<hr class="my-4" />

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)" class="mb-4">
				<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="5"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrAgreementForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			projects: {
				type: Array,
				required: true
			},
			setOptions: {
				type: Object,
				default: () => {
					return {
						fundSourceIds: [],
						agreementTypes: [],
						offerors: [],
						offerees: [],
						statuses: []
					}
				}
			}
		},
		data() {
			return {
				apiUrl: 'sragreements',
				editRoute: 'SrAgreementEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					projects: [],
					contractor: []
				},
				query: {
					project: '',
					projectObject: null
				},
				projectRelations: []
			}
		},
		computed: {
			queryProject() {
				return this.query.project;
			},
			itemContractor() {
				return this.item.contractor;
			},
			projectRelationIds() {
				let ids = [];
				for (let p of this.projectRelations) {
					ids.push(p.id);
				}
				return ids;
			}
		},
		validations: {
			item: {
				statePo: {},
				fundSourceId: {},
				fundFiscalYear: { required, integer },
				agreementType: {},
				offeror: {},
				offeree: {},
				agreementNumber: { required },
				contractor: {},
				amendmentNumber: { integer },
				amendmentDate: {},
				amendmentNotes: {},
				executionDate: {},
				expirationDate: {},
				status: {},
				maxContractPrice: { required, decimal },
				constructionPercent: { required, decimal },
				constructionMaxAmount: { required, decimal },
				profServicesPercent: { required, decimal },
				profServicesMaxAmount: { required, decimal },
				adminPercent: { required, decimal },
				adminMaxAmount: { required, decimal },
				landRightsPercent: { required, decimal },
				landRightsMaxAmount: { required, decimal },
				additionalAmount: { required, decimal },
				lastRevisedDate: {},
				notes: {}
			}
		},
		watch: {
			queryProject: _.debounce(function (query) { this.findProjects(query) }, 500),
			itemContractor: _.debounce(function (query) { this.find('contractor', query) }, 500)
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				this.projectRelations = this.projects;
				this.log(this.setOptions);

				if (this.isUpdate) {
					this.item.amendmentDate = this.dateForForm(this.item.amendmentDate);
					this.item.executionDate = this.dateForForm(this.item.executionDate);
					this.item.expirationDate = this.dateForForm(this.item.expirationDate);
					this.item.lastRevisedDate = this.dateForForm(this.item.lastRevisedDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else if (this.projectRelationIds.length < 1) {
					this.page.errors.push('You must add at least one project to the agreement.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							let data = {
								item1: this.item,
								item2: this.projectRelationIds
							};
							const response = await this.$http.post(this.apiUrl, data, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: {
								item1: this.item,
								item2: this.projectRelationIds
							},
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async findProjects(query) {
				try {
					//const response = await this.$http.get(`/srprojects/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					const response = await this.$http.post(`/srprojects/find-id-post`, { value: query }, this.getTokenHeader());
					this.options.projects = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			addProjectRelation() {
				if (!this.isNullOrEmpty(this.query.projectObject)) {
					this.projectRelations.push(this.query.projectObject);
					this.query.project = '';
					this.query.projectObject = null;
				}
			},
			removeProjectRelation(p) {
				this.projectRelations = this.projectRelations.filter(function (el) { return el.id !== p.id; });
			}
		}
	}
</script>
