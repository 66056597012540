<template>
	<div>
		<auth-container :page="page">
			<h2 class="mb-4">O&amp;M Practice Costs</h2>

			<p>
				Show the minimum, average, maximum, and trends of the costs per unit and number of units performed per practice for reimbursement requests over a selected period of time.
			</p>

			<div v-if="!page.showFilter">
				<b-button variant="primary" type="button" @click="page.showFilter = true">Show Report Options</b-button>
			</div>
			<b-collapse v-model="page.showFilter">
				<b-card bg-variant="light">
					<b-row>
						<b-col md="6">
							<div class="form-group row">
								<label for="reportOptions.filterType" class="col-lg-3 col-form-label">Query by</label>
								<div class="col-lg-9">
									<b-form-select v-model="reportOptions.filterType" id="reportOptions.filterType">
										<b-form-select-option :value="0">Fiscal Year</b-form-select-option>
										<b-form-select-option :value="1">Calendar Year</b-form-select-option>
										<b-form-select-option :value="2">Date Range</b-form-select-option>
									</b-form-select>
								</div>
							</div>

							<div class="form-group row" v-if="reportOptions.filterType === 2">
								<label for="reportOptions.startDate" class="col-lg-3 col-form-label">Date Range</label>
								<div class="col-lg-9">
									<div class="d-flex align-items-center justify-content-start">
										<div class="mr-2">
											<b-input-group>
												<b-form-datepicker id="reportOptions.startDate" v-model="reportOptions.startDate"
																   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

												<b-input-group-append class="pointer">
													<b-input-group-text @click="reportOptions.startDate = null">
														<font-awesome-icon :icon="['fas', 'times']" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</div>
										<div>
											<b-input-group>
												<b-form-datepicker id="reportOptions.endDate" v-model="reportOptions.endDate"
																   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

												<b-input-group-append class="pointer">
													<b-input-group-text @click="reportOptions.endDate = null">
														<font-awesome-icon :icon="['fas', 'times']" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group row" v-else>
								<label for="reportOptions.startYear" class="col-lg-3 col-form-label">Year Range</label>
								<div class="col-lg-9">
									<div class="d-flex align-items-center justify-content-start">
										<div class="mr-2">
											<b-form-input v-model="reportOptions.startYear" type="number" placeholder="Start"></b-form-input>
										</div>
										<div>
											<b-form-input v-model="reportOptions.endYear" type="number" placeholder="End"></b-form-input>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group row" v-if="false">
								<label for="reportOptions.trendInterval" class="col-lg-3 col-form-label">Trend Interval</label>
								<div class="col-lg-9">
									<b-form-select v-model="reportOptions.trendInterval" id="reportOptions.trendInterval">
										<b-form-select-option :value="0">Quarterly</b-form-select-option>
										<b-form-select-option :value="1">Monthly</b-form-select-option>
										<b-form-select-option :value="2">Yearly</b-form-select-option>
									</b-form-select>
								</div>
							</div>

							<div class="form-group row">
								<label for="reportOptions.sponsorId" class="col-lg-3 col-form-label">Sponsor</label>
								<div class="col-lg-9">
									<b-form-select v-model="reportOptions.sponsorId" id="reportOptions.sponsorId" :options="options.sponsors">
										<template #first>
											<b-form-select-option :value="null">Any</b-form-select-option>
										</template>
									</b-form-select>
								</div>
							</div>
						</b-col>
						<b-col md>
							<div>
								<div class="checklist-header bg-white">
									<b-form-checkbox v-model="options.selectAllPractices" @change="toggleAllPractices">
										Practices
									</b-form-checkbox>
								</div>
								<div class="checklist">
									<b-form-checkbox-group v-model="reportOptions.practiceIds" stacked>
										<div class="item" v-for="(o, i) in options.practices" :key="i">
											<b-form-checkbox :value="o.value">{{o.text}}</b-form-checkbox>
										</div>
									</b-form-checkbox-group>
								</div>
							</div>
						</b-col>
					</b-row>

					<hr class="my-3" />
					<div>
						<b-button variant="primary" type="button" @click="loadChartData" class="mr-2">Apply</b-button>
						<b-button variant="secondary" type="button" @click="page.showFilter = false">Hide</b-button>
					</div>
				</b-card>
			</b-collapse>

			<page-loading :loading="page.chartLoading"></page-loading>
			<div v-if="!page.chartLoading">
				<div class="mt-4">
					<highcharts :options="unitCostBoxPlot" :updateArgs="updateArgs"></highcharts>
				</div>
				<div class="mt-4">
					<highcharts :options="unitCostTrendPlot" :updateArgs="updateArgs"></highcharts>
				</div>
				<div class="mt-4">
					<highcharts :options="unitCostByDatePlot" :updateArgs="updateArgs"></highcharts>
				</div>
				<div class="mt-4">
					<highcharts :options="unitsPerformedPlot" :updateArgs="updateArgs"></highcharts>
				</div>
			</div>
		</auth-container>
	</div>
</template>

<script>
	import Highcharts from 'highcharts';

	export default {
		name: 'OmPracticeReport',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					noData: false,
					chartLoading: false,
					showFilter: true
				},
				reportOptions: {
					filterType: 0,
					startYear: null,
					endYear: null,
					startDate: null,
					endDate: null,
					sponsorId: null,
					practiceIds: [],
					trendInterval: 0
				},
				appliedOptions: {
					filterType: 0,
					startYear: null,
					endYear: null,
					startDate: null,
					endDate: null,
					sponsorId: null,
					practiceIds: [],
					trendInterval: 0
				},
				options: {
					fiscalYears: [],
					sponsors: [],
					practices: [],
					selectAllPractices: true
				},
				data: {
					categories: [],
					unitCost: [],
					unitCostAvg: [],
					unitsPerformed: [],
					unitCostByDate: [],
					unitCostTrend: [],
					trendCategories: []
				},
				updateArgs: [true, true, { duration: 1000 }]
			}
		},
		async created() {
			Highcharts.seriesType('lowmedhigh', 'boxplot', {
				keys: ['low', 'median', 'high'],
				tooltip: {
					pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: ' +
						'Minimum <b>{point.low}</b> - Median <b>{point.median}</b> - Maximum <b>{point.high}</b><br/>'
				}
			}, {
				// Change point shape to a line with three crossing lines for low/median/high
				// Stroke width is hardcoded to 1 for simplicity
				drawPoints: function () {
					const series = this;
					this.points.forEach(function (point) {
						let graphic = point.graphic;
						const verb = graphic ? 'animate' : 'attr',
							shapeArgs = point.shapeArgs,
							width = shapeArgs.width,
							left = Math.floor(shapeArgs.x) + 0.5,
							right = left + width,
							crispX = left + Math.round(width / 2) + 0.5,
							highPlot = Math.floor(point.highPlot) + 0.5,
							medianPlot = Math.floor(point.medianPlot) + 0.5,
							// Sneakily draw low marker even if 0
							lowPlot = Math.floor(point.lowPlot) +
								0.5 - (point.low === 0 ? 1 : 0);

						if (point.isNull) {
							return;
						}

						if (!graphic) {
							point.graphic = graphic = series.chart.renderer
								.path('point')
								.add(series.group);
						}

						graphic.attr({
							stroke: point.color || series.color,
							'stroke-width': 2
						});

						graphic[verb]({
							d: [
								'M', left, highPlot,
								'H', right,
								'M', left, medianPlot,
								'H', right,
								'M', left, lowPlot,
								'H', right,
								'M', crispX, highPlot,
								'V', lowPlot
							]
						});
					});
				}
			});

			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`funds/list`, this.getTokenHeader());
					this.log(response.data);

					this.options.fiscalYears = response.data;
					if (this.options.fiscalYears.length > 0) {
						this.reportOptions.startYear = this.options.fiscalYears[0];
						this.reportOptions.endYear = this.options.fiscalYears[0];
					}

					const response2 = await this.$http.get(`omsponsors/list`, this.getTokenHeader());
					this.log(response2.data);

					this.options.sponsors = response2.data;

					const response3 = await this.$http.get(`omactivities/list`, this.getTokenHeader());
					this.log(response3.data);

					this.options.practices = response3.data;
					this.toggleAllPractices(true);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				await this.loadChartData();

				this.page.loading = false;
			},
			async loadChartData() {
				this.page.chartLoading = true;
				try {
					const response = await this.$http.post(`omreports/practices`, this.reportOptions, this.getTokenHeader());
					this.log(response.data);

					this.data = response.data;
					this.appliedOptions = Object.assign({}, this.reportOptions);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else if (this.isApiNotFound(error)) this.page.noData = true;
					else this.page.errors = this.logError(error);
				}
				this.page.chartLoading = false;
			},
			toggleAllPractices(checked) {
				this.reportOptions.practiceIds = checked ? this.options.practices.map(function (i) { return i.value }) : [];
			}
		},
		computed: {
			reportDateDescription() {
				let year = this.appliedOptions.startYear;
				if (this.isNullOrEmpty(this.appliedOptions.startYear)) year = 'Beginning';

				let eyear = this.appliedOptions.endYear;
				if (this.isNullOrEmpty(this.appliedOptions.endYear)) eyear = 'End';

				if (this.appliedOptions.startYear !== this.appliedOptions.endYear) year += '-' + eyear;

				let startDate = this.isNullOrEmpty(this.appliedOptions.startDate) ? 'Beginning' : this.toDate(this.appliedOptions.startDate, 'M/D/YYYY');
				let endDate = this.isNullOrEmpty(this.appliedOptions.endDate) ? 'End' : this.toDate(this.appliedOptions.endDate, 'M/D/YYYY');

				switch (this.appliedOptions.filterType)
				{
					case 0:
						return `FY ${year}`;
					case 1:
						return `CY ${year}`;
					case 2:
						return `${startDate} to ${endDate}`;
				}
				return '';
			},
			unitCostBoxPlot() {
				return {
					title: {
						text: `Unit Cost ${this.reportDateDescription}`
					},
					legend: {
						enabled: true
					},
					xAxis: {
						categories: this.data.categories,
						title: {
							text: 'Practice Code'
						}
					},
					yAxis: {
						title: {
							text: 'Unit Cost'
						}
					},
					tooltip: {
						shared: true
					},
					series: [
						{
							name: 'Average',
							type: 'spline',
							data: this.data.unitCostAvg,
							tooltip: {
								headerFormat: '<em><b>Practice {point.key}</b></em><br/>',
								pointFormat: '<b>Average</b>: ${point.y:.2f}<br>'
							}
						},
						{
							name: 'Max/Median/Min',
							type: 'lowmedhigh',
							data: this.data.unitCost,
							tooltip: {
								headerFormat: '<em><b>Practice {point.key}</b></em><br/>',
								pointFormat: '<b>Maximum</b>: ${point.high:.2f}<br><b>Median</b>: ${point.median:.2f}<br><b>Minimum</b>: ${point.low:.2f}'
							}
						},
					]
				}
			},
			unitsPerformedPlot() {
				return {
					chart: {
						type: 'column'
					},
					title: {
						text: `Total Units Performed ${this.reportDateDescription}`
					},
					tooltip: {
						pointFormat: '<b>Total Units</b>: {point.y:.1f}'
					},
					legend: {
						enabled: false
					},
					xAxis: {
						categories: this.data.categories,
						title: {
							text: 'Practice Code'
						}
					},
					yAxis: {
						title: {
							text: 'Units Performed'
						}
					},
					series: [{
						data: this.data.unitsPerformed,
						tooltip: {
							headerFormat: '<em><b>Practice {point.key}</b></em><br/>'
						}
					}]
				}
			},
			unitCostByDatePlot() {
				return {
					chart: {
						type: 'spline'
					},
					title: {
						text: `Unit Cost by Invoice Date ${this.reportDateDescription}`
					},
					subtitle: {
						text: `Only practices with reimbursement request activity are shown`
					},
					tooltip: {
						pointFormat: '<b>Avg. Invoiced Cost/Unit</b>: ${point.y:.2f}',
						headerFormat: '<em><b>Practice {series.name}</b></em><br/><b>Invoice Date:</b> {point.x:%m/%d/%Y}<br>'
					},
					legend: {
						enabled: true
					},
					xAxis: {
						type: 'datetime',
						title: {
							text: 'Activity Date'
						}
					},
					yAxis: {
						title: {
							text: 'Unit Cost'
						}
					},
					series: this.data.unitCostByDate
				}
			},
			unitCostTrendPlot() {
				return {
					chart: {
						type: 'spline',
						height: 600
					},
					title: {
						text: `Quarterly Unit Cost Trend ${this.reportDateDescription}`
					},
					subtitle: {
						text: `Only practices with reimbursement request activity are shown`
					},
					tooltip: {
						pointFormat: '<b>Avg. Invoiced Cost/Unit</b>: ${point.y:.2f}',
						headerFormat: '<em><b>Practice {series.name} - {point.x}</b></em><br/>'
					},
					legend: {
						enabled: true
					},
					xAxis: {
						categories: this.data.trendCategories,
						title: {
							text: 'Trend Interval'
						}
					},
					yAxis: {
						title: {
							text: 'Unit Cost'
						}
					},
					series: this.data.unitCostTrend
				}
			}
		}
	}
</script>