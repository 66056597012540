<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col md>
					<b-form-group label="Project #" :invalid-feedback="requiredFeedback($v.item.projectNumber)">
						<b-form-input type="text" required
									  v-model.trim="$v.item.projectNumber.$model" :state="getValidState($v.item.projectNumber)" />
					</b-form-group>

					<b-form-group label="Application #" :invalid-feedback="requiredFeedback($v.query.appNum)">
						<b-typeahead v-model.trim="$v.query.appNum.$model" required
									 :data="options.appNums"
									 :serializer="s => s.name" @hit="item.applicationId = $event.id; item.fundCycle = $event.fundCycle"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Project Name" :invalid-feedback="requiredFeedback($v.item.name)">
						<b-form-input type="text" required
									  v-model.trim="$v.item.name.$model" :state="getValidState($v.item.name)" />
					</b-form-group>

					<b-form-group label="Expected Completion Date" :invalid-feedback="requiredFeedback($v.item.expectedCompletionDate)">
						<b-form-input type="date" v-model.trim="$v.item.expectedCompletionDate.$model" :state="getValidState($v.item.expectedCompletionDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Completion Letter Submission Date" :invalid-feedback="requiredFeedback($v.item.completionSubmissionDate)">
						<b-form-input type="date" v-model.trim="$v.item.completionSubmissionDate.$model" :state="getValidState($v.item.completionSubmissionDate)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Category" :invalid-feedback="requiredFeedback($v.item.category)">
						<b-typeahead v-model.trim="$v.item.category.$model"
									 :data="setOptions.categories"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Current Phase" :invalid-feedback="requiredFeedback($v.item.phase)">
						<b-typeahead v-model.trim="$v.item.phase.$model"
									 :data="setOptions.phases"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="A&amp;E Assignment" :invalid-feedback="requiredFeedback($v.item.aeAssignment)">
						<b-typeahead v-model.trim="$v.item.aeAssignment.$model"
									 :data="options.aeAssignment"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Project Manager" :invalid-feedback="requiredFeedback($v.item.projectManager)">
						<b-typeahead v-model.trim="$v.item.projectManager.$model"
									 :data="options.projectManager"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Flood Control Program Staff" :invalid-feedback="requiredFeedback($v.item.fcpStaff)">
						<b-typeahead v-model.trim="$v.item.fcpStaff.$model"
									 :data="options.fcpStaff"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>
				</b-col>
			</b-row>

			<hr class="my-4" />

			<b-row>
				<b-col md>
					<b-form-group label="Overall Status" :invalid-feedback="requiredFeedback($v.item.status)">
						<b-typeahead v-model.trim="$v.item.status.$model"
									 :data="setOptions.statuses"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Status Notes" :invalid-feedback="requiredFeedback($v.item.statusNote)" class="mb-4">
						<b-form-textarea v-model.trim="$v.item.statusNote.$model" :state="getValidState($v.item.statusNote)" rows="3"></b-form-textarea>
					</b-form-group>

					<b-form-group label="Land Rights Status" :invalid-feedback="requiredFeedback($v.item.landRightsStatus)">
						<b-typeahead v-model.trim="$v.item.landRightsStatus.$model"
									 :data="setOptions.landRightsStatuses"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Land Rights Status Changed Date" :invalid-feedback="requiredFeedback($v.item.landRightsStatusDate)">
						<b-form-input type="date" v-model.trim="$v.item.landRightsStatusDate.$model" :state="getValidState($v.item.landRightsStatusDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Land Rights Status Notes" :invalid-feedback="requiredFeedback($v.item.landRightsNotes)" class="mb-4">
						<b-form-textarea v-model.trim="$v.item.landRightsNotes.$model" :state="getValidState($v.item.landRightsNotes)" rows="3"></b-form-textarea>
					</b-form-group>

					<b-form-group label="CoE 404 Permit Status" :invalid-feedback="requiredFeedback($v.item.coe404PermitStatus)">
						<b-typeahead v-model.trim="$v.item.coe404PermitStatus.$model"
									 :data="setOptions.coe404PermitStatuses"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="CoE 404 Permit Status Changed Date" :invalid-feedback="requiredFeedback($v.item.coeStatusDate)">
						<b-form-input type="date" v-model.trim="$v.item.coeStatusDate.$model" :state="getValidState($v.item.coeStatusDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="CoE 404 Permit Status Notes" :invalid-feedback="requiredFeedback($v.item.coeNotes)" class="mb-4">
						<b-form-textarea v-model.trim="$v.item.coeNotes.$model" :state="getValidState($v.item.coeNotes)" rows="3"></b-form-textarea>
					</b-form-group>
				</b-col>

				<b-col md>
					<b-form-group label="Sponsor Status" :invalid-feedback="requiredFeedback($v.item.sponsorStatus)">
						<b-typeahead v-model.trim="$v.item.sponsorStatus.$model"
									 :data="setOptions.sponsorStatuses"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Sponsor Status Notes" :invalid-feedback="requiredFeedback($v.item.sponsorStatusNote)" class="mb-4">
						<b-form-textarea v-model.trim="$v.item.sponsorStatusNote.$model" :state="getValidState($v.item.sponsorStatusNote)" rows="3"></b-form-textarea>
					</b-form-group>

					<b-form-group label="TPWD ARRP Status" :invalid-feedback="requiredFeedback($v.item.tpwdArrpStatus)">
						<b-typeahead v-model.trim="$v.item.tpwdArrpStatus.$model"
									 :data="setOptions.tpwdArrpStatuses"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="TPWD ARRP Status Changed Date" :invalid-feedback="requiredFeedback($v.item.tpwdArrpStatusDate)">
						<b-form-input type="date" v-model.trim="$v.item.tpwdArrpStatusDate.$model" :state="getValidState($v.item.tpwdArrpStatusDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="TPWD ARRP Status Notes" :invalid-feedback="requiredFeedback($v.item.tpwdArrpNotes)" class="mb-4">
						<b-form-textarea v-model.trim="$v.item.tpwdArrpNotes.$model" :state="getValidState($v.item.tpwdArrpNotes)" rows="3"></b-form-textarea>
					</b-form-group>

					<b-form-group label="O&amp;M Agreement Status" :invalid-feedback="requiredFeedback($v.item.omAgreementStatus)">
						<b-typeahead v-model.trim="$v.item.omAgreementStatus.$model"
									 :data="setOptions.omAgreementStatuses"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="O&amp;M Agreement Status Changed Date" :invalid-feedback="requiredFeedback($v.item.omAgreementStatusDate)">
						<b-form-input type="date" v-model.trim="$v.item.omAgreementStatusDate.$model" :state="getValidState($v.item.omAgreementStatusDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="O&amp;M Agreement Status Notes" :invalid-feedback="requiredFeedback($v.item.omAgreementNotes)" class="mb-4">
						<b-form-textarea v-model.trim="$v.item.omAgreementNotes.$model" :state="getValidState($v.item.omAgreementNotes)" rows="3"></b-form-textarea>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Other Notes" :invalid-feedback="requiredFeedback($v.item.notes)" class="mb-4">
				<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="5"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, integer } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrProjectForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			application: {
				type: Object,
				required: false
			},
			setOptions: {
				type: Object,
				default: () => {
					return {
						statuses: [],
						categories: [],
						phases: [],
						landRightsStatuses: [],
						coe404PermitStatuses: [],
						omAgreementStatuses: [],
						sponsorStatuses: [],
						tpwdArrpStatuses: []
					}
				}
			}
		},
		data() {
			return {
				apiUrl: 'srprojects',
				editRoute: 'SrProjectEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					appNums: [],
					projectManager: [],
					fcpStaff: [],
					aeAssignment: []
				},
				query: {
					appNum: ''
				}
			}
		},
		computed: {
			queryAppNum() {
				return this.query.appNum;
			},
			itemProjectManager() {
				return this.item.projectManager;
			},
			itemFcpStaff() {
				return this.item.fcpStaff;
			},
			itemAeAssignment() {
				return this.item.aeAssignment;
			}
		},
		validations: {
			item: {
				applicationId: { required, integer },
				projectNumber: {},
				name: {},
				status: {},
				statusNote: {},
				category: {},
				phase: {},
				aeAssignment: {},
				landRightsStatus: {},
				landRightsStatusDate: {},
				landRightsNotes: {},
				coe404PermitStatus: {},
				coeStatusDate: {},
				coeNotes: {},
				tpwdArrpStatus: {},
				tpwdArrpStatusDate: {},
				tpwdArrpNotes: {},
				omAgreementStatus: {},
				omAgreementStatusDate: {},
				omAgreementNotes: {},
				projectManager: {},
				fcpStaff: {},
				sponsorStatus: {},
				sponsorStatusNote: {},
				expectedCompletionDate: {},
				completionSubmissionDate: {},
				notes: {}
			},
			query: {
				appNum: { required }
			}
		},
		watch: {
			queryAppNum: _.debounce(function (query) { this.findAppNums(query) }, 500),
			itemProjectManager: _.debounce(function (query) { this.find('projectManager', query) }, 500),
			itemFcpStaff: _.debounce(function (query) { this.find('fcpStaff', query) }, 500),
			itemAeAssignment: _.debounce(function (query) { this.find('aeAssignment', query) }, 500)
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				if (!this.isNullOrEmpty(this.application)) {
					this.query.appNum = this.application.appNum;
					this.item.applicationId = this.application.id;
				}

				if (this.isUpdate) {
					this.item.landRightsStatusDate = this.dateForForm(this.item.landRightsStatusDate);
					this.item.expectedCompletionDate = this.dateForForm(this.item.expectedCompletionDate);
					this.item.completionSubmissionDate = this.dateForForm(this.item.completionSubmissionDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.applicationId}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async findAppNums(query) {
				try {
					const response = await this.$http.get(`/srapplications/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					this.options.appNums = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>
