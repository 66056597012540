<template>
	<auth-container :page="page">
		<h2 class="mb-4">In-Kind Match Report Information</h2>

		<inkind-match-form :item="item"></inkind-match-form>
	</auth-container>
</template>

<script>
	import InkindMatchForm from './InkindMatchForm.vue';
	import moment from 'moment';
	export default {
		name: 'InkindMatchCreate',
		components: {
			InkindMatchForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {}
			}
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				this.item = {
					id: 0,
					fiscalYear: this.currentFiscalYear(),
					sponsorId: '',
					dateRecorded: moment().format(),
					reportNumber: null,
					performingEntity: null,
					sourceOfFunds: null,
					notes: null
				}
			}
		}
	}
</script>