<template>
	<div id="app">
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<b-container fluid class="p-0">
				<b-navbar toggleable="lg" type="dark" variant="dark" fixed="top" id="dash-nav">
					<div class="navbar-brand d-flex">
						<div class="acronym mr-3"><router-link to="/">FCP</router-link></div>
						<div class="d-none d-md-block">
							<div class="name">Flood Control Program Database</div>
							<div class="description">Texas State Soil &amp; Water Conservation Board</div>
						</div>
					</div>

					<b-navbar-toggle target="nav_collapse" />
					<b-collapse is-nav id="nav_collapse">
						<b-navbar-nav class="ml-auto">
							<b-nav-item to="/" v-b-tooltip.hover title="Dashboard">
								<font-awesome-icon :icon="['fas', 'globe-americas']" fixed-width size="lg" />
							</b-nav-item>

							<b-nav-item-dropdown right no-caret v-if="isAuthorized(roleNames.user)" v-b-tooltip.hover title="Account settings">
								<template slot="button-content">
									<font-awesome-icon :icon="['fas', 'user-circle']" fixed-width size="lg" />
								</template>
								<b-dropdown-text style="width:300px">Welcome, <strong>{{this.user.userName}}</strong>!</b-dropdown-text>
								<b-dropdown-divider></b-dropdown-divider>
								<b-dropdown-item to="/account">My account</b-dropdown-item>
								<b-dropdown-item-button @click="logout">Log out</b-dropdown-item-button>
							</b-nav-item-dropdown>
						</b-navbar-nav>
					</b-collapse>
				</b-navbar>

				<div id="content-frame">
					<router-view></router-view>
				</div>
			</b-container>					
		</div>
	</div>
</template>

<style lang="scss">
	@import 'app.scss';
</style>

<script>
	export default {
		name: 'app',
		data() {
			return {
				page: {
					loading: true,
					errors: []
				}
			}
		},
		async created() {
			await this.checkAuth();
		},
		methods: {
			async checkAuth() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('auth/check', this.getTokenHeader());
					this.log(response.data);
					this.$store.commit('login', {
						token: localStorage.getItem('auth_token'),
						user: response.data
					});
					this.log(localStorage.getItem('auth_token'));
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>
