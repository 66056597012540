<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col md>
					<b-form-group label="Fiscal Year" :invalid-feedback="requiredFeedback($v.item.fiscalYear)">
						<b-form-input type="number" required @change="formatRequestNumber"
									  v-model.trim="$v.item.fiscalYear.$model" :state="getValidState($v.item.fiscalYear)" />
					</b-form-group>

					<b-form-group label="Sponsor #" :invalid-feedback="requiredFeedback($v.item.sponsorId)">
						<b-typeahead v-model.trim="$v.item.sponsorId.$model" @hit="formatRequestNumber" @input.native="formatRequestNumber"
									 :data="options.sponsors"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Proposal #">
						<b-typeahead v-model="query.proposal"
									 :data="options.proposals"
									 :serializer="s => s.name" @hit="item.proposalId = $event.id"
									 :max-matches="100" :minMatchingChars="1">
							<template slot="append">
								<b-input-group-text @click="query.proposal = ''" class="pointer">
									<font-awesome-icon :icon="['fas', 'times']" />
								</b-input-group-text>
							</template>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Reimbursement Request #" :invalid-feedback="requiredFeedback($v.item.reimbursementNumber)">
						<b-form-input type="text" required
									  v-model.trim="$v.item.reimbursementNumber.$model" :state="getValidState($v.item.reimbursementNumber)" />
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Date/Time Received" :invalid-feedback="requiredFeedback($v.item.dateReceived)">
						<b-form-input type="datetime-local" v-model.trim="$v.item.dateReceived.$model" :state="getValidState($v.item.dateReceived)" required></b-form-input>
					</b-form-group>
					<b-form-group label="Date Recorded" :invalid-feedback="requiredFeedback($v.item.dateRecorded)">
						<b-form-input type="date" v-model.trim="$v.item.dateRecorded.$model" :state="getValidState($v.item.dateRecorded)"></b-form-input>
					</b-form-group>
					<b-form-group label="Invoice Date" :invalid-feedback="requiredFeedback($v.item.invoiceDate)">
						<b-form-input type="date" v-model.trim="$v.item.invoiceDate.$model" :state="getValidState($v.item.invoiceDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)">
				<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="5"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, integer } from 'vuelidate/lib/validators';
	import _ from 'underscore';
	//import moment from 'moment';

	export default {
		name: 'ReimbursementForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			proposal: {
				type: Object,
				required: false
			}
		},
		data() {
			return {
				apiUrl: 'omreimbursementrequests',
				editRoute: 'ReimbursementEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					sponsors: [],
					proposals: [],
					itemReceivedTime: null
				},
				query: {
					proposal: ''
				}
			}
		},
		computed: {
			itemSponsorId() {
				return this.item.sponsorId;
			},
			queryProposal() {
				return this.query.proposal;
			}
		},
		validations: {
			item: {
				fiscalYear: { required, integer },
				sponsorId: { required },
				dateReceived: { required },
				dateRecorded: { required },
				reimbursementNumber: { required },
				proposalId: {},
				invoiceDate: { required },
				notes: {}
			}
		},
		watch: {
			itemSponsorId: _.debounce(function (query) { this.findSponsors('id', query) }, 500),
			queryProposal: _.debounce(function (query) { this.findProposals(query) }, 500)
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				this.item.sponsorId = String(this.item.sponsorId);
				if (!this.isNullOrEmpty(this.proposal)) this.query.proposal = this.proposal.proposalNumber;
				//if (this.item.dateReceived) this.options.itemReceivedTime = moment(this.item.dateReceived).format('HH:mm');

				if (!this.isUpdate) this.formatRequestNumber();
				else {
					this.item.dateReceived = this.dateTimeForForm(this.item.dateReceived);
					this.item.dateRecorded = this.dateForForm(this.item.dateRecorded);
					this.item.invoiceDate = this.dateForForm(this.item.invoiceDate);
				}
			},
			async save() {
				this.log(this.item);
				this.log(this.getItemForForm());
				this.page.errors = [];
				this.page.saving = true;
				this.logValidations();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.getItemForForm(), this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.getItemForForm(),
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			getItemForForm() {
				/*let dateReceived = moment(this.item.dateReceived).startOf('date');
				if (!this.isNullOrEmpty(this.options.itemReceivedTime)) {
					let time = moment(this.options.itemReceivedTime, 'HH:mm:ss');
					dateReceived.set({
						hour: time.get('hour'),
						minute: time.get('minute'),
						second: time.get('second')
					});
				}*/

				let item = {
					fiscalYear: this.item.fiscalYear,
					sponsorId: Number(this.item.sponsorId),
					dateReceived: this.item.dateReceived, //dateReceived.format(),
					dateRecorded: this.item.dateRecorded,
					reimbursementNumber: this.item.reimbursementNumber,
					proposalId: this.item.proposalId,
					invoiceDate: this.item.invoiceDate,
					notes: this.item.notes
				};

				if (this.isUpdate) item.id = this.item.id;

				return item;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async findSponsors(field, query) {
				try {
					const response = await this.$http.get(`/omsponsors/find/${field}/${query}`, this.getTokenHeader());
					this.options.sponsors = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			formatRequestNumber() {
				if (!this.isUpdate) {
					let fy = this.isNullOrEmpty(this.item.fiscalYear) ? '20XX' : String(this.item.fiscalYear);
					for (let i = fy.length; i < 4; i++) {
						fy += 'X';
					}

					let s = this.isNullOrEmpty(this.item.sponsorId) ? 'XXX' : this.item.sponsorId;
					for (let i = s.length; i < 3; i++) {
						s += 'X';
					}

					let parts = this.isNullOrEmpty(this.item.reimbursementNumber) ? [] : this.item.reimbursementNumber.split('-');
					let n = '0000';
					if (parts.length > 2) {
						n = parts[2];
					}

					this.item.reimbursementNumber = `R${fy}-${s}-${n}`;
				}
			},
			async findProposals(query) {
				try {
					const response = await this.$http.get(`/omproposals/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					this.options.proposals = !this.isNullOrEmpty(response.data) ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>
