<template>
	<div>
		<auth-container v-if="$route.name === 'OmDashboard'" :page="page">
			<div class="d-flex align-items-center mb-4">
				<h2 class="mb-0 mr-3">O&amp;M</h2>

				<div class="form-inline">
					<b-input-group prepend="Fiscal Year">
						<b-form-select v-model="fiscalYear.selected" :options="fiscalYear.options" @change="loadFiscalYear">
							<template #first>
								<b-form-select-option :value="null" disabled>-- Please select a year --</b-form-select-option>
							</template>
						</b-form-select>
					</b-input-group>
				</div>
			</div>
			

			<b-alert variant="warning" :show="page.noData">
				No budget data available for FY{{fiscalYear.selected}}.
				<router-link to="/funds">Add funds</router-link> to get started.
			</b-alert>

			<b-tabs v-if="!page.noData" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview" active>
					<b-row>
						<b-col lg="6">
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>FY{{fiscalYear.selected}} Total Proposed O&amp;M</b-th>
										<b-td class="text-right">${{data.totalProposedOm | number(2)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>FY{{fiscalYear.selected}} Total Approved, Allocated</b-th>
										<b-td class="text-right">${{data.totalApproved | number(2)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Money Released to Reobligate</b-th>
										<b-td class="text-right">${{data.canBeReleased | number(2)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Total Approved, Allocated Minus Released</b-th>
										<b-td class="text-right">${{data.approvedMinusReleased | number(2)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>FY{{fiscalYear.selected}} Total O&amp;M Balance</b-th>
										<b-td class="text-right">${{data.currentBalance | number(2)}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col lg="6">
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>FY{{fiscalYear.selected}} Total O&amp;M Starting Balance</b-th>
										<b-td class="text-right">${{data.omStartingBalance | number(2)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>FY{{fiscalYear.selected}} Total Admin. Starting Balance</b-th>
										<b-td class="text-right">${{data.adminStartingBalance | number(2)}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
					</b-row>
					<b-row class="my-4">
						<b-col lg="4">
							<div>
								<highcharts :options="balancePieChart"></highcharts>
							</div>
						</b-col>
						<b-col lg="8">
							<div>
								<highcharts :options="balanceBarChart"></highcharts>
							</div>
						</b-col>
					</b-row>

					<map-visualizer option-button-below :map-data="data.maps" :num-cols="6" :map-height="500"></map-visualizer>
				</b-tab>
				<b-tab title="Summary Table">
					<b-table striped responsive table-class="border-bottom table-grid" sticky-header="60vh"
							 small show-empty
							 :items="data.summary"
							 :fields="table.fields">
						<template #table-busy>
							<div class="text-center my-4">
								<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
							</div>
						</template>
						<template #cell(sponsor)="data">
							<router-link :to="`/om/sponsors/${data.value}`">{{data.value}}</router-link>
						</template>
						<template #empty>
							<h4>No records found.</h4>
						</template>

						<template #custom-foot>
							<b-tr class="bg-light border-bottom font-weight-bold">
								<b-td>Total</b-td>
								<b-td class="text-right">${{ sum(data.summary, 'proposedOm') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(data.summary, 'proposedAdmin') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(data.summary, 'proposedTotal') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(data.summary, 'approvedOm') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(data.summary, 'approvedAdmin') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(data.summary, 'approvedTotal') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(data.summary, 'paidOm') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(data.summary, 'inkindReported') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(data.summary, 'inkindUsed') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(data.summary, 'inkindBalance') | number(2)}}</b-td>
							</b-tr>
						</template>
					</b-table>

					<fixed-action-bar>
						<json-to-csv :json-data="data.summary" :field-labels="table.fieldLabels" :filename="`fy${fiscalYear.selected}-balance-summary-by-sponsor`" />
					</fixed-action-bar>
				</b-tab>
			</b-tabs>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import MapVisualizer from '@/components/MapVisualizer';

	function labelMoneyFormatter() {
		let value = this.y;
		let base = 10 ** 2;
		let x = (Math.round(Number(value) * base) / base).toFixed(2);
		var parts = x.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return '<b>$' + parts.join(".") + '</b>';
	}

	export default {
		name: 'OmDashboard',
		components: { MapVisualizer },
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					noData: false
				},
				table: {
					fields: [
						{ key: 'sponsor', label: 'Sponsor #', sortable: true },
						{ key: 'proposedOm', label: 'Proposed O&M', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'proposedAdmin', label: 'Proposed Admin.', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'proposedTotal', label: 'Total Proposed', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'approvedOm', label: 'Approved O&M', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'approvedAdmin', label: 'Approved Admin.', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'approvedTotal', label: 'Total Approved', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'paidOm', label: 'Total Paid O&M', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'inkindReported', label: 'In-Kind Match Reported', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'inkindUsed', label: 'In-Kind Match Used', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'inkindBalance', label: 'In-Kind Balance', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } }
					],
					filter: null,
					fieldLabels: {
						sponsor: 'Sponsor',
						proposedOm: 'Proposed-O&M',
						proposedAdmin: 'Proposed-Admin',
						proposedTotal: 'Total-Proposed',
						approvedOm: 'Approved-O&M',
						approvedAdmin: 'Approved-Admin',
						approvedTotal: 'Total-Approved',
						paidOm: 'Total-Paid-O&M',
						inkindReported: 'In-Kind-Match-Reported',
						inkindUsed: 'In-Kind-Match-Used',
						inkindBalance: 'In-Kind-Balance'
					}
				},
				fiscalYear: {
					selected: null,
					options: []
				},
				data: {
					summary: [],
					maps: []
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`funds/list`, this.getTokenHeader());
					this.log(response.data);

					this.fiscalYear.options = response.data;
					if (this.fiscalYear.options.length > 0) this.fiscalYear.selected = this.fiscalYear.options[0];
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				if (this.$route.name === 'OmDashboard') {
					await this.loadFiscalYear();
				}

				this.page.loading = false;
			},
			async loadFiscalYear() {
				try {
					const response = await this.$http.get(`omdashboard/balances/${this.fiscalYear.selected}`, this.getTokenHeader());
					this.log(response.data);

					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else if (this.isApiNotFound(error)) this.page.noData = true;
					else this.page.errors = this.logError(error);
				}
			}
		},
		computed: {
			balancePieChart() {
				return {
					chart: {
						type: 'pie'
					},
					title: {
						text: `FY${this.fiscalYear.selected} Total O&M Balance`
					},
					tooltip: {
						pointFormat: '<b>{point.percentage:.2f}%</b>'
					},
					plotOptions: {
						pie: {
							innerSize: '50%'
						}
					},
					series: [{
						data: [
							[`Allocated Funds: ${this.money(this.data.approvedMinusReleased)}`, this.roundAsNumber(this.data.approvedMinusReleased / this.data.omStartingBalance * 100, 1)],
							[`Available Balance: ${this.money(this.data.currentBalance)}`, this.roundAsNumber(this.data.currentBalance / this.data.omStartingBalance * 100, 1)]
						]
					}]
				}
			},
			balanceBarChart() {
				return {
					chart: {
						type: 'column'
					},
					title: {
						text: `FY${this.fiscalYear.selected} O&M Funds`
					},
					tooltip: {
						formatter: labelMoneyFormatter
					},
					xAxis: {
						type: 'category'
					},
					yAxis: {
						plotLines: [{
							color: '#840a0a',
							width: 2,
							value: this.roundAsNumber(this.data.omStartingBalance, 2),
							label: {
								text: `Total O&M Starting Balance ${this.money(this.data.omStartingBalance)}`,
								align: 'center'
							}
						}],
					},
					legend: {
						enabled: false
					},
					series: [{
						name: 'Funds',
						type: 'column',
						data: [
							{ name: `Total Proposed O&M`, y: this.roundAsNumber(this.data.totalProposedOm, 2), color: '#124e8e' },
							{ name: `Total Approved, Allocated`, y: this.roundAsNumber(this.data.totalApproved, 2), color: '#128c5d' },
							{ name: `Money Released to Reobligate`, y: this.roundAsNumber(this.data.canBeReleased, 2), color: '#128c84' },
							{ name: `Total Approved, Allocated Minus Released	`, y: this.roundAsNumber(this.data.approvedMinusReleased, 2), color: '#653d82' },
							{ name: `Total O&M Balance`, y: this.roundAsNumber(this.data.currentBalance, 2), color: '#99214d' }
						],
						dataLabels: {
							enabled: true,
							align: 'center',
							color: '#FFFFFF',
							inside: true,
							formatter: labelMoneyFormatter
						}
					}]
				}
			}
		}
	}
</script>