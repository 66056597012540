<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control Structural Repair Program<br />Agreement #{{$parent.item.agreementNumber}}</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-row>
				<b-col md>
					<b-table-simple small class="table-definitions">
						<b-tbody>
							<b-tr>
								<b-th>State PO#</b-th>
								<b-td>{{valueOrNa($parent.item.statePo)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Agreement Appropriation Fund Source ID</b-th>
								<b-td>{{valueOrNa($parent.item.fundSourceId)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Fiscal Year Funds Were Obligated</b-th>
								<b-td>{{valueOrNa($parent.item.fundFiscalYear)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Offeror</b-th>
								<b-td>{{valueOrNa($parent.item.offeror)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Offeree</b-th>
								<b-td>{{valueOrNa($parent.item.offeree)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Contracting Entity</b-th>
								<b-td>{{valueOrNa($parent.item.contractor)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Agreement Type</b-th>
								<b-td>{{valueOrNa($parent.item.agreementType)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Status</b-th>
								<b-td>{{valueOrNa($parent.item.status)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Amendment #</b-th>
								<b-td>{{valueOrNa($parent.item.amendmentNumber)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Amendment Notes</b-th>
								<b-td>{{valueOrNa($parent.item.amendmentNotes, 'None')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Amendment Date</b-th>
								<b-td>{{$parent.item.amendmentDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Execution Date</b-th>
								<b-td>{{$parent.item.executionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Expiration Date</b-th>
								<b-td>{{$parent.item.expirationDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Last Revised Date</b-th>
								<b-td>{{$parent.item.lastRevisedDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Notes</b-th>
								<b-td>{{valueOrNa($parent.item.notes, 'None')}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
				<b-col md>
					<b-table-simple small class="table-definitions" bordered>
						<b-tbody>
							<b-tr class="bg-light">
								<b-th>Project</b-th>
								<b-th>Dam</b-th>
							</b-tr>
							<b-tr v-for="(p, i) in $parent.projects" :key="i">
								<b-td>#{{p.projectNumber}} - {{p.projectName}}</b-td>
								<b-td>
									<span v-if="isNullOrEmpty(p.damId)">N/A</span>
									<span v-else>{{p.damName}}</span>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<b-table-simple small class="table-definitions" bordered>
						<b-tbody>
							<b-tr>
								<b-th class="bg-light">Maximum Contract Price</b-th>
								<b-td class="text-right" colspan="2">{{money($parent.item.maxContractPrice)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th class="bg-light" style="border-bottom-width:3px">Any Amount Not Cost-Shared</b-th>
								<b-td class="text-right" colspan="2" style="border-bottom-width:3px">{{money($parent.item.additionalAmount)}}</b-td>
							</b-tr>
							<b-tr class="bg-light">
								<b-th>Cost Share</b-th>
								<b-th class="text-right">Reimbursable %</b-th>
								<b-th class="text-right">Max Reimbursable Amount</b-th>
							</b-tr>
							<b-tr>
								<b-th class="bg-light">Total Construction</b-th>
								<b-td class="text-right">{{$parent.item.constructionPercent | number(2)}}%</b-td>
								<b-td class="text-right">{{money($parent.item.constructionMaxAmount)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th class="bg-light">Professional Services</b-th>
								<b-td class="text-right">{{$parent.item.profServicesPercent | number(2)}}%</b-td>
								<b-td class="text-right">{{money($parent.item.profServicesMaxAmount)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th class="bg-light">Administrative Expenses</b-th>
								<b-td class="text-right">{{$parent.item.adminPercent | number(2)}}%</b-td>
								<b-td class="text-right">{{money($parent.item.adminMaxAmount)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th class="bg-light">Land Rights</b-th>
								<b-td class="text-right">{{$parent.item.landRightsPercent | number(2)}}%</b-td>
								<b-td class="text-right">{{money($parent.item.landRightsMaxAmount)}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>

			<h3 class="mb-3">Reimbursements</h3>
			<b-table-simple small class="table-definitions" bordered>
				<b-tbody v-for="(r,i) in $parent.reimbursements.items" :key="i">
					<b-tr v-for="(f,j) in reimbursements.fields" :key="j">
						<b-th class="bg-light" :style="j == 0 ? 'border-top-width: 3px' : ''">{{f.label}}</b-th>
						<b-td :style="j == 0 ? 'border-top-width: 3px' : ''">{{f.formatter(r[f.key])}}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'SrAgreementPrint',
		components: { PrintMenu },
		data() {
			return {
				reimbursements: {
					fields: [
						{ key: 'dam', label: 'Dam', sortable: true, formatter: (value) => { return value.name } },
						{ key: 'invoiceNumber', label: 'Invoice#', sortable: true, formatter: (value) => { return value } },
						{ key: 'invoicedDate', label: 'Invoiced Date', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'stateConstructionCost', label: 'State Construction Cost', sortable: true, formatter: (value) => { return this.money(value); } },
						{ key: 'wasStateRetainageRequested', label: 'State Retainage Requested?', sortable: true, formatter: (value) => { return this.boolToText(value); } },
						{ key: 'stateLandRights', label: 'State Land Rights Cost', sortable: true, formatter: (value) => { return this.money(value); } },
						{ key: 'wasAdminRequestedInAdvance', label: 'Admin Requested in Advance?', sortable: true, formatter: (value) => { return this.boolToText(value); } },
						{ key: 'stateAdminCost', label: 'State Admin Cost', sortable: true, formatter: (value) => { return this.money(value); } },
						{ key: 'federalConstructionCost', label: 'Federal Construction Cost', sortable: true, formatter: (value) => { return this.money(value); } },
						{ key: 'wasFederalRetainageRequested', label: 'Federal Retainage Requested?', sortable: true, formatter: (value) => { return this.boolToText(value); } },
						{ key: 'federalLandRights', label: 'Federal Land Rights Cost', sortable: true, formatter: (value) => { return this.money(value); } },
						{ key: 'sponsorConstructionCost', label: 'Sponsor Construction Cost', sortable: true, formatter: (value) => { return this.money(value); } }
					]
				}
			}
		}
	}
</script>