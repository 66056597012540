<template>
	<div>
		<auth-container :page="page" require-admin>
			<h2 class="mb-4">Funds</h2>

			<b-table striped responsive table-class="table-fixed-height border-bottom table-grid" sticky-header="60vh"
					 small show-empty
					 :items="items" :busy="table.loading"
					 :fields="table.fields"
					 :no-local-sorting="true"
					 :sort-by.sync="table.sort"
					 :sort-desc.sync="table.reverse"
					 @sort-changed="sortChg">
				<template #table-busy>
					<div class="text-center my-4">
						<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
					</div>
				</template>
				<template #cell(edit)="data">
					<font-awesome-icon :icon="['fas', 'edit']" class="pointer text-primary" @click="edit(data.item.fiscalYear)" />
				</template>
				<template #cell(delete)="data">
					<font-awesome-icon v-if="isAuthorized(roleNames.admin)" :icon="['fas', 'times']" class="pointer text-danger" @click="deleteFund(data.item.fiscalYear)" />
				</template>
				<template #empty>
					<h4>No records found.</h4>
				</template>
				<template #emptyfiltered>
					<h4>No records found matching "{{table.filter}}".</h4>
				</template>
			</b-table>

			<fixed-action-bar>
				<b-button type="button" variant="success" @click="createFund" class="mr-2">Create</b-button>
				<back-button />
				<b-pagination class="ml-auto mb-0" v-if="table.total > table.itemsPerPage" size="md" :total-rows="table.total" v-model="table.currentPage" :per-page="table.itemsPerPage" @change="pageChg" />
			</fixed-action-bar>

			<b-modal v-model="page.edit.show" title="Funds Information" scrollable size="lg">
				<error-list :errors="page.edit.errors"></error-list>

				<b-form-group label="Fiscal Year" :invalid-feedback="requiredFeedback($v.page.edit.item.fiscalYear)">
					<b-form-input type="number" required
								  v-model.trim="$v.page.edit.item.fiscalYear.$model" :state="getValidState($v.page.edit.item.fiscalYear)" />
				</b-form-group>

				<b-form-group label="O&M Starting Balance" :invalid-feedback="requiredFeedback($v.page.edit.item.omStartingBalance)">
					<b-input-group prepend="$">
						<b-form-input type="number" required step="any"
									  v-model.trim="$v.page.edit.item.omStartingBalance.$model" :state="getValidState($v.page.edit.item.omStartingBalance)" />
					</b-input-group>
				</b-form-group>

				<b-form-group label="Admin. Starting Balance" :invalid-feedback="requiredFeedback($v.page.edit.item.adminStartingBalance)">
					<b-input-group prepend="$">
						<b-form-input type="number" required step="any"
									  v-model.trim="$v.page.edit.item.adminStartingBalance.$model" :state="getValidState($v.page.edit.item.adminStartingBalance)" />
					</b-input-group>
				</b-form-group>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.edit.saving" @click.native="save" variant="success" />
					<b-button type="button" variant="secondary" @click="page.edit.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this in-kind match report?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</auth-container>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'FundList',
		data() {
			return {
				apiUrl: 'funds',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					delete: {
						show: false,
						errors: [],
						saving: false,
						id: null
					},
					edit: {
						show: false,
						errors: [],
						saving: false,
						item: null,
						isUpdate: false,
						saveDescription: null
					}
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'fiscalYear', label: 'Fiscal Year', sortable: true },
						{ key: 'omStartingBalance', label: 'O&M Starting Balance', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'adminStartingBalance', label: 'Admin. Starting Balance', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'delete', label: '' }
					],
					sort: 'fiscalYear',
					reverse: true,
					itemsPerPage: 20,
					filter: null,
					currentPage: 1,
					total: 0,
					loading: false
				},
				items: []
			}
		},
		computed: {
			showingFirst() {
				return (this.table.currentPage - 1) * this.table.itemsPerPage + 1
			},
			showingLast() {
				var max = (this.table.currentPage - 1) * this.table.itemsPerPage + this.table.itemsPerPage
				return max > this.table.total ? this.table.total : max;
			}
		},
		validations: {
			page: {
				edit: {
					item: {
						fiscalYear: { required, integer },
						omStartingBalance: { required, decimal },
						adminStartingBalance: { required, decimal }
					}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			getDb() {
				let sort = this.table.reverse ? this.table.sort + '_desc' : this.table.sort;
				let filter = this.table.filter !== null ? `&filter=${encodeURIComponent(this.table.filter)}` : '';

				return this.$http.get(`${this.apiUrl}?sort=${sort}&page=${this.table.currentPage}&perPage=${this.table.itemsPerPage}${filter}`, this.getTokenHeader());
			},
			async filterChg() {
				this.table.currentPage = 1;
				_.debounce(await this.get(), 500);
			},
			async pageChg(page) {
				this.table.currentPage = page;
				await this.get();
			},
			async sortChg(ctx) {
				this.table.sort = ctx.sortBy;
				this.table.reverse = ctx.sortDesc;
				this.table.specialSort = undefined;

				await this.get();
			},
			async get() {
				this.page.errors = [];
				this.table.loading = true;

				try {
					const response = await this.getDb();
					this.log(response.data);

					if (response.data.items.length == 0 && this.table.currentPage > 1) {
						this.table.currentPage--;
						await this.get();
					}

					this.items = response.data.items;
					this.table.total = response.data.total;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.table.loading = false;
				this.page.loading = false;
			},
			deleteFund(id) {
				this.log("delete");
				this.page.delete.id = id;
				this.page.delete.show = true;
			},
			async confirmDelete() {
				if (!this.isNullOrEmpty(this.page.delete.id)) {
					this.page.delete.errors = [];
					this.page.delete.saving = true;

					try {
						await this.$http.delete(`${this.apiUrl}/${this.page.delete.id}`, this.getTokenHeader());
						this.page.delete.show = false;
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.delete.errors = this.logError(error);
					}

					this.page.delete.saving = false;
				}
			},
			resetEditItem() {
				this.page.edit.item = {
					fiscalYear: 0,
					omStartingBalance: 0,
					adminStartingBalance: 0
				};
				this.page.edit.isUpdate = false;
				this.page.edit.saveDescription = null;
			},
			async getEditItem(id) {
				this.page.edit.loading = true;
				this.page.edit.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${id}`, this.getTokenHeader());
					this.log(response.data);
					this.page.edit.item = response.data;
					this.page.edit.isUpdate = true;
				} catch (error) {
					this.page.edit.errors = this.logError(error);
				}

				this.page.edit.loading = false;
			},
			async edit(id) {
				await this.getEditItem(id);
				this.page.edit.isUpdate = true;
				this.page.edit.show = true;
			},
			createFund() {
				this.resetEditItem();
				this.page.edit.show = true;
			},
			cancelEdit() {
				this.page.edit.show = false;
				this.resetEditItem();
			},
			async save() {
				this.page.edit.errors = [];
				this.page.edit.saving = true;

				if (this.$v.$invalid) {
					this.page.edit.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						let apiCall = null;
						if (this.page.edit.isUpdate) {
							let data = {
								item1: this.page.edit.item,
								item2: this.isNullOrEmpty(this.page.edit.saveDescription) ? "Changes saved." : this.page.edit.saveDescription
							};
							apiCall = this.$http.put(`${this.apiUrl}/${this.page.edit.item.fiscalYear}`, data, this.getTokenHeader());
						} else {
							apiCall = this.$http.post(this.apiUrl, this.page.edit.item, this.getTokenHeader());
						}

						const response = await apiCall;
						this.log(response.data);

						this.cancelEdit();
						this.$bvToast.show('changes-saved');
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.edit.errors = this.logError(error);
					}
				}

				this.page.edit.saving = false;
			}
		}
	}
</script>