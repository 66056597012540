<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Agreement</h2>

		<sr-agreement-form :item="item" :projects="projects" :set-options="setOptions"></sr-agreement-form>
	</auth-container>
</template>

<script>
	import SrAgreementForm from './SrAgreementForm.vue';

	export default {
		name: 'SrAgreementCreate',
		components: {
			SrAgreementForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					statePo: null,
					fundSourceId: null,
					fundFiscalYear: null,
					agreementType: null,
					offeror: null,
					offeree: null,
					agreementNumber: null,
					contractor: null,
					amendmentNumber: null,
					amendmentDate: null,
					amendmentNotes: null,
					executionDate: null,
					expirationDate: null,
					status: null,
					maxContractPrice: 0,
					constructionPercent: 0,
					constructionMaxAmount: 0,
					profServicesPercent: 0,
					profServicesMaxAmount: 0,
					adminPercent: 0,
					adminMaxAmount: 0,
					landRightsPercent: 0,
					landRightsMaxAmount: 0,
					additionalAmount: 0,
					lastRevisedDate: null,
					notes: null
				},
				projects: [],
				setOptions: {
					fundSourceIds: [],
					agreementTypes: [],
					offerors: [],
					offerees: [],
					statuses: []
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`sragreements/options`, this.getTokenHeader());
					this.setOptions = response.data;
					this.item.fundFiscalYear = this.currentFiscalYear();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>