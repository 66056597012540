<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control Structural Repair Program<br />Design Phase for Project #{{$parent.project.projectNumber}}</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-row>
				<b-col md>
					<b-table-simple small class="table-definitions">
						<b-tbody>
							<b-tr>
								<b-th>Dam</b-th>
								<b-td>
									<span v-if="isNullOrEmpty($parent.dam.id)">N/A</span>
									<span v-else>{{$parent.dam.name}}</span>
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Project</b-th>
								<b-td>
									#{{$parent.project.projectNumber}} - {{$parent.project.name}}
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Designer</b-th>
								<b-td>{{valueOrNa($parent.item.designer)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Final Construction Documents (CD) Date Received</b-th>
								<b-td>{{$parent.item.finalContractDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>

			<b-table-simple small class="table-definitions" bordered>
				<b-tbody>
					<b-tr class="bg-light">
						<b-th></b-th>
						<b-th>Agent</b-th>
						<b-th>Status</b-th>
						<b-th>Date</b-th>
						<b-th>Notes</b-th>
					</b-tr>
					<b-tr>
						<b-th class="bg-light">30% Milestone</b-th>
						<b-td>{{valueOrNa($parent.item.m30Agent)}}</b-td>
						<b-td>{{valueOrNa($parent.item.m30Status)}}</b-td>
						<b-td>{{$parent.item.m30Date | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
						<b-td>{{valueOrNa($parent.item.m30Notes)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th class="bg-light">60% Milestone</b-th>
						<b-td>{{valueOrNa($parent.item.m60Agent)}}</b-td>
						<b-td>{{valueOrNa($parent.item.m60Status)}}</b-td>
						<b-td>{{$parent.item.m60Date | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
						<b-td>{{valueOrNa($parent.item.m60Notes)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th class="bg-light">90% Milestone</b-th>
						<b-td>{{valueOrNa($parent.item.m90Agent)}}</b-td>
						<b-td>{{valueOrNa($parent.item.m90Status)}}</b-td>
						<b-td>{{$parent.item.m90Date | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
						<b-td>{{valueOrNa($parent.item.m90Notes)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th class="bg-light">Final Design</b-th>
						<b-td>{{valueOrNa($parent.item.finalAgent)}}</b-td>
						<b-td>{{valueOrNa($parent.item.finalStatus)}}</b-td>
						<b-td>{{$parent.item.finalDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
						<b-td>{{valueOrNa($parent.item.finalNotes)}}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'SrDesignPrint',
		components: { PrintMenu }
	}
</script>