<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Contact</h2>

		<sr-contact-form :item="item"></sr-contact-form>
	</auth-container>
</template>

<script>
	import SrContactForm from './SrContactForm.vue';
	export default {
		name: 'SrContactCreate',
		components: {
			SrContactForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					firstName: null,
					lastName: null,
					nickname: null,
					category: null,
					sponNotice: false,
					organization: null,
					jobTitle: null,
					officePhone: null,
					officePhoneExt: null,
					mobilePhone: null,
					email: null,
					alternateEmails: null,
					addressNumber: null,
					addressStreet: null,
					addressSub: null,
					addressSuite: null,
					city: null,
					state: null,
					zip: null,
					notes: null
				}
			}
		}
	}
</script>