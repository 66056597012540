<template>
	<b-button type="button" :variant="variant" :size="size" :disabled="saving || disabled" @click="getCsv">
		<font-awesome-icon v-if="saving" :icon="['fas', 'spinner']" spin />
		{{ text }}
	</b-button>
</template>

<script>
	import Papa from "papaparse";
	import moment from 'moment';

	export default {
		name: 'JsonToCsv',
		props: {
			jsonData: {
				type: Array,
				default: () => []
			},
			apiUrl: {
				type: String,
				default: ''
			},
			fields: {
				type: Array,
				default: () => []
			},
			ignoreFields: {
				type: Array,
				default: () => []
			},
			fieldLabels: {
				type: Object,
				default: null
			},
			disabled: {
				type: Boolean,
				default: false
			},
			filename: {
				type: String,
				default: 'data'
			},
			text: {
				type: String,
				default: 'Download CSV'
			},
			variant: {
				type: String,
				default: 'primary'
			},
			size: {
				type: String,
				default: 'md'
			},
			useApiPost: {
				type: Boolean,
				default: false
			},
			apiFormData: {
				type: Object,
				default: null
			}
		},
		data() {
			return {
				saving: false
			}
		},
		methods: {
			async getCsv() {
				let data = await this.getData();
				let blob = new Blob([Papa.unparse(data)], { type: 'text/csv;charset=utf-8;' });

				let link = document.createElement("a");
				let url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", `${this.filename}-${moment().format('YYYY-MM-DD')}.csv`);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				this.$emit('done');
			},
			async getData() {
				this.saving = true;

				let checkFields = this.fields.length > 0;
				let data = this.jsonData;
				if (data.length < 1) {
					try {
						let action = this.useApiPost ? this.$http.post(this.apiUrl, this.apiFormData, this.getTokenHeader()) : this.$http.get(this.apiUrl, this.getTokenHeader());
						const response = await action;
						this.log(response.data);
						data = response.data;
					} catch (error) {
						this.logError(error);
					}
				}

				let hasFieldLabels = !this.isNullOrEmpty(this.fieldLabels);

				let newData = [];
				for (let r of data) {
					let newRow = {};
					for (let k of Object.keys(r)) {
						if (((checkFields && this.fields.includes(k)) || !checkFields) && !this.ignoreFields.includes(k)) {
							let fieldLabel = k;
							if (hasFieldLabels && (k in this.fieldLabels)) {
								fieldLabel = this.fieldLabels[k];
							}

							newRow[fieldLabel] = r[k];
						}
					}
					newData.push(newRow);
				}

				this.saving = false;
				return newData;
			}
		}
	}
</script>
