<template>
	<div>
		<auth-container v-if="$route.name === 'SrDashboard'" :page="page">
			<div class="d-flex align-items-center mb-4">
				<h2 class="mb-0 mr-3">Structural Repair</h2>

				<div class="form-inline">
					<b-input-group prepend="Fund Cycle" class="mr-1">
						<b-form-select v-model="selection.fundCycle" :options="options.fundCycles" @change="loadData">
							<template #first>
								<b-form-select-option :value="null">All</b-form-select-option>
							</template>
						</b-form-select>
					</b-input-group>

					<b-input-group prepend="Status">
						<b-form-select v-model="selection.status" :options="options.statuses" @change="loadData">
							<template #first>
								<b-form-select-option :value="null">Any</b-form-select-option>
							</template>
						</b-form-select>
					</b-input-group>
				</div>
			</div>

			<page-loading :loading="page.subLoading"></page-loading>
			<div v-if="!page.subLoading">
				<b-alert variant="warning" :show="data.projectCounts.total < 1">
					No {{lowercase(selection.status)}} projects available<span v-if="!isNullOrEmpty(selection.fundCycle)"> for {{selection.fundCycle}}</span>.
				</b-alert>

				<b-tabs v-if="data.projectCounts.total > 0" content-class="mt-4" class="page-nav-tabs">
					<b-tab title="Overview" active>
						<h4 class="text-center">
							<strong>{{data.projectCounts.total}}</strong> Total {{selection.status}} {{selection.fundCycle}} Projects
						</h4>

						<b-card no-body class="mb-3">
							<b-tabs pills card>
								<b-tab title="Categories">
									<div>
										<highcharts :options="categoriesPieChart"></highcharts>
									</div>
								</b-tab>
								<b-tab title="Phases">
									<div>
										<highcharts :options="phasesPieChart"></highcharts>
									</div>
								</b-tab>
								<b-tab title="Funds">
									<b-row class="my-4">
										<b-col lg="6">
											<div>
												<highcharts :options="fundsObligatedPieChart"></highcharts>
											</div>
										</b-col>
										<b-col lg="6">
											<div>
												<highcharts :options="fundsSpentPieChart"></highcharts>
											</div>
										</b-col>
									</b-row>
								</b-tab>
							</b-tabs>
						</b-card>

						
					</b-tab>
					<b-tab title="Maps">
						<map-visualizer option-button-below :map-data="data.maps" :num-cols="6" tabs :map-height="600" value-format="none" show-labels></map-visualizer>
					</b-tab>
					<b-tab title="Progress Table">
						<p>
							Note: the table below is taking the project progress value from work order -> invoice -> item with highest project status.
						</p>
						<b-table striped responsive table-class="border-bottom table-grid" sticky-header="60vh"
								 small show-empty
								 :items="data.projectStatus"
								 :fields="table.fields">
							<template #table-busy>
								<div class="text-center my-4">
									<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
								</div>
							</template>
							<template #cell(project)="data">
								<router-link :to="`/sr/projects/${data.item.projectId}`">{{data.item.project}}</router-link>
							</template>
							<template #cell(dam)="data">
								<router-link :to="`/dams/${data.item.damId}`">{{data.item.dam}}</router-link>
							</template>
							<template #cell(status)="data">
								<div class="d-flex">
									<div class="text-right pr-2" style="width:50px">{{data.value}}%</div>
									<div class="flex-fill"><b-progress :value="data.value" :max="100"></b-progress></div>
								</div>
								
							</template>
							<template #empty>
								<h4>No records found.</h4>
							</template>
						</b-table>
					</b-tab>
				</b-tabs>
			</div>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import MapVisualizer from '@/components/MapVisualizer';

	export default {
		name: 'SrDashboard',
		components: { MapVisualizer },
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					subLoading: false
				},
				options: {
					fundCycles: [],
					statuses: []
				},
				selection: {
					fundCycle: null,
					status: null
				},
				data: {
					projectCounts: {
						total: 0,
						categories: [],
						phases: []
					},
					projectStatus: [],
					maps: [],
					funds: {
						obligated: {
							total: 0,
							federal: 0,
							state: 0,
							esf: 0,
							gr1: 0,
							gr2: 0
						},
						spent: {
							total: 0,
							sponsor: 0,
							federal: 0,
							state: 0,
							esf: 0,
							gr1: 0,
							gr2: 0
						}
					}
				},
				table: {
					fields: [
						{ key: 'project', sortable: true, class: 'nowrap' },
						{ key: 'dam', sortable: true, class: 'nowrap' },
						{ key: 'status', label: 'Status %', sortable: true },
					]
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				if (this.$route.name === 'SrDashboard') {
					try {
						const response = await this.$http.get(`srdashboard/options`, this.getTokenHeader());
						this.log(response.data);
						this.options = response.data;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					await this.loadData();
				}

				this.page.loading = false;
			},
			async loadData() {
				this.page.subLoading = true;

				try {
					let url = `srdashboard/balances`;
					let parts = [];
					if (!this.isNullOrEmpty(this.selection.status)) parts.push(`status=${this.selection.status}`);
					if (!this.isNullOrEmpty(this.selection.fundCycle)) parts.push(`fundCycle=${this.selection.fundCycle}`);
					if (parts.length > 0) {
						url += '?' + parts.join('&');
					}

					const response = await this.$http.get(url, this.getTokenHeader());
					this.log(response.data);

					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else if (this.isApiNotFound(error)) this.page.noData = true;
					else this.page.errors = this.logError(error);
				}

				this.page.subLoading = false;
			}
		},
		computed: {
			categoriesPieChart() {
				let data = [];
				for (let d of this.data.projectCounts.categories) {
					data.push([`${d.name}: ${d.total}`, this.roundAsNumber(d.total / this.data.projectCounts.total * 100, 1)]);
				}

				return {
					chart: {
						type: 'pie'
					},
					title: {
						text: `Categories`
					},
					tooltip: {
						pointFormat: '{point.percentage:.1f}%'
					},
					plotOptions: {
						pie: {
							innerSize: '50%'
						}
					},
					series: [{
						name: 'Categories',
						data: data
					}]
				}
			},
			phasesPieChart() {
				let data = [];
				for (let d of this.data.projectCounts.phases) {
					data.push([`${d.name}: ${d.total}`, this.roundAsNumber(d.total / this.data.projectCounts.total * 100, 1)]);
				}

				return {
					chart: {
						type: 'pie'
					},
					title: {
						text: `Phases`
					},
					tooltip: {
						pointFormat: '{point.percentage:.1f}%'
					},
					plotOptions: {
						pie: {
							innerSize: '50%'
						}
					},
					series: [{
						name: 'Phases',
						data: data
					}]
				}
			},
			fundsObligatedPieChart() {
				let data = this.data.funds.obligated;

				return {
					chart: {
						type: 'pie'
					},
					title: {
						text: `Total Obligated ${this.money(data.total)}`
					},
					tooltip: {
						pointFormat: '{point.percentage:.1f}%'
					},
					plotOptions: {
						pie: {
							innerSize: '20%'
						}
					},
					series: [{
						name: 'Total Obligated',
						size: '60%',
						data: [
							{ name: `Federal`, y: this.roundAsNumber(data.federal / data.total * 100, 1), color: '#c1002d' },
							{ name: `State: ${this.money(data.state)}`, y: this.roundAsNumber(data.state / data.total * 100, 1), color: '#0081c1' }
						],
						dataLabels: {
							color: '#ffffff',
							distance: -30
						}
					},
					{
						name: 'Total Obligated Breakdown',
						size: '80%',
						innerSize: '60%',
						data: [
							{ name: `Federal: ${this.money(data.federal)}`, y: this.roundAsNumber(data.federal / data.total * 100, 1), color: '#c1002d' },
							{ name: `ESF: ${this.money(data.esf)}`, y: this.roundAsNumber(data.esf / data.total * 100, 1), color: '#3bafe5' },
							{ name: `GR1: ${this.money(data.gr1)}`, y: this.roundAsNumber(data.gr1 / data.total * 100, 1), color: '#226889' },
							{ name: `GR2: ${this.money(data.gr2)}`, y: this.roundAsNumber(data.gr2 / data.total * 100, 1), color: '#67adce' }
						]
					}]
				}
			},
			fundsSpentPieChart() {
				let data = this.data.funds.spent;

				return {
					chart: {
						type: 'pie'
					},
					title: {
						text: `Total Spent ${this.money(data.total)}`
					},
					tooltip: {
						pointFormat: '{point.percentage:.1f}%'
					},
					plotOptions: {
						pie: {
							innerSize: '20%'
						}
					},
					series: [{
						name: 'Total Spent',
						size: '60%',
						data: [
							{ name: `Federal`, y: this.roundAsNumber(data.federal / data.total * 100, 1), color: '#c1002d' },
							{ name: `State: ${this.money(data.state)}`, y: this.roundAsNumber(data.state / data.total * 100, 1), color: '#0081c1' },
							{ name: `Sponsor`, y: this.roundAsNumber(data.sponsor / data.total * 100, 1), color: '#0eb229' },
						],
						dataLabels: {
							color: '#ffffff',
							distance: -30
						}
					},
					{
						name: 'Total Spent Breakdown',
						size: '80%',
						innerSize: '60%',
						data: [
							{ name: `Federal: ${this.money(data.federal)}`, y: this.roundAsNumber(data.federal / data.total * 100, 1), color: '#c1002d' },
							{ name: `ESF: ${this.money(data.esf)}`, y: this.roundAsNumber(data.esf / data.total * 100, 1), color: '#3bafe5' },
							{ name: `GR1: ${this.money(data.gr1)}`, y: this.roundAsNumber(data.gr1 / data.total * 100, 1), color: '#226889' },
							{ name: `GR2: ${this.money(data.gr2)}`, y: this.roundAsNumber(data.gr2 / data.total * 100, 1), color: '#67adce' },
							{ name: `Sponsor: ${this.money(data.sponsor)}`, y: this.roundAsNumber(data.sponsor / data.total * 100, 1), color: '#0eb229' }
						]
					}]
				}
			}
		}
	}
</script>