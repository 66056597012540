<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col md>
					<b-form-group label="Work Order #" :invalid-feedback="requiredFeedback($v.query.workOrderNumber)">
						<b-typeahead v-model.trim="$v.query.workOrderNumber.$model" required
									 :data="options.workOrderNumber"
									 :serializer="s => s.name" @hit="item.workOrderId = $event.id"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Invoice #" :invalid-feedback="requiredFeedback($v.item.invoiceNumber)">
						<b-form-input type="text" required
									  v-model.trim="$v.item.invoiceNumber.$model" :state="getValidState($v.item.invoiceNumber)" />
					</b-form-group>

					<b-form-group label="Invoice Date" :invalid-feedback="requiredFeedback($v.item.invoiceDate)">
						<b-form-input type="date" v-model.trim="$v.item.invoiceDate.$model" :state="getValidState($v.item.invoiceDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Service Start Date" :invalid-feedback="requiredFeedback($v.item.serviceStartDate)">
						<b-form-input type="date" v-model.trim="$v.item.serviceStartDate.$model" :state="getValidState($v.item.serviceStartDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Service End Date" :invalid-feedback="requiredFeedback($v.item.serviceEndDate)">
						<b-form-input type="date" v-model.trim="$v.item.serviceEndDate.$model" :state="getValidState($v.item.serviceEndDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Reviewer" :invalid-feedback="requiredFeedback($v.item.reviewer)">
						<b-typeahead v-model.trim="$v.item.reviewer.$model"
									 :data="options.reviewer"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Approval Date" :invalid-feedback="requiredFeedback($v.item.approvalDate)">
						<b-form-input type="date" v-model.trim="$v.item.approvalDate.$model" :state="getValidState($v.item.approvalDate)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Contract Specialist" :invalid-feedback="requiredFeedback($v.item.contractSpecialist)">
						<b-typeahead v-model.trim="$v.item.contractSpecialist.$model"
									 :data="options.contractSpecialist"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Contract Specialist Received Date" :invalid-feedback="requiredFeedback($v.item.contractSpecialistReceivedDate)">
						<b-form-input type="date" v-model.trim="$v.item.contractSpecialistReceivedDate.$model" :state="getValidState($v.item.contractSpecialistReceivedDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Contract Specialist Processed Date" :invalid-feedback="requiredFeedback($v.item.contractSpecialistProcessedDate)">
						<b-form-input type="date" v-model.trim="$v.item.contractSpecialistProcessedDate.$model" :state="getValidState($v.item.contractSpecialistProcessedDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Accountant" :invalid-feedback="requiredFeedback($v.item.accountant)">
						<b-typeahead v-model.trim="$v.item.accountant.$model"
									 :data="options.accountant"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Accountant Received Date" :invalid-feedback="requiredFeedback($v.item.accountantReceivedDate)">
						<b-form-input type="date" v-model.trim="$v.item.accountantReceivedDate.$model" :state="getValidState($v.item.accountantReceivedDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Accountant Processed Date" :invalid-feedback="requiredFeedback($v.item.accountantProcessedDate)">
						<b-form-input type="date" v-model.trim="$v.item.accountantProcessedDate.$model" :state="getValidState($v.item.accountantProcessedDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)">
				<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="5"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrInvoiceForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			workOrder: {
				type: Object,
				required: false
			}
		},
		data() {
			return {
				apiUrl: 'srworkorderinvoices',
				editRoute: 'SrInvoiceEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					workOrderNumber: [],
					reviewer: [],
					contractSpecialist: [],
					accountant: []
				},
				query: {
					workOrderNumber: ''
				}
			}
		},
		computed: {
			queryWorkOrderNumber() {
				return this.query.workOrderNumber;
			},
			itemReviewer() {
				return this.item.reviewer;
			},
			itemContractSpecialist() {
				return this.item.contractSpecialist;
			},
			itemAccountant() {
				return this.item.accountant;
			}
		},
		validations: {
			item: {
				workOrderId: { required },
				invoiceNumber: { required },
				invoiceDate: {},
				serviceStartDate: {},
				serviceEndDate: {},
				reviewer: {},
				approvalDate: {},
				contractSpecialist: {},
				contractSpecialistReceivedDate: {},
				contractSpecialistProcessedDate: {},
				accountant: {},
				accountantReceivedDate: {},
				accountantProcessedDate: {},
				notes: {}
			},
			query: {
				workOrderNumber: { required }
			}
		},
		watch: {
			queryWorkOrderNumber: _.debounce(function (query) { this.findWorkOrders(query) }, 500),
			itemReviewer: _.debounce(function (query) { this.find('reviewer', query) }, 500),
			itemContractSpecialist: _.debounce(function (query) { this.find('contractSpecialist', query) }, 500),
			itemAccountant: _.debounce(function (query) { this.find('accountant', query) }, 500)
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				if (!this.isNullOrEmpty(this.workOrder)) {
					this.query.workOrderNumber = this.workOrder.workOrderNumber;
				}

				if (this.isUpdate) {
					this.item.invoiceDate = this.dateForForm(this.item.invoiceDate);
					this.item.serviceStartDate = this.dateForForm(this.item.serviceStartDate);
					this.item.serviceEndDate = this.dateForForm(this.item.serviceEndDate);
					this.item.approvalDate = this.dateForForm(this.item.approvalDate);
					this.item.contractSpecialistReceivedDate = this.dateForForm(this.item.contractSpecialistReceivedDate);
					this.item.contractSpecialistProcessedDate = this.dateForForm(this.item.contractSpecialistProcessedDate);
					this.item.accountantReceivedDate = this.dateForForm(this.item.accountantReceivedDate);
					this.item.accountantProcessedDate = this.dateForForm(this.item.accountantProcessedDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async findWorkOrders(query) {
				try {
					const response = await this.$http.get(`/srworkorders/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					this.options.workOrderNumber = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>
