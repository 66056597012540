<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control Structural Repair Program<br />Work Order #{{$parent.item.workOrderNumber}}</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-table-simple small class="table-definitions">
				<b-tbody>
					<b-tr>
						<b-th>Revision</b-th>
						<b-td>{{valueOrNa($parent.item.revision)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>PO#</b-th>
						<b-td>{{valueOrNa($parent.item.purchaseOrder)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>PO Issuance Date</b-th>
						<b-td>{{$parent.item.purchaseOrderDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Name</b-th>
						<b-td>{{valueOrNa($parent.item.name)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Execution Date</b-th>
						<b-td>{{$parent.item.executionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Expiration Date</b-th>
						<b-td>{{$parent.item.expirationDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Completion Date</b-th>
						<b-td>{{$parent.item.completionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Project Manager</b-th>
						<b-td>{{valueOrNa($parent.item.projectManager)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Contractor</b-th>
						<b-td>{{valueOrNa($parent.item.contractor)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Primary POC</b-th>
						<b-td>{{valueOrNa($parent.item.pocPrimary)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Secondary POC</b-th>
						<b-td>{{valueOrNa($parent.item.pocSecondary)}}</b-td>
					</b-tr>

					<b-tr>
						<b-th>Funding Type</b-th>
						<b-td>{{valueOrNa($parent.item.fundingType)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Funding Subcategory</b-th>
						<b-td>{{valueOrNa($parent.item.fundingSubcategory)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Fiscal Year</b-th>
						<b-td>{{valueOrNa($parent.item.fundingFiscalYear)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Project Type</b-th>
						<b-td>{{valueOrNa($parent.item.projectType)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Project Subtype</b-th>
						<b-td>{{valueOrNa($parent.item.projectSubtype)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Notes</b-th>
						<b-td>{{valueOrNa($parent.item.notes, 'None')}}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>

			<b-table-simple small class="table-definitions" bordered>
				<b-tbody>
					<b-tr class="bg-light">
						<b-th>Project</b-th>
						<b-th>Dam</b-th>
					</b-tr>
					<b-tr v-for="(p, i) in $parent.projects" :key="i">
						<b-td>#{{p.projectNumber}} - {{p.projectName}}</b-td>
						<b-td>
							<span v-if="isNullOrEmpty(p.damId)">N/A</span>
							<span v-else>{{p.damName}}</span>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>

			<h3 class="my-3">Work Order Funding by Dam</h3>
			<b-table striped responsive small show-empty :fields="$parent.dams.fields" :items="$parent.dams.items">
				<template #cell(edit)>
					
				</template>
				<template #cell(dam)="data">
					{{data.value.name}}
				</template>
				<template #cell(agreement)="data">
					{{data.value.name}}
				</template>
				<template #cell(delete)>
					
				</template>
				<template #empty>
					<p class="my-2">This work order does not have any dams entered.</p>
				</template>

				<template #custom-foot>
					<b-tr class="bg-light border-bottom font-weight-bold">
						<b-td colspan="2">Total</b-td>
						<b-td class="text-right">${{ sum($parent.dams.items, 'fundingAmount') | number(2)}}</b-td>
						<b-td colspan="5"></b-td>
					</b-tr>
				</template>
			</b-table>
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'SrWorkOrderPrint',
		components: { PrintMenu },
		data() {
			return {
				dams: {
					fields: [
						
					]
				}
			}
		}
	}
</script>