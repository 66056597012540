<template>
	<div>
		<auth-container :page="page">
			<b-container v-if="isPrintView" fluid class="p-0">
				<router-view></router-view>
			</b-container>
			<b-container v-else fluid class="p-0">
				<b-row no-gutters>
					<b-col md="3" lg="2" order="2" order-md="1" class="fixed-height bg-light">
						<div class="px-3 pt-4">
							<h6>Programs</h6>
							<b-nav vertical class="dash-items-nav nav-lg">
								<b-nav-item to="/">
									<font-awesome-icon :icon="['fas', 'globe-americas']" fixed-width class="mr-3" /> Dashboard
								</b-nav-item>
								<b-nav-item to="/om">
									<font-awesome-icon :icon="['fas', 'project-diagram']" fixed-width class="mr-3" /> O&amp;M
									<b-nav vertical v-if="$route.path.startsWith('/om')" class="nested">
										<b-nav-item to="/om/reimbursements">Reimbursement Requests</b-nav-item>
										<b-nav-item to="/om/proposals">Proposals</b-nav-item>
										<b-nav-item to="/om/inkindmatches">In-Kind Match Reports</b-nav-item>
										<b-nav-item to="/om/sponsors">Sponsors</b-nav-item>
										<b-nav-item to="/om/activities">Eligible Practices</b-nav-item>
										<b-nav-item to="/om/reports">Reports</b-nav-item>
									</b-nav>
								</b-nav-item>
								<b-nav-item to="/sr">
									<font-awesome-icon :icon="['fas', 'tools']" fixed-width class="mr-3" /> Structural Repair
									<b-nav vertical v-if="$route.path.startsWith('/sr')" class="nested">
										<b-nav-item to="/sr/applications">Applications</b-nav-item>
										<b-nav-item to="/sr/plans">Budget Plans</b-nav-item>
										<b-nav-item to="/sr/projects">
											Projects
											<b-nav vertical class="nested py-0">
												<b-nav-item to="/sr/projects/phases/dam-assessment">Dam Assessment</b-nav-item>
												<b-nav-item to="/sr/projects/phases/plan-env-assessment">Plan/Env. Assessment</b-nav-item>
												<b-nav-item to="/sr/projects/phases/reaffirm-study">Reaffirming Study</b-nav-item>
												<b-nav-item to="/sr/projects/design">Design</b-nav-item>
												<b-nav-item to="/sr/projects/construction">Construction</b-nav-item>
											</b-nav>
										</b-nav-item>
										<b-nav-item to="/sr/agreements">Agreements</b-nav-item>
										<b-nav-item to="/sr/workorders">
											Work Orders
											<b-nav vertical class="nested py-0">
												<b-nav-item to="/sr/workorders/invoices">Invoices</b-nav-item>
												<b-nav-item to="/sr/workorders/hubs">HUB Logs</b-nav-item>
											</b-nav>
										</b-nav-item>
										<b-nav-item to="/sr/contacts">Contacts</b-nav-item>
									</b-nav>
								</b-nav-item>
							</b-nav>

							<div class="mt-4">
								<h6>Data Tables</h6>
								<b-nav vertical class="dash-items-nav nav-lg">
									<b-nav-item to="/dams"><font-awesome-icon :icon="['fas', 'water']" fixed-width class="mr-3" /> Dams</b-nav-item>
									<b-nav-item to="/swcds"><font-awesome-icon :icon="['fas', 'database']" fixed-width class="mr-3" /> SWCDs</b-nav-item>
									<b-nav-item to="/maplayers"><font-awesome-icon :icon="['fas', 'map']" fixed-width class="mr-3" /> Map Layers</b-nav-item>
								</b-nav>
							</div>

							<div class="mt-4" v-if="isAuthorized(roleNames.admin)">
								<h6>Administration</h6>
								<b-nav vertical class="dash-items-nav nav-lg">
									<b-nav-item to="/funds"><font-awesome-icon :icon="['fas', 'money-check-alt']" fixed-width class="mr-3" /> Funds</b-nav-item>
									<b-nav-item to="/users"><font-awesome-icon :icon="['fas', 'user-friends']" fixed-width class="mr-3" /> Users</b-nav-item>
								</b-nav>
							</div>

							<div class="mt-5 mb-2">
								<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid mx-5">
							</div>
						</div>
					</b-col>
					<b-col md="9" lg="10" order="1" order-md="2" class="fixed-height shadow-sm no-shadow-sm" style="z-index:500">
						<div v-if="$route.name === 'Home'">
							<div class="map-container full-map">
								<l-map ref="leafmap" style="height: 100%; width:100%" :zoom="mapBase.zoom" :center="mapBase.center" @mousemove="mapMove">
									<l-control-layers position="topright"></l-control-layers>
									<l-tile-layer v-for="tileProvider in mapBase.tileProviders"
												  :key="tileProvider.name"
												  :name="tileProvider.name"
												  :visible="tileProvider.visible"
												  :url="tileProvider.url"
												  :attribution="tileProvider.attribution"
												  layer-type="base" />

									<l-layer-group layer-type="overlay" name="SWCDs">
										<l-geo-json :geojson="map.layers.swcds.geojson" :options="map.layers.swcds.options" :options-style="map.layers.swcds.style"></l-geo-json>
									</l-layer-group>
									<l-layer-group layer-type="overlay" name="Dams">
										<l-geo-json :geojson="map.layers.dams.geojson" :options="map.layers.dams.options"></l-geo-json>
									</l-layer-group>

									<l-layer-group layer-type="overlay" v-for="(extraLayer, i) in map.additionalLayers" :key="i" :name="extraLayer.name" :ref="`extraLayer${i}`" :visible="false">
										<l-geo-json :geojson="extraLayer.geojson" :options="mapExtraLayersOptions" :options-style="mapExtraLayersStyle(i)"></l-geo-json>
									</l-layer-group>

									<l-control position="bottomleft">
										<div v-if="map.currentCoords !== undefined">Cursor position: {{map.currentCoords.lat | number(3)}}, {{map.currentCoords.lng | number(3)}}</div>
									</l-control>

									<l-control position="bottomright">
										<b-dropdown text="Filter" variant="dark" size="md" style="font-size:0.8rem" dropup right>
											<b-dropdown-form>
												<b-input-group prepend="Fiscal Year" class="flex-nowrap mb-3" style="min-width:250px">
													<b-form-select v-model="fiscalYear.selected" :options="fiscalYear.options" @change="toggleOmGeojson">
														<template #first>
															<b-form-select-option :value="null" disabled>-- Please select a year --</b-form-select-option>
														</template>
													</b-form-select>
												</b-input-group>

												<b-form-checkbox v-model="map.filter.withOm" @change="toggleOmGeojson">Show dams/districts with O&amp;M</b-form-checkbox>
											</b-dropdown-form>
										</b-dropdown>
									</l-control>
								</l-map>
								<div v-if="map.loading" class="map-loading-overlay">
									<font-awesome-icon :icon="['fas', 'spinner']" spin size="3x" />
								</div>
							</div>
						</div>
						<div v-else>
							<b-breadcrumb :items="breadCrumbs" class="bg-white px-md-4 mb-0"></b-breadcrumb>
							<div class="container-fluid px-md-4 pt-2 pb-4">
								<router-view></router-view>
							</div>
						</div>
					</b-col>
				</b-row>
			</b-container>

			<b-modal v-model="modals.swcd.show" :title="`SWCD# ` + (modals.swcd.item !== null ? `${modals.swcd.item.id} - ${modals.swcd.item.name}` : '')" scrollable size="lg">
				<error-list :errors="modals.swcd.errors"></error-list>
				<page-loading :loading="modals.swcd.loading"></page-loading>

				<div v-if="!modals.swcd.loading && modals.swcd.errors.length < 1">
					<div class="d-flex align-items-center justify-content-center mb-4">
						<h4 class="mb-0 mr-3">O&amp;M</h4>

						<div class="form-inline">
							<b-input-group prepend="Fiscal Year">
								<b-form-select v-model="fiscalYear.selected" :options="fiscalYear.options" @change="getDamOm">
									<template #first>
										<b-form-select-option :value="null" disabled>-- Please select a year --</b-form-select-option>
									</template>
								</b-form-select>
							</b-input-group>
						</div>
					</div>

					<page-loading :loading="modals.swcd.omLoading"></page-loading>
					<div v-if="modals.swcd.om && !modals.swcd.omLoading">
						<b-alert v-if="modals.swcd.om.swcdProposedOm == 0" show variant="info" class="text-center">
							No FY{{fiscalYear.selected}} O&amp;M proposals for {{modals.swcd.item.id}}.
						</b-alert>
						<highcharts v-else :options="swcdOmBarChart"></highcharts>
					</div>
				</div>

				<div slot="modal-footer">
					<b-button v-if="modals.swcd.item !== null" type="button" variant="primary" :to="`/swcds/${modals.swcd.item.id}`" @click="modals.swcd.show = false">Go to SWCD</b-button>
					<b-button type="button" variant="secondary" @click="modals.swcd.show = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="modals.dam.show" :title="`Dam: ` + (modals.dam.item !== null ? `${modals.dam.item.nid_id} - ${modals.dam.item.name}` : '')" scrollable size="xl">
				<error-list :errors="modals.dam.errors"></error-list>
				<page-loading :loading="modals.dam.loading"></page-loading>

				<div v-if="!modals.dam.loading && modals.dam.errors.length < 1 && modals.dam.item !== null">
					<b-row>
						<b-col md="5">
							<b-table-simple small>
								<b-tbody>
									<b-tr>
										<b-th>County</b-th>
										<b-td>{{modals.dam.item.county}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>SWCD</b-th>
										<b-td>{{modals.dam.item.swcd}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Watershed</b-th>
										<b-td>{{modals.dam.item.watershed}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>River Basin</b-th>
										<b-td>{{modals.dam.item.riverBasin}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Hazard Class</b-th>
										<b-td>{{modals.dam.item.hazardClass}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Hazard Design</b-th>
										<b-td>{{modals.dam.item.hazardDesign}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Year Completed</b-th>
										<b-td>{{modals.dam.item.yearCompleted}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Life Expiration Year</b-th>
										<b-td>{{modals.dam.item.lifeExpirationYear}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col md="7">
							<div class="d-flex align-items-center justify-content-center mb-4">
								<h4 class="mb-0 mr-3">O&amp;M</h4>

								<div class="form-inline">
									<b-input-group prepend="Fiscal Year">
										<b-form-select v-model="fiscalYear.selected" :options="fiscalYear.options" @change="getDamOm">
											<template #first>
												<b-form-select-option :value="null" disabled>-- Please select a year --</b-form-select-option>
											</template>
										</b-form-select>
									</b-input-group>
								</div>
							</div>

							<page-loading :loading="modals.dam.omLoading"></page-loading>
							<div v-if="!modals.dam.omLoading">
								<b-alert v-if="modals.dam.om.damProposedOm == 0" show variant="info" class="text-center">
									No FY{{fiscalYear.selected}} O&amp;M proposals for {{modals.dam.item.nid_id}}.
								</b-alert>
								<highcharts v-else :options="damOmBarChart"></highcharts>
							</div>
						</b-col>
					</b-row>

					
				</div>

				<div slot="modal-footer">
					<b-button v-if="modals.dam.item !== null" type="button" variant="primary" :to="`/dams/${modals.dam.item.id}`" @click="modals.dam.show = false">Go to Dam</b-button>
					<b-button type="button" variant="secondary" @click="modals.dam.show = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>
		</auth-container>
	</div>
</template>

<script>
	import L from 'leaflet';
	import { LMap, LTileLayer, LControl, LControlLayers, LGeoJson, LLayerGroup } from 'vue2-leaflet';
	import 'leaflet/dist/leaflet.css';

	function labelMoneyFormatter() {
		let value = this.y;
		let base = 10 ** 2;
		let x = (Math.round(Number(value) * base) / base).toFixed(2);
		var parts = x.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return '<b>$' + parts.join(".") + '</b>';
	}

	export default {
        name: 'Home',
		components: {
			LMap, LTileLayer, LControl, LControlLayers, LGeoJson, LLayerGroup
		},
		data() {
			return {
				isPrintView: this.$route.name.includes('Print'),
                page: {
                    errors: [],
                    loading: false,
					showLogin: false
				},
				modals: {
					swcd: {
						show: false,
						loading: false,
						errors: [],
						item: null,
						om: null,
						omLoading: false
					},
					dam: {
						show: false,
						loading: false,
						errors: [],
						item: null,
						om: null,
						omLoading: false
					}
				},
				map: {
					loading: false,
					currentCoords: undefined,
					layers: {
						swcds: {
							geojson: null,
							options: {},
							style: {
								weight: 1,
								opacity: 1,
								color: '#9dd123',
								fillOpacity: 0.5,
								fillColor: '#ceff5e'
							}
						},
						dams: {
							geojson: null,
							options: {},
							style: {
								radius: 4,
								weight: 1,
								opacity: 1,
								color: '#9b3ee8',
								fillOpacity: 0.8,
								fillColor: '#ca93f9'
							}
						}
					},
					additionalLayers: [],
					filter: {
						withOm: false,
						dam: null,
						swcd: null
					}
				},
				fiscalYear: {
					selected: null,
					options: []
				}
            }
		},
		watch: {
			'$route'(to, from) {
				this.log(from);
				this.isPrintView = to.name.includes('Print');
				this.get();
			}
		},
		async mounted() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				if (this.$route.name === 'Home') {
					try {
						const fyResponse = await this.$http.get(`funds/list`, this.getTokenHeader());
						this.fiscalYear.options = fyResponse.data;
						if (this.fiscalYear.options.length > 0) this.fiscalYear.selected = this.fiscalYear.options[0];

						const response = await this.$http.get(`home`, this.getTokenHeader());
						this.log(response.data);
						this.map.layers.swcds.geojson = response.data.swcds;
						this.map.layers.swcds.options = {
							onEachFeature: this.mapSwcdsOnEachFeature
						};

						this.map.layers.dams.geojson = response.data.dams;
						this.map.layers.dams.options = {
							pointToLayer: (feature, latLng) => L.circleMarker(latLng, this.map.layers.dams.style),
							onEachFeature: this.mapDamsOnEachFeature
						};

						this.map.additionalLayers = response.data.layers;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.loading = false;
			},
			async toggleOmGeojson() {
				this.page.errors = [];
				this.map.loading = true;

				try {
					let url = 'home';
					if (this.map.filter.withOm) {
						url = `home/withom/${this.fiscalYear.selected}`;
					}
					const response = await this.$http.get(url, this.getTokenHeader());
					this.log(response.data);
					this.map.layers.swcds.geojson = response.data.swcds;
					this.map.layers.dams.geojson = response.data.dams;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.map.loading = false;
			},
			mapMove(e) {
				this.map.currentCoords = e.latlng;
			},
			async mapSwcdClick(e) {
				this.modals.swcd.item = e.target.feature.properties;
				this.modals.swcd.errors = [];
				this.modals.swcd.show = true;
				this.modals.swcd.loading = true;

				await this.getSwcdOm();

				this.modals.swcd.loading = false;
			},
			async mapDamClick(e) {
				this.modals.dam.item = e.target.feature.properties;
				this.modals.dam.errors = [];
				this.modals.dam.show = true;
				this.modals.dam.loading = true;

				await this.getDamOm();

				this.modals.dam.loading = false;
			},
			async getDamOm() {
				this.modals.dam.omLoading = true;
				this.modals.dam.om = null;
				if (!this.isNullOrEmpty(this.modals.dam.item.id) && !this.isNullOrEmpty(this.fiscalYear.selected)) {
					try {
						const response = await this.$http.get(`home/damom/${this.modals.dam.item.id}/${this.fiscalYear.selected}`, this.getTokenHeader());
						this.log(response.data);
						this.modals.dam.om = response.data;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.modals.dam.errors = this.logError(error);
					}
				}
				this.modals.dam.omLoading = false;
			},
			async getSwcdOm() {
				this.modals.swcd.omLoading = true;
				this.modals.swcd.om = null;
				if (!this.isNullOrEmpty(this.modals.swcd.item.id) && !this.isNullOrEmpty(this.fiscalYear.selected)) {
					try {
						const response = await this.$http.get(`home/swcdom/${this.modals.swcd.item.id}/${this.fiscalYear.selected}`, this.getTokenHeader());
						this.log(response.data);
						this.modals.swcd.om = response.data;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.modals.swcd.errors = this.logError(error);
					}
				}
				this.modals.swcd.omLoading = false;
			},
			getBarChart(omStartingBalance, totalProposedOm, totalApproved, itemProposedOm, itemApproved) {
				return {
					chart: {
						type: 'column'
					},
					title: {
						text: `FY${this.fiscalYear.selected} O&M Funds`
					},
					tooltip: {
						formatter: labelMoneyFormatter
					},
					xAxis: {
						type: 'category'
					},
					yAxis: {
						type: 'logarithmic',
						plotLines: [{
							color: '#840a0a',
							width: 2,
							value: this.roundAsNumber(omStartingBalance, 2),
							label: {
								text: `Total O&M Starting Balance ${this.money(omStartingBalance)}`,
								align: 'center'
							}
						}],
					},
					plotOptions: {
						series: {
							grouping: false,
							borderWidth: 0
						}
					},
					legend: {
						enabled: false
					},
					series: [{
						color: 'rgb(158, 159, 163)',
						pointPlacement: -0.2,
						linkedTo: 'main',
						name: 'Total Funds',
						type: 'column',
						data: [
							{ name: `Proposed O&M`, y: this.roundAsNumber(totalProposedOm, 2) },
							{ name: `Approved, Allocated`, y: this.roundAsNumber(totalApproved, 2) }
						],
						dataLabels: {
							enabled: true,
							align: 'center',
							color: '#FFFFFF',
							inside: true,
							formatter: labelMoneyFormatter
						}
					}, {
						id: 'main',
						dataSorting: {
							enabled: true,
							matchByName: true
						},
						name: 'Dam Funds',
						type: 'column',
						data: [
							{ name: `Proposed O&M`, y: this.roundAsNumber(itemProposedOm, 2), color: '#124e8e' },
							{ name: `Approved, Allocated`, y: this.roundAsNumber(itemApproved, 2), color: '#128c5d' }
						],
						dataLabels: {
							enabled: true,
							align: 'center',
							color: '#FFFFFF',
							inside: true,
							formatter: labelMoneyFormatter
						}
					}]
				}
			},
			mapExtraLayersStyle(i) {
				let color = this.getMapLayerColor(i);

				return {
					weight: 1,
					opacity: 1,
					color: color,
					fillOpacity: 0.2,
					fillColor: color
				};
			}
		},
        computed: {
            breadCrumbs() {
                var crumbs = [];
                for (let item of this.$route.matched) {
                    let name = item.meta.title ? item.meta.title : item.name;

                    crumbs.push({
                        text: name,
                        to: { name: item.name }
                    });
                }
                return crumbs;
			},
			mapDamsOnEachFeature() {
				return (feature, layer) => {
					let popText = `<div>Dam ${feature.properties.nid_id} - ${feature.properties.name}</div>`;

					layer.on({
						click: this.mapDamClick
					});

					layer.bindTooltip(
						popText,
						{ permanent: false, sticky: true });
				};
			},
			mapSwcdsOnEachFeature() {
				return (feature, layer) => {
					let popText = `<div>SWCD# ${feature.properties.id} - ${feature.properties.name}</div>`;

					layer.on({
						click: this.mapSwcdClick
					});

					layer.bindTooltip(
						popText,
						{ permanent: false, sticky: true });
				};
			},
			mapExtraLayersOptions() {
				return {
					onEachFeature: this.mapLayersOnEachFeature
				};
			},
			mapLayersOnEachFeature() {
				return (feature, layer) => {
					let label = this.isNullOrEmpty(feature.properties.label) ? "" : feature.properties.label;
					let popText = `<div><div><b>${label} ${feature.properties.name}</b></div>`;

					let keys = Object.keys(feature.properties);
					let ignoreKeys = ['id', 'name', 'label'];
					for (let k of keys) {
						if (!ignoreKeys.includes(k))
							popText += `<div>${k} - ${feature.properties[k]}</div>`;
					}
					popText += '</div>';

					layer.bindTooltip(
						popText,
						{ permanent: false, sticky: true });
				};
			},
			damOmBarChart() {
				return this.getBarChart(this.modals.dam.om.omStartingBalance,
					this.modals.dam.om.totalProposedOm,
					this.modals.dam.om.totalApproved,
					this.modals.dam.om.damProposedOm,
					this.modals.dam.om.damApproved);
			},
			swcdOmBarChart() {
				return this.getBarChart(this.modals.swcd.om.omStartingBalance,
					this.modals.swcd.om.totalProposedOm,
					this.modals.swcd.om.totalApproved,
					this.modals.swcd.om.swcdProposedOm,
					this.modals.swcd.om.swcdApproved);
			}
        }
	}
</script>
