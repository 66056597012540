<template>
	<auth-container :page="page">
		<h2 class="mb-4">Practice {{item.practiceCode}}</h2>

		<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
			<b-tab title="Overview" active>
				<b-row>
					<b-col md>
						<b-table-simple small>
							<b-tbody>
								<b-tr>
									<b-th>Code</b-th>
									<b-td>{{item.practiceCode}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Description</b-th>
									<b-td>{{item.description}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Cost/Unit</b-th>
									<b-td>${{item.costPerUnit | number(2)}} per {{item.units}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Comments</b-th>
									<b-td>{{valueOrNa(item.other, 'None')}}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</b-col>
				</b-row>

				<fixed-action-bar>
					<back-button />
					<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
				</fixed-action-bar>
			</b-tab>
			<b-tab title="Edit" v-if="!isReadOnly">
				<activity-form ref="activityForm" :item="item" is-update @change="changesSaved"></activity-form>
			</b-tab>
			<b-tab title="Change Log">
				<grid-view :api-url="`home/logs/OmActivities/${itemId}`" use-filter small :fields="table.fields"
						   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
						   collection-description="change log"
						   item-name="log">
					<back-button />
				</grid-view>
			</b-tab>
		</b-tabs>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this practice?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</auth-container>
</template>

<script>
	import moment from 'moment';
	import GridView from '@/components/helpers/GridView';
	import ActivityForm from './ActivityForm.vue';

	export default {
		name: 'ActivityEdit',
		components: {
			ActivityForm, GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				item: {},
				table: {
					fields: [
						{ key: 'activityDate', label: 'Date', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } },
						{ key: 'userName', label: 'User', sortable: true },
						{ key: 'description', label: 'Description', sortable: true }
					],
					sort: 'activityDate',
					reverse: true,
					itemsPerPage: 100
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`omactivities/${this.$route.params.id}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.activity;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.page.tabIndex = 1;
				this.$refs.activityForm.launchToast();
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`omactivities/${this.$route.params.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: 'ActivityList' });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			}
		}
	}
</script>