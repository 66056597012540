<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control O&amp;M Program<br />In-Kind Match Report {{$parent.item.reportNumber}}</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-row>
				<b-col md="7">
					<b-table-simple small class="table-definitions border-bottom">
						<b-tbody>
							<b-tr>
								<b-th>Sponsor</b-th>
								<b-td>
									{{$parent.sponsor.type}} {{$parent.sponsor.id}} - {{$parent.sponsor.name}}
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Performing Entity</b-th>
								<b-td>{{valueOrNa($parent.item.performingEntity, 'None')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Source of Funds</b-th>
								<b-td>{{valueOrNa($parent.item.sourceOfFunds, 'None')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Notes</b-th>
								<b-td>{{valueOrNa($parent.item.notes, 'None')}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
				<b-col md>
					<b-table-simple small class="table-definitions border-bottom">
						<b-tbody>
							<b-tr>
								<b-th>Recorded</b-th>
								<b-td>{{$parent.item.dateRecorded | date('ddd, MMM D, YYYY', 'None')}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>

			<h3 class="mt-3">Practices</h3>
			<b-table striped responsive small show-empty :fields="activities.fields" :items="$parent.activities.items" no-local-sorting style="font-size:0.8rem">
				<template #cell(dam)="data">
					{{data.value.name}}
				</template>
				<template #cell(practice)="data">
					{{data.value.name}}
				</template>
				<template #cell(unitsPerformed)="data">
					{{data.value | number(2)}} {{data.item.units}}
				</template>
				<template #empty>
					<p class="my-2">This proposal does not have any practices entered.</p>
				</template>

				<template #custom-foot>
					<b-tr class="bg-light border-bottom font-weight-bold">
						<b-td colspan="4">Total</b-td>
						<b-td class="text-right">${{ sum($parent.activities.items, 'totalContribution') | number(2)}}</b-td>
					</b-tr>
				</template>
			</b-table>
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'InkindMatchPrint',
		components: { PrintMenu },
		data() {
			return {
				activities: {
					fields: [
						{ key: 'dam', label: 'Dam', sortable: false },
						{ key: 'practice', label: 'Practice', sortable: false },
						{ key: 'unitsPerformed', label: 'Units Performed', sortable: false, class: 'text-right', formatter: (value) => { return this.numberFormat(value, 2); } },
						{ key: 'unitCost', label: 'Unit Cost', sortable: false, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'totalContribution', label: 'Total Contribution', sortable: false, class: 'text-right', formatter: (value) => { return this.money(value); } }
					]
				}
			}
		}
	}
</script>