<template>
	<div>
		<auth-container v-if="$route.name === 'SrContactList'" :page="page">
			<h2 class="mb-4">Contacts</h2>

			<grid-view ref="gridTable" api-url="srcontacts" small use-filter :fields="table.fields" :hide-edit="isReadOnly"
					   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
					   collection-description="contacts" show-create
					   item-name="contact"
					   allow-export export-file-name="sr-contacts" :export-ignore-fields="['id','name']">
				<b-button v-if="isAuthorized(roleNames.superAdmin)" variant="primary" @click="page.showModal = true">Import</b-button>
			</grid-view>

			<b-modal v-model="page.showModal" title="Upload Contacts" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<p class="mt-3">
					Upload a csv of contacts. 
				</p>

				<div slot="modal-footer">
					<json-to-csv :json-data="template" filename="sr-contacts-template" text="Download Template" class="mr-2" />
					<b-button type="button" variant="primary" @click="page.showModal = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'SrContactList',
		components: {
			GridView, vueDropzone: vue2Dropzone
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					showModal: false
				},
				dropzoneOptions: {
					url: '/api/srcontacts/upload/',
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'firstName', sortable: true },
						{ key: 'lastName', sortable: true },
						{ key: 'nickname', sortable: true },
						{ key: 'category', sortable: true },
						{ key: 'sponNotice', label: 'Spon. Notice', sortable: true, formatter: value => { return value ? 'Y' : 'N' } },
						{ key: 'organization', sortable: true },
						{ key: 'jobTitle', sortable: true },
						{ key: 'officePhone', sortable: true },
						{ key: 'officePhoneExt', label: 'Ext', sortable: true },
						{ key: 'mobilePhone', sortable: true },
						{ key: 'email', sortable: true },
						{ key: 'city', sortable: true },
						{ key: 'state', sortable: true },
						{ key: 'zip', sortable: true }
					],
					sort: 'lastName',
					reverse: false,
					itemsPerPage: 100
				},
				template: [{
					firstName: null,
					lastName: null,
					nickname: null,
					category: null,
					sponNotice: 'N',
					organization: null,
					jobTitle: null,
					officePhone: null,
					officePhoneExt: null,
					mobilePhone: null,
					email: null,
					alternateEmails: null,
					addressNumber: null,
					addressStreet: null,
					addressSub: null,
					addressSuite: null,
					city: null,
					state: null,
					zip: null,
					notes: null
				}]
			}
		},
		methods: {
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				await this.$refs.gridTable.get();
				this.$refs.savedAlert.startAlert();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			}
		}
	}
</script>