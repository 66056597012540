<template>
	<div>
		<auth-container v-if="$route.name === 'SrPhaseList'" :page="page">
			<h2 class="mb-4">{{phaseDescription}} Phases</h2>

			<div class="mx-auto">
				<b-row class="mb-3" align-v="end">
					<b-col lg="6">
						<b-form inline>
							<b-input-group>
								<b-form-input v-model="table.filter" placeholder="Search..." @input="filterChg"></b-form-input>

								<b-input-group-append class="pointer mr-2">
									<b-input-group-text @click="clearFilter">
										<font-awesome-icon :icon="['fas', 'times']" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
							<b-button variant="primary" type="button" @click="table.showAdvancedFilter = !table.showAdvancedFilter">Advanced Filter</b-button>
						</b-form>
					</b-col>
					<b-col lg="6" class="text-right">
						<div class="ml-auto">
							Showing {{showingFirst}} - {{showingLast}}
							of {{table.total}}
						</div>
					</b-col>
				</b-row>

				<b-collapse v-model="table.showAdvancedFilter" class="my-3">
					<b-card bg-variant="light">
						<b-row>
							<b-col lg="4">
								<div class="form-group row">
									<label for="advancedFilter.projectNumber" class="col-lg-5 col-form-label">Project #</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.projectNumber"
													 v-model="advancedFilter.projectNumber"
													 :data="advancedFilter.options.projectNumber"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.projectName" class="col-lg-5 col-form-label">Project Name</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.projectName"
													 v-model="advancedFilter.projectName"
													 :data="advancedFilter.options.projectName"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.dam" class="col-lg-5 col-form-label">Dam NID_ID</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.dam"
													 v-model="advancedFilter.dam"
													 :data="advancedFilter.options.dam"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>
							</b-col>
							<b-col lg>
								<div class="form-group row" v-for="field in filterDateFields" :key="field.value">
									<label for="advancedFilter[field.value].start" class="col-lg-4 col-form-label">{{field.text}}</label>
									<div class="col-lg-8">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter[field.value].start" v-model="advancedFilter[field.value].start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter[field.value].start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter[field.value].end" v-model="advancedFilter[field.value].end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter[field.value].end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
						<hr class="my-3" />
						<div>
							<b-button variant="primary" type="button" @click="get" class="mr-2">Apply</b-button>
							<b-button variant="secondary" type="button" @click="clearAdvancedFilter" class="mr-2">Clear</b-button>
							<b-button variant="secondary" type="button" @click="table.showAdvancedFilter = false">Close</b-button>
						</div>
					</b-card>
				</b-collapse>

				<b-table striped responsive table-class="table-fixed-height border-bottom table-grid" sticky-header="60vh"
						 small show-empty
						 :items="items" :busy="table.loading"
						 :fields="table.fields"
						 :no-local-sorting="true"
						 :sort-by.sync="table.sort"
						 :sort-desc.sync="table.reverse"
						 @sort-changed="sortChg">
					<template #table-busy>
						<div class="text-center my-4">
							<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
						</div>
					</template>
					<template #cell(edit)="data">
						<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'edit']" class="pointer text-primary" @click="editSubItem(data.item.applicationId)" />
					</template>
					<template #cell(projectNumber)="data">
						<router-link :to="`/sr/projects/${data.item.applicationId}`">{{data.value}}</router-link>
					</template>
					<template #cell(damId)="data">
						<router-link :to="`/dams/${data.value}`">{{data.item.nid_id}}</router-link>
					</template>
					<template #empty>
						<h4>No records found.</h4>
					</template>
					<template #emptyfiltered>
						<h4>No records found matching "{{table.filter}}".</h4>
					</template>
				</b-table>
			</div>

			<fixed-action-bar>
				<b-button v-if="!isReadOnly" type="button" variant="success" @click="createSubItem" class="mr-2">Create</b-button>
				<json-to-csv :api-url="`${apiUrl}/export/${phaseKey}`" :filename="apiUrl" class="mr-2" />
				<b-pagination class="ml-auto mb-0" v-if="table.total > table.itemsPerPage" size="md" :total-rows="table.total" v-model="table.currentPage" :per-page="table.itemsPerPage" @change="pageChg" />
			</fixed-action-bar>

			<b-modal v-model="modal.show" :title="`${phaseDescription} Information`" scrollable size="lg" hide-footer>
				<error-list :errors="modal.errors"></error-list>

				<sr-phase-form :item="modal.item" :project="modal.project" :is-update="modal.isUpdate" @change="modalSaved" @cancel="cancelModal"></sr-phase-form>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import _ from 'underscore';
	import SrPhaseForm from './SrPhaseForm.vue';

	export default {
		name: 'SrPhaseList',
		components: {
			SrPhaseForm
		},
		data() {
			return {
				apiUrl: 'srprojects/phases',
				page: {
					errors: [],
					loading: true,
					showLogin: false,
					saveErrors: [],
					showModal: false
				},
				table: {
					fields: [
						{ key: 'edit', label: '', stickyColumn: true },
						{ key: 'projectNumber', label: 'Project #', sortable: true },
						{ key: 'name', sortable: true },
						{ key: 'damId', label: 'NID_ID', sortable: true },
						{ key: 'reportDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
					],
					sort: 'projectNumber',
					reverse: true,
					itemsPerPage: 100,
					filter: null,
					currentPage: 1,
					total: 0,
					loading: false,
					showAdvancedFilter: false
				},
				items: [],
				advancedFilter: {
					dam: null,
					projectNumber: null,
					projectName: null,
					reportDate: {
						start: null,
						end: null
					},
					options: {
						projectNumber: [],
						projectName: [],
						dam: []
					}
				},
				filterDateFields: [
					{ value: 'reportDate', text: 'Report Date' }
				],
				phaseOptions: {
					'dam-assessment': 'Dam Assessment',
					'plan-env-assessment': 'Planning/Environmental Assessment',
					'reaffirm-study': 'Reaffirming Study'
				},
				modal: {
					item: {},
					project: null,
					show: false,
					loading: false,
					errors: [],
					isUpdate: false
				}
			}
		},
		computed: {
			phaseKey() {
				return this.$route.params.phase;
			},
			phaseDescription() {
				if (this.phaseKey in this.phaseOptions) return this.phaseOptions[this.phaseKey];
				return null;
			},
			showingFirst() {
				return (this.table.currentPage - 1) * this.table.itemsPerPage + 1
			},
			showingLast() {
				var max = (this.table.currentPage - 1) * this.table.itemsPerPage + this.table.itemsPerPage
				return max > this.table.total ? this.table.total : max;
			},
			advancedFilterProjectNumber() {
				return this.advancedFilter.projectNumber;
			},
			advancedFilterProjectName() {
				return this.advancedFilter.projectName;
			},
			advancedFilterDam() {
				return this.advancedFilter.dam;
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get',
			advancedFilterProjectNumber: _.debounce(function (query) { this.find('projectNumber', query) }, 500),
			advancedFilterProjectName: _.debounce(function (query) { this.find('projectName', query) }, 500),
			advancedFilterDam: _.debounce(function (query) { this.findDams('nid_id', query) }, 500)
		},
		methods: {
			getDb() {
				let sort = this.table.reverse ? this.table.sort + '_desc' : this.table.sort;
				let filter = this.table.filter !== null ? `&filter=${encodeURIComponent(this.table.filter)}` : '';

				let filterComplex = '';
				let filterValues = [];

				let stringFields = ['dam', 'projectNumber', 'projectName'];
				let twoPartFields = ['reportDate'];

				for (let f of stringFields) {
					if (!this.isNullOrEmpty(this.advancedFilter[f])) {
						filterValues.push(`${f}:${this.advancedFilter[f]}`);
					}
				}

				for (let f of twoPartFields) {
					if (!this.isNullOrEmpty(this.advancedFilter[f].start) || !this.isNullOrEmpty(this.advancedFilter[f].end)) {
						let s = this.advancedFilter[f].start;
						let e = this.advancedFilter[f].end;
						s = s === null ? 'any' : s;
						e = e === null ? 'any' : e;
						filterValues.push(`${f}:${s},${e}`);
					}
				}

				if (filterValues.length > 0) filterComplex = '&filterComplex=' + filterValues.join('|');

				return this.$http.get(`${this.apiUrl}/${this.phaseKey}?sort=${sort}&page=${this.table.currentPage}&perPage=${this.table.itemsPerPage}${filter}${filterComplex}`, this.getTokenHeader());
			},
			async filterChg() {
				this.table.currentPage = 1;
				_.debounce(await this.get(), 500);
			},
			async pageChg(page) {
				this.table.currentPage = page;
				await this.get();
			},
			async sortChg(ctx) {
				this.table.sort = ctx.sortBy;
				this.table.reverse = ctx.sortDesc;
				this.table.specialSort = undefined;

				await this.get();
			},
			async get() {
				this.page.errors = [];
				this.table.loading = true;

				if (this.phaseDescription === null) this.page.errors.push('Invalid phase key.');
				else {
					try {
						const response = await this.getDb();
						this.log(response.data);

						if (response.data.items.length == 0 && this.table.currentPage > 1) {
							this.table.currentPage--;
							await this.get();
						}

						this.items = response.data.items;
						this.table.total = response.data.total;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.table.loading = false;
				this.page.loading = false;
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async findDams(field, query) {
				try {
					const response = await this.$http.get(`/dams/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options.dam = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async clearFilter() {
				this.table.filter = null;
				await this.get();
			},
			async clearAdvancedFilter() {
				this.advancedFilter = {
					dam: null,
					projectNumber: null,
					projectName: null,
					reportDate: {
						start: null,
						end: null
					},
					options: {
						projectNumber: [],
						projectName: [],
						dam: []
					}
				};

				await this.get();
			},
			async editSubItem(id) {
				await this.getModal(id);
				this.modal.show = true;
			},
			createSubItem() {
				this.resetModal();
				this.modal.show = true;
			},
			async modalSaved() {
				this.modal.show = false;
				this.resetModal();
				await this.get();
			},
			cancelModal() {
				this.modal.show = false;
				this.resetModal();
			},
			resetModal() {
				this.modal.item = {
					id: null,
					name: this.phaseKey,
					include: true,
					reportDate: null
				}
				this.modal.project = null;
				this.modal.isUpdate = false;
			},
			async getModal(id) {
				this.modal.loading = true;
				this.modal.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.phaseKey}/${id}`, this.getTokenHeader());
					this.log(response.data);
					this.modal.item = response.data.item;
					this.modal.project = response.data.project;
					this.modal.isUpdate = true;
				} catch (error) {
					this.modal.errors = this.logError(error);
				}

				this.modal.loading = false;
			}
		}
	}
</script>