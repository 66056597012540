<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col v-for="field in fields" :key="field.name" :md="field.fullWidth ? 12 : 6">
					<b-form-group :label="field.label + (field.required ? ' (required)' : '')" :invalid-feedback="requiredFeedback($v.item[field.name])">
						<b-form-input :type="field.type === 'text'? 'text': 'number'"
									  :required="field.required" :step="field.type === 'decimal' ? 'any' : '1'"
									  v-model.trim="$v.item[field.name].$model" :state="getValidState($v.item[field.name])" />
					</b-form-group>
				</b-col>
			</b-row>

			<div v-if="checkboxFields.length > 0">
				<hr class="my-4" />
				<b-row>
					<b-col md="4" v-for="field in checkboxFields" :key="field.name">
						<b-form-checkbox v-model.trim="$v.item[field.name].$model" :state="getValidState($v.item[field.name])">{{field.label}}</b-form-checkbox>
					</b-col>
				</b-row>
			</div>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Upload Dams" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';

	export default {
		name: 'DamForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				apiUrl: 'dams',
				editRoute: 'DamEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				fields: [
					{ name: 'nid_id', label: 'Nid_id', type: 'text', required: true },
					{ name: 'fed_id', label: 'Fed_id', type: 'text', required: false },
					{ name: 'dam_name', label: 'Dam name', type: 'text', required: true, fullWidth: true },
					{ name: 'other_names', label: 'Other names', type: 'text', required: false },
					{ name: 'former_names', label: 'Former names', type: 'text', required: false },
					{ name: 'lat_deg', label: 'Lat_deg', type: 'decimal', required: true },
					{ name: 'long_deg', label: 'Long_deg', type: 'decimal', required: true },
					{ name: 'geodetic_loc', label: 'Geodetic_loc', type: 'text', required: false },
					{ name: 'county', label: 'County', type: 'text', required: true },
					{ name: 'stream', label: 'Stream', type: 'text', required: false },
					{ name: 'nearest_town', label: 'Nearest_town', type: 'text', required: false },
					{ name: 'dist_town', label: 'Dist_town', type: 'decimal', required: false },
					{ name: 'owner_name', label: 'Owner_name', type: 'text', required: false },
					{ name: 'owner_type', label: 'Owner_type', type: 'text', required: false },
					{ name: 'dam_dsgnr', label: 'Dam_dsgnr', type: 'text', required: false },
					{ name: 'nfdfp', label: 'Nfdfp', type: 'text', required: false },
					{ name: 'dam_type', label: 'Dam_type', type: 'text', required: false },
					{ name: 'core', label: 'Core', type: 'text', required: false },
					{ name: 'fndn', label: 'Fndn', type: 'text', required: false },
					{ name: 'purposes', label: 'Purposes', type: 'text', required: false },
					{ name: 'yr_comp', label: 'Yr_comp', type: 'integer', required: false },
					{ name: 'yr_mod', label: 'Yr_mod', type: 'integer', required: false },
					{ name: 'dam_len', label: 'Dam_len', type: 'decimal', required: false },
					{ name: 'dam_ht', label: 'Dam_ht', type: 'decimal', required: false },
					{ name: 'str_ht', label: 'Str_ht', type: 'decimal', required: false },
					{ name: 'hydr_ht', label: 'Hydr_ht', type: 'decimal', required: false },
					{ name: 'max_disc', label: 'Max_disc', type: 'decimal', required: false },
					{ name: 'max_stor', label: 'Max_stor', type: 'decimal', required: false },
					{ name: 'norm_stor', label: 'Norm_stor', type: 'decimal', required: false },
					{ name: 'surf_a', label: 'Surf_a', type: 'decimal', required: false },
					{ name: 'da', label: 'Da', type: 'decimal', required: false },
					{ name: 'nrcs_haz', label: 'Nrcs_haz', type: 'text', required: false },
					{ name: 'spwy_w', label: 'Spwy_w', type: 'decimal', required: false },
					{ name: 'auth', label: 'Auth', type: 'text', required: false },
					{ name: 'wshed_no', label: 'Wshed_no', type: 'integer', required: false },
					{ name: 'wshed_name', label: 'Wshed_name', type: 'text', required: false },
					{ name: 'serv_life', label: 'Serv_life', type: 'integer', required: false },
					{ name: 'pop_risk', label: 'Pop_risk', type: 'text', required: false },
					{ name: 'pop_acc', label: 'Pop_acc', type: 'text', required: false },
					{ name: 'dsgn_haz', label: 'Dsgn_haz', type: 'text', required: false },
					{ name: 'sed_stor', label: 'Sed_stor', type: 'decimal', required: false },
					{ name: 'fld_stor', label: 'Fld_stor', type: 'decimal', required: false },
					{ name: 'sur_stor', label: 'Sur_stor', type: 'decimal', required: false },
					{ name: 'oth_stor', label: 'Oth_stor', type: 'decimal', required: false },
					{ name: 'ps_type', label: 'Ps_type', type: 'text', required: false },
					{ name: 'as1_type', label: 'As1_type', type: 'text', required: false },
					{ name: 'as2_type', label: 'As2_type', type: 'text', required: false },
					{ name: 'as3_type', label: 'As3_type', type: 'text', required: false },
					{ name: 'cond_ht', label: 'Cond_ht', type: 'decimal', required: false },
					{ name: 'cond_w', label: 'Cond_w', type: 'decimal', required: false },
					{ name: 'no_cond', label: 'No_cond', type: 'decimal', required: false },
					{ name: 'ps_disch', label: 'Ps_disch', type: 'decimal', required: false },
					{ name: 'river_basin', label: 'River_basin', type: 'text', required: false },
					{ name: 'status', label: 'Status', type: 'text', required: false },
					{ name: 'swcd', label: 'Swcd', type: 'integer', required: false },
					{ name: 'repairType', label: 'Repair Type', type: 'text', required: false },
					{ name: 'repairNeedEstEngrServices', label: 'Repair Need Est. Engr. Services', type: 'decimal', required: false },
					{ name: 'repairNeedEstConstruction', label: 'Repair Need Est. Construction', type: 'decimal', required: false },
					{ name: 'repairNeedCostTotal', label: 'Repair Need Cost Total', type: 'decimal', required: false },
					{ name: 'dateRepairCompleted', label: 'Date Repair Completed', type: 'text', required: false },
					{ name: 'finalRepairCostStateFunds', label: 'Final Repair Cost State Funds', type: 'decimal', required: false },
					{ name: 'finalRepairCostOtherFunds', label: 'Final Repair Cost Other Funds', type: 'decimal', required: false },
					{ name: 'finalRepairCostTotal', label: 'Final Repair Cost Total', type: 'decimal', required: false },
					{ name: 'comments', label: 'Comments', type: 'text', required: false },
					{ name: 'yearEvalLifeExpired', label: 'YearEvalLifeExpired', type: 'integer', required: false }
				],
				checkboxFields: [
					{ name: 'cool_water', label: 'Cool_water', type: 'checkbox', required: false },
					{ name: 'tceq_unsafe', label: 'Tceq_unsafe', type: 'checkbox', required: false }
				]
			}
		},
		validations() {
			let item = {};
			for (let field of this.fields) {
				if (field.type === 'decimal') {
					if (field.required) item[field.name] = { required, decimal };
					else item[field.name] = { decimal };
				} else if (field.type === 'integer') {
					if (field.required) item[field.name] = { required, integer };
					else item[field.name] = { integer };
				} else if (field.type === 'text') {
					if (field.required) item[field.name] = { required };
					else item[field.name] = {};
				} else {
					item[field.name] = {};
				}				
			}
			for (let field of this.checkboxFields) {
				item[field.name] = {};
			}
			return { item: item };
		},
		methods: {
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;
				this.logValidations();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
				if (this.$v.$invalid) {
					for (let field of this.fields) {
						let invalid = this.$v.item[field.name].$invalid;
						this.log(`${field.name}: ${invalid}`);
					}
				}
			}
		}
	}
</script>
