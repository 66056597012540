<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-form-group label="Project" :invalid-feedback="requiredFeedback($v.query.project)">
				<b-typeahead v-model.trim="$v.query.project.$model" required placeholder="Type to search projects by number, name, or dam..."
							 :data="options.projects == null ? [] : options.projects"
							 :serializer="s => s.name" @hit="item.applicationId = $event.id;"
							 :max-matches="100" :minMatchingChars="1">
				</b-typeahead>
			</b-form-group>

			<b-row>
				<b-col md>
					<b-form-group label="Engineering Estimate for Construction Activity" :invalid-feedback="requiredFeedback($v.item.bidEngEst)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.bidEngEst.$model" :state="getValidState($v.item.bidEngEst)" />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Date of Engineering Estimate" :invalid-feedback="requiredFeedback($v.item.bidEngEstDate)">
						<b-form-input type="date" v-model.trim="$v.item.bidEngEstDate.$model" :state="getValidState($v.item.bidEngEstDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Notes Regarding Engineering Estimate" :invalid-feedback="requiredFeedback($v.item.bidEngEstNotes)" class="mb-4">
				<b-form-textarea v-model.trim="$v.item.bidEngEstNotes.$model" :state="getValidState($v.item.bidEngEstNotes)" rows="3"></b-form-textarea>
			</b-form-group>

			<b-row>
				<b-col md="6">
					<b-form-group label="Base Contract Amount" :invalid-feedback="requiredFeedback($v.item.baseContractAmount)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.baseContractAmount.$model" :state="getValidState($v.item.baseContractAmount)" />
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<b-form-group label="State Percent of Construction Cost-Share" :invalid-feedback="requiredFeedback($v.item.stateCostSharePercent)">
						<b-input-group append="%">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.stateCostSharePercent.$model" :state="getValidState($v.item.stateCostSharePercent)" />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="State Amount of Construction Cost-Share">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any" v-model.number="stateCostShareAmount" readonly />
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<b-form-group label="In-Kind Percent Paid to Sponsors" :invalid-feedback="requiredFeedback($v.item.sponsorAdminCostSharePercent)">
						<b-input-group append="%">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.sponsorAdminCostSharePercent.$model" :state="getValidState($v.item.sponsorAdminCostSharePercent)" />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="In-Kind Amount Paid to Sponsors">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any" v-model.number="sponsorAdminCostShareAmount" readonly />
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<b-form-group label="Total State Funds">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any" v-model.number="totalStateFunds" readonly />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Sponsor Responsible Cost-Share Amount">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any" v-model.number="sponsorResponsible" readonly />
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Agreement/PO#" :invalid-feedback="requiredFeedback($v.item.agreementPoNum)">
				<b-typeahead v-model.trim="$v.item.agreementPoNum.$model"
							 :data="options.agreementPoNum"
							 :max-matches="100" :minMatchingChars="1">
				</b-typeahead>
			</b-form-group>

			<b-row>
				<b-col md>
					<b-form-group label="Accepted Final Construction Bid" :invalid-feedback="requiredFeedback($v.item.finalBid)">
						<b-input-group append="%">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.finalBid.$model" :state="getValidState($v.item.finalBid)" />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Date of Accepted Bid" :invalid-feedback="requiredFeedback($v.item.finalBidDate)">
						<b-form-input type="date" v-model.trim="$v.item.finalBidDate.$model" :state="getValidState($v.item.finalBidDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<b-form-group label="Inspecting Agent" :invalid-feedback="requiredFeedback($v.item.inspectingAgent)">
						<b-typeahead v-model.trim="$v.item.inspectingAgent.$model"
									 :data="options.inspectingAgent"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Final Construction Cost" :invalid-feedback="requiredFeedback($v.item.finalCost)">
						<b-input-group append="%">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.finalCost.$model" :state="getValidState($v.item.finalCost)" />
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Date of As-Built Plans Received" :invalid-feedback="requiredFeedback($v.item.asBuiltReceivedDate)">
				<b-form-input type="date" v-model.trim="$v.item.asBuiltReceivedDate.$model" :state="getValidState($v.item.asBuiltReceivedDate)"></b-form-input>
			</b-form-group>

			<b-form-group label="Notes Regarding As-Built Plans" :invalid-feedback="requiredFeedback($v.item.asBuiltNotes)" class="mb-4">
				<b-form-textarea v-model.trim="$v.item.asBuiltNotes.$model" :state="getValidState($v.item.asBuiltNotes)" rows="3"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, decimal, integer } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrConstructionForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			project: {
				type: Object,
				required: false
			},
			agreement: {
				type: Object,
				required: false
			}
		},
		data() {
			return {
				apiUrl: 'srconstructions',
				editRoute: 'SrConstructionEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					projects: [],
					agreementPoNum: [],
					inspectingAgent: []
				},
				query: {
					project: ''
				}
			}
		},
		computed: {
			queryProject() {
				return this.query.project;
			},
			itemAgreementPoNum() {
				return this.item.agreementPoNum;
			},
			itemInspectingAgent() {
				return this.item.inspectingAgent;
			},
			stateCostShareAmount() {
				return this.item.baseContractAmount * this.item.stateCostSharePercent / 100;
			},
			sponsorAdminCostShareAmount() {
				return this.item.baseContractAmount * this.item.sponsorAdminCostSharePercent / 100;
			},
			totalStateFunds() {
				return this.stateCostShareAmount + this.sponsorAdminCostShareAmount;
			},
			sponsorResponsible() {
				return this.item.baseContractAmount - this.stateCostShareAmount;
			}
		},
		validations: {
			item: {
				applicationId: { required, integer },
				bidEngEst: { required, decimal },
				bidEngEstDate: {},
				bidEngEstNotes: {},
				baseContractAmount: { required, decimal },
				stateCostSharePercent: { required, decimal },
				sponsorAdminCostSharePercent: { required, decimal },
				agreementPoNum: {},
				finalBid: { required, decimal },
				finalBidDate: {},
				inspectingAgent: {},
				finalCost: { required, decimal },
				asBuiltReceivedDate: {},
				asBuiltNotes: {},
			},
			query: {
				project: { required }
			}
		},
		watch: {
			queryProject: _.debounce(function (query) { this.findProjects(query) }, 500),
			itemAgreementPoNum: _.debounce(function (query) { this.find('agreementPoNum', query) }, 500),
			itemInspectingAgent: _.debounce(function (query) { this.find('inspectingAgent', query) }, 500)
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				if (!this.isNullOrEmpty(this.project)) {
					this.query.project = this.project.name;
					this.item.applicationId = this.project.id;
				}

				if (this.isUpdate) {
					this.item.bidEngEstDate = this.dateForForm(this.item.bidEngEstDate);
					this.item.finalBidDate = this.dateForForm(this.item.finalBidDate);
					this.item.asBuiltReceivedDate = this.dateForForm(this.item.asBuiltReceivedDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.applicationId}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async findProjects(query) {
				try {
					//const response = await this.$http.get(`/srprojects/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					const response = await this.$http.post(`/srprojects/find-id-post`, { value: query }, this.getTokenHeader());
					this.options.projects = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async findAgreements(query) {
				try {
					const response = await this.$http.get(`/sragreements/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					this.options.agreements = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>
