<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<div v-if="!page.saveConfirmShow">
				<b-form @submit.prevent="save">
					<b-form-group label="Project/Dam" :invalid-feedback="requiredFeedback($v.item.damId)">
						<b-form-select v-model.trim="$v.item.damId.$model" :state="getValidState($v.item.damId)" :options="dams">
							<template #first>
								<b-form-select-option :value="null" disabled>-- Select a project/dam --</b-form-select-option>
							</template>
						</b-form-select>
					</b-form-group>

					<b-row>
						<b-col md>
							<b-form-group label="Invoice #" :invalid-feedback="requiredFeedback($v.item.invoiceNumber)">
								<b-form-input type="text" required
											  v-model.trim="$v.item.invoiceNumber.$model" :state="getValidState($v.item.invoiceNumber)" />
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Invoiced Date" :invalid-feedback="requiredFeedback($v.item.invoicedDate)">
								<b-form-input type="date" v-model.trim="$v.item.invoicedDate.$model" :state="getValidState($v.item.invoicedDate)"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>

					<hr class="mb-4" />

					<b-row>
						<b-col md>
							<b-form-group label="State Portion of Construction Cost" :invalid-feedback="requiredFeedback($v.item.stateConstructionCost)">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any"
												  v-model.trim.number="$v.item.stateConstructionCost.$model" :state="getValidState($v.item.stateConstructionCost)" />
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="State Portion of Land Rights Requested" :invalid-feedback="requiredFeedback($v.item.stateLandRights)">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any"
												  v-model.trim.number="$v.item.stateLandRights.$model" :state="getValidState($v.item.stateLandRights)" />
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<div class="mb-3">
						<b-form-checkbox v-model.trim="$v.item.wasStateRetainageRequested.$model">
							Was retainage requested for payment within the state reimbursement?
						</b-form-checkbox>
					</div>
					<div class="mb-3">
						<b-form-checkbox v-model.trim="$v.item.wasAdminRequestedInAdvance.$model">
							Was reimbursement of admin cost requested in advance of construction cost?
						</b-form-checkbox>
					</div>

					<b-form-group label="State Portion of Admin Cost" :invalid-feedback="requiredFeedback($v.item.stateAdminCost)">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any"
										  v-model.trim.number="$v.item.stateAdminCost.$model" :state="getValidState($v.item.stateAdminCost)" />
						</b-input-group>
					</b-form-group>

					<hr class="my-4" />

					<b-row>
						<b-col md>
							<b-form-group label="Federal Portion of Construction Cost" :invalid-feedback="requiredFeedback($v.item.federalConstructionCost)">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any"
												  v-model.trim.number="$v.item.federalConstructionCost.$model" :state="getValidState($v.item.federalConstructionCost)" />
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Federal Portion of Land Rights Requested" :invalid-feedback="requiredFeedback($v.item.federalLandRights)">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any"
												  v-model.trim.number="$v.item.federalLandRights.$model" :state="getValidState($v.item.federalLandRights)" />
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<div class="mb-3">
						<b-form-checkbox v-model.trim="$v.item.wasFederalRetainageRequested.$model">
							Was retainage requested for payment within the federal reimbursement?
						</b-form-checkbox>
					</div>

					<hr class="my-4" />

					<b-form-group label="Sponsor Portion of Construction Cost" :invalid-feedback="requiredFeedback($v.item.sponsorConstructionCost)">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any"
										  v-model.trim.number="$v.item.sponsorConstructionCost.$model" :state="getValidState($v.item.sponsorConstructionCost)" />
						</b-input-group>
					</b-form-group>

					<div class="mt-4 mb-3 d-flex align-items-center">
						<save-button :saving="page.saving" class="mr-2" />
						<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
						<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
							<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
							You have errors in the form.
						</div>
					</div>
				</b-form>
			</div>
			<div v-else>
				<b-form-group label="Add a note to describe changes?" description="Optional">
					<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
				</b-form-group>

				<div class="my-3">
					<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
					<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';

	export default {
		name: 'SrAgreementReimbursementForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			dams: {
				type: Array,
				required: true
			}
		},
		data() {
			return {
				apiUrl: 'sragreementreimbursements',
				page: {
					loading: false,
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				}
			}
		},
		validations: {
			item: {
				agreementId: { required, integer },
				damId: { required, integer },
				invoiceNumber: { required },
				invoicedDate: { required },
				stateConstructionCost: { required, decimal },
				wasStateRetainageRequested: {},
				stateLandRights: { required, decimal },
				wasAdminRequestedInAdvance: {},
				stateAdminCost: { required, decimal },
				federalConstructionCost: { required, decimal },
				wasFederalRetainageRequested: {},
				federalLandRights: { required, decimal },
				sponsorConstructionCost: { required, decimal }
			}
		},
		created() {
			this.get();
		},
		methods: {
			async get() {
				if (this.isUpdate) {
					this.item.invoicedDate = this.dateForForm(this.item.invoicedDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;
				this.logValidations();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.page.errors.length < 1) {
						if (this.isUpdate) {
							this.page.saveConfirmShow = true;
						} else {
							try {
								const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
								this.log(response.data);

								this.$emit('change');
							} catch (error) {
								if (this.isApiUnauthorized(error)) this.page.showLogin = true;
								else this.page.errors = this.logError(error);
							}
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			logValidations() {
				this.log(this.$v);
			},
			cancel() {
				this.$emit('cancel');
			}
		}
	}
</script>
