<template>
	<auth-container :page="page">
		<h2 class="mb-4">Reimbursement Request Information</h2>

		<reimbursement-form :item="item"></reimbursement-form>
	</auth-container>
</template>

<script>
	import ReimbursementForm from './ReimbursementForm.vue';
	import moment from 'moment';
	export default {
		name: 'ReimbursementCreate',
		components: {
			ReimbursementForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {}
			}
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				this.item = {
					id: 0,
					fiscalYear: this.currentFiscalYear(),
					sponsorId: '',
					dateReceived: moment().format(),
					dateRecorded: moment().format(),
					reimbursementNumber: null,
					proposalId: null,
					invoiceDate: moment().format(),
					notes: null
				}
			}
		}
	}
</script>