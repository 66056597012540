import Vue from 'vue';

import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import exportingInit from 'highcharts/modules/exporting';
import exportDataInit from 'highcharts/modules/export-data';
import offlineExportInit from 'highcharts/modules/offline-exporting';
import mapInit from 'highcharts/modules/map';

Vue.use(HighchartsVue);
Highcharts.setOptions({
	chart: {
		style: {
			fontFamily: '"Roboto", Arial, Helvetica, sans-serif'
		}
	},
	lang: {
		thousandsSep: '\u002c'
	}
});



HighchartsMore(Highcharts);
exportingInit(Highcharts);
exportDataInit(Highcharts);
offlineExportInit(Highcharts);
mapInit(Highcharts);


