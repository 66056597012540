<template>
	<div>
		<auth-container v-if="$route.name === 'ProposalList'" :page="page">
			<h2 class="mb-4">Proposals</h2>

			<div class="mx-auto">
				<b-row class="mb-3" align-v="end">
					<b-col lg="6">
						<b-form inline>
							<b-input-group>
								<b-form-input v-model="table.filter" placeholder="Search..." @input="filterChg"></b-form-input>

								<b-input-group-append class="pointer mr-2">
									<b-input-group-text @click="clearFilter">
										<font-awesome-icon :icon="['fas', 'times']" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>

							<b-input-group prepend="Fiscal Year">
								<b-form-select v-model.number="advancedFilter.fiscalYear" :options="fiscalYear.options" @change="get(false)" class="mr-2">
									<template #first>
										<b-form-select-option :value="null">-- All --</b-form-select-option>
									</template>
								</b-form-select>
							</b-input-group>

							<b-button variant="primary" type="button" @click="table.showAdvancedFilter = !table.showAdvancedFilter">Advanced Filter</b-button>
						</b-form>
					</b-col>
					<b-col lg="6" class="text-right">
						<div class="ml-auto">
							Showing {{showingFirst}} - {{showingLast}}
							of {{table.total}}
						</div>
					</b-col>
				</b-row>

				<b-collapse v-model="table.showAdvancedFilter" class="my-3">
					<b-card bg-variant="light">
						<b-row>
							<b-col lg="4">
								<div class="form-group row">
									<label for="advancedFilter.dam" class="col-lg-5 col-form-label">Dam NID_ID</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.dam"
													 v-model="advancedFilter.dam"
													 :data="advancedFilter.options.dam"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.sponsor" class="col-lg-5 col-form-label">Sponsor #</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.sponsor"
													 v-model="advancedFilter.sponsor"
													 :data="advancedFilter.options.sponsor"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.isActive" class="col-lg-5 col-form-label">Active?</label>
									<div class="col-lg-7">
										<b-form-select v-model="advancedFilter.isActive" id="advancedFilter.isActive">
											<b-form-select-option :value="null">-</b-form-select-option>
											<b-form-select-option value="y">Active</b-form-select-option>
											<b-form-select-option value="n">Inactive</b-form-select-option>
										</b-form-select>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.isCanceled" class="col-lg-5 col-form-label">Canceled?</label>
									<div class="col-lg-7">
										<b-form-select v-model="advancedFilter.isCanceled" id="advancedFilter.isCanceled">
											<b-form-select-option :value="null">-</b-form-select-option>
											<b-form-select-option value="y">Canceled</b-form-select-option>
											<b-form-select-option value="n">Not Canceled</b-form-select-option>
										</b-form-select>
									</div>
								</div>
							</b-col>
							<b-col lg>
								<div class="form-group row">
									<label for="advancedFilter.dateReceived.start" class="col-lg-3 col-form-label">Date Received Range</label>
									<div class="col-lg-9">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter.dateReceived.start" v-model="advancedFilter.dateReceived.start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.dateReceived.start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter.dateReceived.end" v-model="advancedFilter.dateReceived.end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.dateReceived.end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.dateRecorded.start" class="col-lg-3 col-form-label">Date Recorded Range</label>
									<div class="col-lg-9">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter.dateRecorded.start" v-model="advancedFilter.dateRecorded.start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.dateRecorded.start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter.dateRecorded.end" v-model="advancedFilter.dateRecorded.end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.dateRecorded.end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.dateApproved.start" class="col-lg-3 col-form-label">Date Approved Range</label>
									<div class="col-lg-9">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter.dateApproved.start" v-model="advancedFilter.dateApproved.start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.dateReceived.start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter.dateApproved.end" v-model="advancedFilter.dateApproved.end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.dateApproved.end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
						<hr class="my-3" />
						<div>
							<b-button variant="primary" type="button" @click="get(false)" class="mr-2">Apply</b-button>
							<b-button variant="secondary" type="button" @click="clearAdvancedFilter" class="mr-2">Clear</b-button>
							<b-button variant="secondary" type="button" @click="table.showAdvancedFilter = false">Close</b-button>
						</div>
					</b-card>
				</b-collapse>

				<b-table striped responsive table-class="table-fixed-height border-bottom table-grid" sticky-header="60vh"
						 small show-empty
						 :items="items" :busy="table.loading"
						 :fields="table.fields"
						 :no-local-sorting="true"
						 :sort-by.sync="table.sort"
						 :sort-desc.sync="table.reverse"
						 @sort-changed="sortChg">
					<template #table-busy>
						<div class="text-center my-4">
							<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
						</div>
					</template>
					<template #cell(edit)="data">
						<router-link :to="`${data.item.id}`" append>
							<font-awesome-icon :icon="['fas', 'edit']" />
						</router-link>
					</template>
					<template #cell(sponsorId)="data">
						<router-link :to="`/om/sponsors/${data.value}`">{{data.value}}</router-link>
					</template>
					<template #empty>
						<h4>No records found.</h4>
					</template>
					<template #emptyfiltered>
						<h4>No records found matching "{{table.filter}}".</h4>
					</template>
				</b-table>
			</div>

			<fixed-action-bar>
				<router-link v-if="!isReadOnly" to="create" append class="btn btn-success mr-2">
					Create
				</router-link>
				<b-button variant="primary" @click="downloadData.show = true" class="mr-2">Download CSV</b-button>
				<b-button v-if="isAuthorized(roleNames.superAdmin)" variant="primary" @click="page.showModal = true" class="mr-2">Import</b-button>
				<b-pagination class="ml-auto mb-0" v-if="table.total > table.itemsPerPage" size="md" :total-rows="table.total" v-model="table.currentPage" :per-page="table.itemsPerPage" @change="pageChg" />
			</fixed-action-bar>

			<b-modal v-model="page.showModal" title="Upload Proposals" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<p class="mt-3">
					<strong>Recommended developer upload only. Existing proposal numbers already in the database will not be modified.</strong>
				</p>
				<p>
					Upload a csv of proposals with the following columns (headers on first line):<br />
					<code>
						SponsorNumber, DateReceived, ProposalNumber, DateRecorded, DateApproved, ProposalStatus, ItemStatus,
						Nid_id, PracticeCode, EstimatedUnits, EstimatedCost, ApprovedAmount, IntendedStartDate, IntendedEndDate, Notes, IsCanceled
					</code>.
				</p>

				<div slot="modal-footer">
					<b-button type="button" variant="primary" @click="page.showModal = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="downloadData.show" title="Export Proposals" size="md" hide-footer centered>
				<error-list :errors="downloadData.errors"></error-list>

				<div class="form-inline mt-2 mb-4">
					<b-input-group prepend="Fiscal Year" class="mr-2">
						<b-form-select v-model="downloadData.fiscalYear" :options="$parent.fiscalYear.options">
							<template #first>
								<b-form-select-option :value="null" disabled>Select a year</b-form-select-option>
							</template>
						</b-form-select>
					</b-input-group>

					<json-to-csv :api-url="`omproposals/export/${downloadData.fiscalYear}`" :field-labels="downloadData.fieldLabels" 
						:filename="`fy${downloadData.fiscalYear}-proposals`" @done="downloadData.show = false" />
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import _ from 'underscore';

	export default {
		name: 'ProposalList',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				apiUrl: 'omproposals',
				page: {
					errors: [],
					loading: true,
					showLogin: false,
					saveErrors: [],
					showModal: false
				},
				dropzoneOptions: {
					url: '/api/omproposals/upload/',
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'fiscalYear', label: 'FY', sortable: true },
						{ key: 'sponsorId', label: 'Sponsor #', sortable: true },
						{ key: 'dateReceived', label: 'Date Received', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY h:mm a') } },
						{ key: 'proposalNumber', label: 'Proposal #', sortable: true },
						{ key: 'estimatedCost', label: 'Est. Cost', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'approvedAmount', label: 'Approved O&M', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'approvedAdminAmount', label: 'Approved Admin', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'isActive', label: 'Active', sortable: true, class: 'text-center', formatter: (value) => { return value ? '✔' : '-'; } },
						{ key: 'isCanceled', label: 'Canceled', sortable: true, class: 'text-center', formatter: (value) => { return value ? '✔' : '-'; } }
					],
					sort: 'dateReceived',
					reverse: false,
					itemsPerPage: 100,
					filter: null,
					currentPage: 1,
					total: 0,
					loading: false,
					showAdvancedFilter: false
				},
				items: [],
				advancedFilter: {
					dam: null,
					fiscalYear: null,
					sponsor: null,
					dateReceived: {
						start: null,
						end: null
					},
					dateRecorded: {
						start: null,
						end: null
					},
					dateApproved: {
						start: null,
						end: null
					},
					isActive: null,
					isCanceled: null,
					options: {
						fiscalYear: [],
						sponsor: [],
						dam: []
					}
				},
				downloadData: {
					errors: [],
					show: false,
					fiscalYear: null,
					fieldLabels: {
						sponsorId : 'Sponsor',
						dateReceived: 'Date-Received',
						proposalNumber: 'Proposal-#',
						dateRecorded: 'Date-Recorded',
						dateApproved: 'Date-Approved',
						proposalStatus: 'Proposal-Status',
						itemStatus: 'Item-Status',
						nid_id: 'NID_ID',
						practiceCode: 'Practice-Code',
						estimatedUnits: 'Estimated-Units',
						estimatedCost: 'Estimated-Cost',
						approvedAmount: 'Approved-O&M-Amount',
						approvedAdminAmount: 'Approved-Admin-Amount',
						intendedStartDate: 'Intended-Start-Date',
						intendedEndDate: 'Intended-Completion-Date',
						ninetyDayDeadlineDate: '90-Day-Deadline-Date',
						reimbursementNumber: 'Reimbursement Number',
						notes: 'Notes'
					}
				},
				fiscalYear: {
					options: []
				}
			}
		},
		computed: {
			showingFirst() {
				return (this.table.currentPage - 1) * this.table.itemsPerPage + 1
			},
			showingLast() {
				var max = (this.table.currentPage - 1) * this.table.itemsPerPage + this.table.itemsPerPage
				return max > this.table.total ? this.table.total : max;
			},
			advancedFilterFiscalYear() {
				return this.advancedFilter.fiscalYear;
			},
			advancedFilterSponsor() {
				return this.advancedFilter.sponsor;
			},
			advancedFilterDam() {
				return this.advancedFilter.dam;
			}
		},
		async created() {
			await this.get(true);
		},
		watch: {
			'$route': 'get',
			advancedFilterFiscalYear: _.debounce(function (query) { this.find('fiscalYear', query) }, 500),
			advancedFilterSponsor: _.debounce(function (query) { this.find('sponsor', query) }, 500),
			advancedFilterDam: _.debounce(function (query) { this.findDams('nid_id', query) }, 500)
		},
		methods: {
			getDb() {
				let sort = this.table.reverse ? this.table.sort + '_desc' : this.table.sort;
				let filter = this.table.filter !== null ? `&filter=${encodeURIComponent(this.table.filter)}` : '';

				let filterComplex = '';
				let filterValues = [];
				if (!this.isNullOrEmpty(this.advancedFilter.dam)) {
					filterValues.push(`dam:${this.advancedFilter.dam}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.fiscalYear)) {
					filterValues.push(`fiscalYear:${this.advancedFilter.fiscalYear}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.sponsor)) {
					filterValues.push(`sponsor:${this.advancedFilter.sponsor}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.isActive)) {
					filterValues.push(`isActive:${this.advancedFilter.isActive}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.isCanceled)) {
					filterValues.push(`isCanceled:${this.advancedFilter.isCanceled}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.dateReceived.start) || !this.isNullOrEmpty(this.advancedFilter.dateReceived.end)) {
					let s = this.advancedFilter.dateReceived.start;
					let e = this.advancedFilter.dateReceived.end;
					s = s === null ? 'any' : s;
					e = e === null ? 'any' : e;
					filterValues.push(`dateReceived:${s},${e}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.dateRecorded.start) || !this.isNullOrEmpty(this.advancedFilter.dateRecorded.end)) {
					let s = this.advancedFilter.dateRecorded.start;
					let e = this.advancedFilter.dateRecorded.end;
					s = s === null ? 'any' : s;
					e = e === null ? 'any' : e;
					filterValues.push(`dateRecorded:${s},${e}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.dateApproved.start) || !this.isNullOrEmpty(this.advancedFilter.dateApproved.end)) {
					let s = this.advancedFilter.dateApproved.start;
					let e = this.advancedFilter.dateApproved.end;
					s = s === null ? 'any' : s;
					e = e === null ? 'any' : e;
					filterValues.push(`dateApproved:${s},${e}`);
				}

				if (filterValues.length > 0) filterComplex = '&filterComplex=' + filterValues.join('|');

				return this.$http.get(`${this.apiUrl}?sort=${sort}&page=${this.table.currentPage}&perPage=${this.table.itemsPerPage}${filter}${filterComplex}`, this.getTokenHeader());
			},
			async filterChg() {
				this.table.currentPage = 1;
				_.debounce(await this.get(false), 500);
			},
			async pageChg(page) {
				this.table.currentPage = page;
				await this.get(false);
			},
			async sortChg(ctx) {
				this.table.sort = ctx.sortBy;
				this.table.reverse = ctx.sortDesc;
				this.table.specialSort = undefined;

				await this.get(false);
			},
			async get(init = false) {
				this.page.errors = [];
				this.table.loading = true;

				try {
					if (init) {
						const responseF = await this.$http.get(`funds/list`, this.getTokenHeader());
						this.fiscalYear.options = responseF.data;
						if (this.fiscalYear.options.length > 0) {
							this.advancedFilter.fiscalYear = this.fiscalYear.options[0];
							this.downloadData.fiscalYear = this.fiscalYear.options[0];
						}
					}

					const response = await this.getDb();
					this.log(response.data);

					if (response.data.items.length == 0 && this.table.currentPage > 1) {
						this.table.currentPage--;
						await this.get();
					}

					this.items = response.data.items;
					this.table.total = response.data.total;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.table.loading = false;
				this.page.loading = false;
			},
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				await this.get();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async findDams(field, query) {
				try {
					const response = await this.$http.get(`/dams/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options.dam = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async clearFilter() {
				this.table.filter = null;
				await this.get();
			},
			async clearAdvancedFilter() {
				this.advancedFilter = {
					dam: null,
					//fiscalYear: null,
					sponsor: null,
					dateReceived: {
						start: null,
						end: null
					},
					dateRecorded: {
						start: null,
						end: null
					},
					dateApproved: {
						start: null,
						end: null
					},
					isActive: null,
					isCanceled: null,
					options: {
						fiscalYear: [],
						sponsor: [],
						dam: []
					}
				};

				await this.get(false);
			}
		}
	}
</script>