<template>
	<auth-container :page="page">
		<h2 class="mb-4">Map Layer Information</h2>

		<map-layer-form :item="item"></map-layer-form>
	</auth-container>
</template>

<script>
	import MapLayerForm from './MapLayerForm.vue';
	export default {
		name: 'MapLayerCreate',
		components: {
			MapLayerForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					layerName: null,
					name: null,
					label: null,
					geometry: null,
					additionalProperties: null
				}
			}
		}
	}
</script>