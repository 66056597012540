<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<div v-if="!page.saveConfirmShow">
				<b-form @submit.prevent="save">
					<div class="mb-2">
						<span class="mr-3">Status</span>
						<b-form-checkbox v-model.trim="$v.item.isActive.$model" button :button-variant="item.isActive ? 'success' : 'secondary'" class="mr-2">{{item.isActive ? 'Active' : 'Inactive'}}</b-form-checkbox>
					</div>

					<b-form-group label="Dam">
						<b-typeahead v-model="query.dam"
									 :data="options.dams"
									 :serializer="s => s.name" @hit="item.damId = $event.id"
									 :max-matches="100" :minMatchingChars="1">
							<template slot="append">
								<b-input-group-text @click="query.dam = ''" class="pointer">
									<font-awesome-icon :icon="['fas', 'times']" />
								</b-input-group-text>
							</template>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Practice Code">
						<b-typeahead v-model="query.practice"
									 :data="options.practices"
									 :serializer="s => s.name" @hit="getPractice($event.id)"
									 :max-matches="100" :minMatchingChars="1">
							<template slot="append">
								<b-input-group-text @click="clearPractice" class="pointer">
									<font-awesome-icon :icon="['fas', 'times']" />
								</b-input-group-text>
							</template>
							<template slot="suggestion" slot-scope="{ data, htmlText }">
								<div>
									<span v-html="htmlText"></span> - {{data.description}}
								</div>
							</template>
						</b-typeahead>
					</b-form-group>

					<b-card v-if="selectedPractice.show" class="my-3">
						<error-list :errors="selectedPractice.errors"></error-list>
						<div v-if="selectedPractice.errors.length < 1 && selectedPractice.item !== null" class="small">
							<div>
								<strong>{{selectedPractice.item.practiceCode}}</strong> - {{selectedPractice.item.description}}
							</div>
							<div>
								Cost: ${{selectedPractice.item.costPerUnit | number(2)}} / {{lower(selectedPractice.item.units)}}
							</div>
							<div v-if="!isNullOrEmpty(selectedPractice.item.comments)">
								Comments: {{selectedPractice.item.comments}}
							</div>


						</div>
					</b-card>
					<b-alert :show="selectedPractice.show && selectedPractice.errors.length < 1 && selectedPractice.item !== null && selectedPractice.existingProposals.length > 0" variant="warning" class="my-3">
						This dam already has active proposals for this practice. Please review before continuing:
						<ul class="mb-0">
							<li v-for="(ep, i) in selectedPractice.existingProposals" :key="i">
								<router-link :to="`/om/proposals/${ep.value}`" target="_blank">{{ep.text}}</router-link>
							</li>
						</ul>
					</b-alert>

					<b-row>
						<b-col md>
							<b-form-group label="Estimated Number of Units" :invalid-feedback="requiredFeedback($v.item.estimatedUnits)">
								<b-input-group>
									<b-form-input type="number" required step="any"
												  v-model.trim="$v.item.estimatedUnits.$model" :state="getValidState($v.item.estimatedUnits)" />


								</b-input-group>

							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Estimated Cost" :invalid-feedback="requiredFeedback($v.item.estimatedCost)">
								<b-input-group prepend="$">
									<b-form-input type="number" required step="any"
												  v-model.trim="$v.item.estimatedCost.$model" :state="getValidState($v.item.estimatedCost)" />


								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md>
							<b-form-group label="Approved O&amp;M Amount" :invalid-feedback="requiredFeedback($v.item.approvedAmount)">
								<b-input-group prepend="$">
									<b-form-input type="number" required step="any" @input="calcAdminAmount"
												  v-model.trim="$v.item.approvedAmount.$model" :state="getValidState($v.item.approvedAmount)" />

									<template #append>
										<b-button variant="secondary" @click="copyEstToApproved" title="Get from estimated cost"><font-awesome-icon :icon="['fas', 'paste']" /></b-button>
									</template>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md>
							<b-form-checkbox v-model.trim="$v.item.noAdminPayment.$model" @change="calcAdminAmount">Do not include admin. amount</b-form-checkbox>
						</b-col>
						<b-col md>
							<b-form-group label="Approved Admin Amount (5%)">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any" v-model="adminAmount" readonly />
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md>
							<b-form-group label="Intended Start Date" :invalid-feedback="requiredFeedback($v.item.intendedStartDate)">
								<b-form-input type="date" v-model.trim="$v.item.intendedStartDate.$model" :state="getValidState($v.item.intendedStartDate)"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Intended End Date" :invalid-feedback="requiredFeedback($v.item.intendedEndDate)">
								<b-form-input type="date" v-model.trim="$v.item.intendedEndDate.$model" :state="getValidState($v.item.intendedEndDate)"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>

					<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)">
						<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="3"></b-form-textarea>
					</b-form-group>

					<div class="my-3 d-flex align-items-center">
						<save-button :saving="page.saving" class="mr-2" />
						<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
						<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
							<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
							You have errors in the form.
						</div>
					</div>
				</b-form>
			</div>
			<div v-else>
				<b-form-group label="Add a note to describe changes?" description="Optional">
					<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
				</b-form-group>

				<div class="my-3">
					<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
					<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'ProposalActivityForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			dam: {
				type: Object,
				required: false
			},
			practice: {
				type: Object,
				required: false
			}
		},
		data() {
			return {
				apiUrl: 'omproposalactivities',
				page: {
					loading: true,
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					dams: [],
					practices: []
				},
				query: {
					dam: '',
					practice: ''
				},
				selectedPractice: {
					loading: false,
					errors: [],
					show: false,
					item: null,
					existingProposals: []
				},
				adminAmount: 0
			}
		},
		computed: {
			queryDam() {
				return this.query.dam;
			},
			queryPractice() {
				return this.query.practice;
			}
		},
		validations: {
			item: {
				proposalId: { required, integer },
				isActive: {},
				damId: { integer },
				practiceId: { integer },
				estimatedUnits: { required, decimal },
				estimatedCost: { required, decimal },
				approvedAmount: { required, decimal },
				intendedStartDate: {},
				intendedEndDate: {},
				notes: {},
				noAdminPayment: {}
			}
		},
		watch: {
			queryDam: _.debounce(function (query) { this.findDams(query) }, 500),
			queryPractice: _.debounce(function (query) { this.findPractices(query) }, 500)
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.log("hi");
				this.log(this.item);
				this.calcAdminAmount();
				if (!this.isNullOrEmpty(this.dam)) this.query.dam = this.dam.name;
				if (!this.isNullOrEmpty(this.practice)) {
					this.query.practice = this.practice.name;
					await this.getPractice(this.item.practiceId);
				}

				if (this.isUpdate) {
					this.item.intendedStartDate = this.dateForForm(this.item.intendedStartDate);
					this.item.intendedEndDate = this.dateForForm(this.item.intendedEndDate);
				}

				this.page.loading = false;
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;
				this.logValidations();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					//this.item.damId = await this.getDamId();
					//this.item.practiceId = await this.getPracticeId();
					if (this.page.errors.length < 1) {
						if (this.isUpdate) {
							this.page.saveConfirmShow = true;
						} else {
							try {
								const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
								this.log(response.data);

								this.$emit('change');
							} catch (error) {
								if (this.isApiUnauthorized(error)) this.page.showLogin = true;
								else this.page.errors = this.logError(error);
							}
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						//this.page.saveConfirmShow = false;
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			logValidations() {
				this.log(this.$v);
			},
			async findDams(query) {
				try {
					const response = await this.$http.get(`/dams/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					this.options.dams = !this.isNullOrEmpty(response.data) ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async findPractices(query) {
				try {
					const response = await this.$http.get(`/omactivities/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					this.options.practices = !this.isNullOrEmpty(response.data) ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			cancel() {
				this.$emit('cancel');
			},
			async getPractice(id) {
				this.item.practiceId = id;
				this.selectedPractice.show = true;
				this.selectedPractice.loading = true;
				this.selectedPractice.errors = [];

				try {
					const response = await this.$http.get(`omactivities/${id}?proposalId=${this.item.proposalId}&damId=${this.item.damId}`, this.getTokenHeader());
					this.log(response.data);
					this.selectedPractice.item = response.data.activity;
					this.selectedPractice.existingProposals = response.data.existingProposals;
				} catch (error) {
					this.selectedPractice.errors = this.logError(error);
				}

				this.selectedPractice.loading = false;
			},
			clearPractice() {
				this.query.practice = '';
				this.selectedPractice.item = null;
				this.selectedPractice.show = false;
			},
			calcEstimate() {
				if (this.selectedPractice.item !== null) {
					this.item.estimatedCost = this.selectedPractice.item.costPerUnit * this.item.estimatedUnits;
				}
			},
			calcAdminAmount() {
				this.adminAmount = this.item.noAdminPayment ? 0 : this.roundAsNumber(this.item.approvedAmount * 0.05, 2);
			},
			copyEstToApproved() {
				this.item.approvedAmount = this.item.estimatedCost;
				this.calcAdminAmount();
			}
		}
	}
</script>
