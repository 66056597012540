<template>
	<div>
		<auth-container v-if="$route.name === 'OmReportsList'" :page="page">
			<h2 class="mb-4">O&amp;M Reports</h2>

			<ul>
				<li><router-link to="/om/reports/proposalfunding">31.529.A funding report</router-link></li>
				<li><router-link to="/om/reports/practices">Practice unit cost, units performed, and trends</router-link></li>
			</ul>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'OmReportsList',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					noData: false
				}
			}
		}
	}
</script>