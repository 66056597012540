<template>
	<div>
		<auth-container v-if="$route.name === 'ProposalEdit'" :page="page">
			<b-toast id="practice-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
				Your practice has been saved.
			</b-toast>

			<h2 class="mb-4">Proposal {{item.proposalNumber}}</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview" active>
					<b-row>
						<b-col md="7">
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Status</b-th>
										<b-td>
											<b-badge variant="success" v-if="item.isActive" class="mr-1">Active</b-badge>
											<b-badge variant="secondary" v-else class="mr-1">Inactive</b-badge>
											<b-badge variant="danger" v-if="item.isCanceled" class="mr-1">Canceled</b-badge>
										</b-td>
									</b-tr>
									<b-tr>
										<b-th>Sponsor</b-th>
										<b-td>
											<router-link :to="`/om/sponsors/${sponsor.id}`">{{sponsor.type}} {{sponsor.id}} - {{sponsor.name}}</router-link>
										</b-td>
									</b-tr>
									<b-tr>
										<b-th>Notes</b-th>
										<b-td>{{valueOrNa(item.notes, 'None')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Reimbursement Requests</b-th>
										<b-td>
											<span v-if="reimbursements.length < 1">None</span>
											<div v-else>
												<div v-for="r in reimbursements" :key="r.id">
													<router-link :to="`/om/reimbursements/${r.id}`">{{r.name}}</router-link>
												</div>
											</div>
										</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Received</b-th>
										<b-td>{{item.dateReceived | date}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Recorded</b-th>
										<b-td>{{item.dateRecorded | date('ddd, MMM D, YYYY', 'None')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Approved</b-th>
										<b-td>{{item.dateApproved | date('ddd, MMM D, YYYY', 'None')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
					</b-row>

					<h3 class="mt-3">Practices</h3>
					<b-table striped responsive small show-empty :fields="activities.fields" :items="activities.items">
						<template #cell(edit)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'edit']" class="pointer text-primary" @click="editActivity(data.item.id)" />
						</template>
						<template #cell(dam)="data">
							<router-link :to="`/dams/${data.value.id}`">{{data.value.name}}</router-link>
						</template>
						<template #cell(practice)="data">
							<router-link :to="`/om/activities/${data.value.id}`">{{data.value.name}}</router-link>
						</template>
						<template #cell(reimbursement)="data">
							<router-link :to="`/om/reimbursements/${data.value.id}`">{{data.value.name}}</router-link>
						</template>
						<template #cell(delete)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'times']" class="pointer text-danger" @click="deletePractice(data.item.id)" />
						</template>
						<template #empty>
							<p class="my-2">This proposal does not have any practices entered.</p>
						</template>

						<template #custom-foot>
							<b-tr class="bg-light border-bottom font-weight-bold">
								<b-td colspan="3">Total</b-td>
								<b-td></b-td>
								<b-td></b-td>
								<b-td class="text-right">${{ sum(activities.items, 'estimatedCost') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(activities.items, 'approvedAmount') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(activities.items, 'approvedAdminAmount') | number(2)}}</b-td>
								<b-td colspan="6"></b-td>
							</b-tr>
						</template>
					</b-table>

					<fixed-action-bar>
						<b-button v-if="!isReadOnly" type="button" variant="success" @click="createActivity" class="mr-2">Create Practice</b-button>
						<b-button type="button" variant="primary" to="print" append target="_blank" class="mr-2">Print</b-button>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<proposal-form ref="proposalForm" :item="item" is-update @change="changesSaved"></proposal-form>
				</b-tab>
				<b-tab title="Files">
					<div v-if="files.items.length > 0">
						<p>
							{{ isReadOnly ? 'View': 'Manage' }} file uploads related to this proposal.
						</p>

						<b-list-group v-if="files.items.length > 0">
							<b-list-group-item class="d-flex justify-content-between align-items-center" v-for="file in files.items" :key="file.fileName">
								<a :href="file.fileName" target="_blank">{{file.description}}</a>
								<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'times']" class="pointer text-danger" @click="deleteFile(file)" />
							</b-list-group-item>
						</b-list-group>
					</div>
					<div v-else>
						<p><em>There are no files uploaded for this proposal yet.</em></p>
					</div>

					<div v-if="!isReadOnly">
						<hr class="my-4" />

						<h3>Upload a File</h3>

						<error-list :errors="files.errors"></error-list>

						<b-row>
							<b-col md="6">
								<b-card class="bg-light">
									<b-form-file v-model="files.fileInput" @change="selectFile" class="mb-2"
												 accept=".pdf"
												 placeholder="Choose a file or drop it here..."
												 drop-placeholder="Drop file here..."></b-form-file>

									<b-form-input type="text" required v-model="files.upload.description" placeholder="Enter a description/label" />

									<save-button type="button" variant="primary" @click.native="uploadFile" :disabled="files.saving" class="mt-2" text="Upload"></save-button>
								</b-card>
							</b-col>
						</b-row>
					</div>
				</b-tab>
				<b-tab title="Change Log">
					<grid-view :api-url="`home/logs/OmProposals/${itemId}`" use-filter small :fields="table.fields"
							   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
							   collection-description="change log"
							   item-name="log">
						<back-button />
					</grid-view>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this proposal?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.deletePractice.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.deletePractice.errors"></error-list>

				<p>
					Are you sure you want to delete this practice?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.deletePractice.saving" @click.native="confirmDeletePractice" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.deletePractice.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="modal.show" title="Practice Information" scrollable size="lg" hide-footer>
				<error-list :errors="modal.errors"></error-list>

				<proposal-activity-form :item="modal.item" :dam="modal.dam" :practice="modal.practice" :is-update="modal.isUpdate" @change="modalSaved" @cancel="cancelModal"></proposal-activity-form>
			</b-modal>

			<b-modal v-model="files.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="files.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this file?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="files.delete.saving" @click.native="confirmDeleteFile" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="files.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>	
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import ProposalForm from './ProposalForm.vue';
	import ProposalActivityForm from './ProposalActivityForm.vue';

	export default {
		name: 'ProposalEdit',
		components: {
			GridView, ProposalForm, ProposalActivityForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					deletePractice: {
						show: false,
						errors: [],
						saving: false,
						id: null
					}
				},
				item: {},
				sponsor: {},
				activities: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'isActive', label: 'Active', sortable: true, class: 'text-center', formatter: (value) => { return value ? '✔' : '-'; } },
						{ key: 'dam', label: 'Dam', sortable: true },
						{ key: 'practice', label: 'Practice', sortable: true },
						{ key: 'estimatedUnits', label: 'Est. Units', sortable: true, class: 'text-right', formatter: (value) => { return this.numberFormat(value, 2); } },
						{ key: 'estimatedCost', label: 'Est. Cost', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'approvedAmount', label: 'Approved O&M', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'approvedAdminAmount', label: 'Approved Admin', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'intendedStartDate', label: 'Intended Start', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'intendedEndDate', label: 'Intended End', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'ninetyDayDeadlineDate', label: '90-Day Deadline', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'reimbursement', label: 'Reimbursement Request', sortable: true },
						{ key: 'notes', label: 'Notes', sortable: true },
						{ key: 'delete', label: '' }
					],
					items: []
				},
				reimbursements: [],
				table: {
					fields: [
						{ key: 'activityDate', label: 'Date', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY h:mm a') } },
						{ key: 'userName', label: 'User', sortable: true },
						{ key: 'description', label: 'Description', sortable: true }
					],
					sort: 'activityDate',
					reverse: true,
					itemsPerPage: 100
				},
				modal: {
					item: {},
					dam: null,
					practice: null,
					show: false,
					loading: false,
					errors: [],
					isUpdate: false
				},
				files: {
					items: [],
					errors: [],
					saving: false,
					fileInput: null,
					upload: {
						file: null,
						description: null
					},
					delete: {
						model: null,
						show: false,
						errors: []
					}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`omproposals/${this.$route.params.id}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.proposal;
					this.sponsor = response.data.sponsor;
					this.activities.items = response.data.activities;
					this.files.items = response.data.files;
					this.reimbursements = response.data.reimbursements == null ? [] : response.data.reimbursements;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.page.tabIndex = 1;
				this.$refs.proposalForm.launchToast();
			},
			deletePractice(id) {
				this.page.deletePractice.id = id;
				this.page.deletePractice.show = true;
			},
			async confirmDeletePractice() {
				if (this.page.deletePractice.id !== null) {
					this.page.deletePractice.errors = [];
					this.page.deletePractice.saving = true;

					try {
						await this.$http.delete(`omproposalactivities/${this.page.deletePractice.id}`, this.getTokenHeader());
						this.page.deletePractice.show = false;
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deletePractice.errors = this.logError(error);
					}

					this.page.deletePractice.saving = false;
				}
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`omproposals/${this.$route.params.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: 'ProposalList' });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async editActivity(id) {
				await this.getModal(id);
				this.modal.show = true;
			},
			createActivity() {
				this.resetModal();
				this.modal.show = true;
			},
			async modalSaved() {
				this.modal.show = false;
				this.resetModal();
				await this.get();
				this.$bvToast.show('practice-saved');
			},
			cancelModal() {
				this.modal.show = false;
				this.resetModal();
			},
			resetModal() {
				this.modal.item = {
					proposalId: this.itemId,
					isActive: false,
					damId: null,
					practiceId: null,
					estimatedUnits: 0,
					estimatedCost: 0,
					approvedAmount: 0,
					intendedStartDate: null,
					intendedEndDate: null,
					notes: null
				}
				this.modal.dam = null;
				this.modal.practice = null;
				this.modal.isUpdate = false;
			},
			async getModal(id) {
				this.modal.loading = true;
				this.modal.errors = [];

				try {
					const response = await this.$http.get(`omproposalactivities/${id}`, this.getTokenHeader());
					this.log(response.data);
					this.modal.item = response.data.activity;
					this.modal.dam = response.data.dam;
					this.modal.practice = response.data.practice;
					this.modal.isUpdate = true;
				} catch (error) {
					this.modal.errors = this.logError(error);
				}

				this.modal.loading = false;
			},
			selectFile(event) {
				this.files.upload.file = event.target.files[0];
			},
			async uploadFile() {
				this.files.saving = true;
				this.files.errors = [];
				if (this.files.upload.file === null) {
					this.files.errors.push('Please select a file to upload.');
				}
				else if (this.isNullOrEmpty(this.files.upload.description)) {
					this.files.errors.push('Please enter a label or short description of the file.');
				} else {
					try {
						let formData = new FormData();
						formData.append('file', this.files.upload.file);
						formData.append('description', this.files.upload.description);

						const response = await this.$http.post(`omproposals/files/${this.$route.params.id}`, formData, this.getTokenHeaderMultiPart());
						this.log(response.data);
						this.files.upload.file = null;
						this.files.fileInput = null;
						this.files.upload.description = null;
						await this.get();
						this.page.tabIndex = 2;
					} catch (error) {
						this.files.errors = this.logError(error);
					}
				}
				

				this.files.saving = false;
			},
			deleteFile(file) {
				this.files.delete.model = file;
				this.files.delete.show = true;
			},
			async confirmDeleteFile() {
				this.files.delete.errors = [];
				this.files.delete.saving = true;

				try {
					await this.$http.put(`omproposals/deletefile/${this.$route.params.id}`, this.files.delete.model, this.getTokenHeader());
					this.files.delete.show = false;
					await this.get();
					this.page.tabIndex = 2;
				} catch (error) {
					this.files.delete.errors = this.logError(error);
				}

				this.files.delete.saving = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			}
		}
	}
</script>