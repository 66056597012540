<template>
	<div>		
		<div v-if="$route.name == 'AccountProfile'" class="container py-5">
			<auth-container :page="page">
				<h2 class="mb-4">Account information for {{profile.userName}}</h2>

				<b-tabs v-model="tabIndex" content-class="mt-4" class="page-nav-tabs">
					<b-tab title="Profile">
						<error-list :errors="page.profile.errors"></error-list>
						<success-alert ref="profileSavedAlert" text="Changes saved." />

						<b-form @submit.prevent="saveProfile">
							<b-row>
								<b-col md>
									<b-form-group label="First name" :invalid-feedback="requiredFeedback($v.profile.firstName)">
										<b-form-input v-model="profile.firstName" type="text" autofocus :state="getValidState($v.profile.firstName)"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col md>
									<b-form-group label="Last name" :invalid-feedback="requiredFeedback($v.profile.lastName)">
										<b-form-input v-model="profile.lastName" type="text" :state="getValidState($v.profile.lastName)"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<b-form-group label="Position title" :invalid-feedback="requiredFeedback($v.profile.title)">
								<b-form-input v-model="profile.affiliation" type="text" :state="getValidState($v.profile.title)"></b-form-input>
							</b-form-group>

							<div class="mt-4">
								<save-button :saving="page.profile.saving" />
							</div>
						</b-form>
					</b-tab>

					<b-tab title="Change email">
						<p>
							The current email address on your account is
							<strong>{{profile.email}}</strong>.
						</p>
						<p>
							Please contact an administrator to change your email address.
						</p>
					</b-tab>

					<b-tab title="Change password">
						<error-list :errors="page.password.errors"></error-list>
						<success-alert ref="passwordSavedAlert" text="Changes saved." />

						<b-form @submit.prevent="savePassword">
							<b-form-group label="Current password">
								<b-form-input v-model="password.old" type="password" :state="getValidState($v.password.old)"></b-form-input>
								<b-form-invalid-feedback v-if="!$v.password.old.required">Required</b-form-invalid-feedback>
							</b-form-group>

							<b-form-group label="New password" description="Minimum 10 characters with a number and special character">
								<b-form-input v-model="password.new" type="password" :state="getValidState($v.password.new)"></b-form-input>
								<b-form-invalid-feedback v-if="!$v.password.new.required">Required</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="!$v.password.new.strongPassword">Must be at least 10 characters with a number and special character</b-form-invalid-feedback>
							</b-form-group>

							<b-form-group label="Confirm new password">
								<b-form-input v-model="password.confirm" type="password" :state="getValidState($v.password.confirm)"></b-form-input>
								<b-form-invalid-feedback v-if="!$v.password.confirm.required">Required</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="!$v.password.confirm.sameAsPassword">Passwords must match</b-form-invalid-feedback>
							</b-form-group>

							<div class="mt-4">
								<save-button :saving="page.password.saving" />
							</div>
						</b-form>
					</b-tab>

					<b-tab title="Delete account" title-link-class="text-danger">
						<p>
							Are you sure you want to delete your account?
							This action is <strong>permanent and cannot be undone</strong>.
							All plans will be removed.
						</p>

						<error-list :errors="page.deleteAccount.errors"></error-list>

						<b-form @submit.prevent="confirmDelete">
							<b-form-group label="Type DELETE to confirm">
								<b-form-input v-model="deleteAccount.value" type="text" :state="getValidState($v.deleteAccount.value)"></b-form-input>
								<b-form-invalid-feedback v-if="!$v.deleteAccount.value.required">Required</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="deleteAccount.value !== 'DELETE'">You must type DELETE</b-form-invalid-feedback>
							</b-form-group>

							<div class="mt-4">
								<save-button :saving="page.deleteAccount.saving"
												variant="danger" text="Delete Account"
												:disabled="deleteAccount.value !== 'DELETE'" />
							</div>
						</b-form>
					</b-tab>
				</b-tabs>

				<fixed-action-bar :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
					<back-button class="btn btn-secondary mr-2" />
				</fixed-action-bar>
			</auth-container>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import { requiredIf, email, sameAs } from 'vuelidate/lib/validators';

	export default {
		name: 'AccountProfile',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					profile: {
						errors: [],
						saving: false
					},
					email: {
						errors: [],
						saving: false,
						submitted: false
					},
					password: {
						errors: [],
						saving: false
					},
					deleteAccount: {
						errors: [],
						saving: false
					}
				},
				tabIndex: 0,
				profile: {},
				email: {
					address: '',
					confirm: ''
				},
				password: {
					old: '',
					new: '',
					confirm: ''
				},
				deleteAccount: {
					value: ''
				}
			}
		},
		validations: {
			profile: {
				firstName: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				lastName: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				title: { required: requiredIf(function () { return this.tabIndex == 0; }) }
			},
			email: {
				address: {
					email,
					required: requiredIf(function(){ return this.tabIndex == 1; })
				},
				confirm: {
					email,
					sameAsEmail: sameAs('address'),
					required: requiredIf(function(){ return this.tabIndex == 1; })
				}
			},
			password: {
				old: { required: requiredIf(function(){ return this.tabIndex == 2; }) },
				new: {
					strongPassword(password) {
						return (
							this.tabIndex != 2 || (
							/[a-z]/.test(password) && // checks for a-z
							/[0-9]/.test(password) && // checks for 0-9
							/\W|_/.test(password) && // checks for special char
							password.length >= 10)
						);
					},
					required: requiredIf(function(){ return this.tabIndex == 2; })
				},
				confirm: {
					sameAsPassword: sameAs('new'),
					required: requiredIf(function(){ return this.tabIndex == 2; })
				}
			},
			tierRequest: {
				value: { required: requiredIf(function(){ return this.tabIndex == 3; }) }
			},
			deleteAccount: {
				value: { required: requiredIf(function(){ return this.tabIndex == 4; }) }
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				if (this.$route.name == 'AccountProfile') {
					this.page.errors = [];
					this.page.loading = true;

					try {
						const response = await this.$http.get('account/profile', this.getTokenHeader());
						this.log(response.data);
						this.profile = response.data.profile;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					this.page.loading = false;
				}
			},
			async saveProfile() {
				this.page.profile.errors = [];
				this.page.profile.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.profile.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						await this.$http.post('account/profile', this.profile, this.getTokenHeader());
						this.$refs.profileSavedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.profile.errors = this.logError(error);
					}
				}

				this.page.profile.saving = false;
			},
			async saveEmail() {
				this.page.email.errors = [];
				this.page.email.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.email.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						await this.$http.post('account/changeemail', { value: this.email.address }, this.getTokenHeader());
						this.$v.$reset();
						this.page.email.submitted = true;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.email.errors = this.logError(error);
					}
				}

				this.page.email.saving = false;
			},
			async savePassword() {
				this.page.password.errors = [];
				this.page.password.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.password.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						var data = {
							oldPassword: this.password.old,
							newPassword: this.password.new
						};
						await this.$http.post('account/changepassword', data, this.getTokenHeader());
						this.$refs.passwordSavedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.password.errors = this.logError(error);
					}
				}

				this.page.password.saving = false;
			},
			async confirmDelete() {
				this.page.deleteAccount.errors = [];
				this.page.deleteAccount.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.deleteAccount.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						await this.$http.delete('account/delete', this.getTokenHeader());
						this.logout();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deleteAccount.errors = this.logError(error);
					}
				}

				this.page.deleteAccount.saving = false;
			}
		}
	}
</script>
