<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Work Order Invoice</h2>

		<sr-invoice-form :item="item" :work-order="workOrder"></sr-invoice-form>
	</auth-container>
</template>

<script>
	import SrInvoiceForm from './SrInvoiceForm.vue';
	export default {
		name: 'SrInvoiceCreate',
		components: {
			SrInvoiceForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					workOrderId: null,
					invoiceNumber: null,
					invoiceDate: null,
					serviceStartDate: null,
					serviceEndDate: null,
					reviewer: null,
					approvalDate: null,
					contractSpecialist: null,
					contractSpecialistReceivedDate: null,
					contractSpecialistProcessedDate: null,
					accountant: null,
					accountantReceivedDate: null,
					accountantProcessedDate: null,
					notes: null
				},
				workOrder: null
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				if (!this.isNullOrEmpty(this.$route.query.workOrderId)) {
					try {
						const response = await this.$http.get(`srworkorders/getname/${this.$route.query.workOrderId}`, this.getTokenHeader());
						this.workOrder = response.data;
						this.item.workOrderId = response.data.id;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.loading = false;
			}
		}
	}
</script>