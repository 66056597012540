import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

import './plugins/bootstrap-vue';
import './plugins/font-awesome';
import './plugins/highcharts';
import './plugins/custom-components';
import './plugins/filters';
import './plugins/global-mixin';

import Vuelidate from 'vuelidate';

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

//Vue.use(StatusHub);
Vue.use(Vuelidate);

Vue.prototype.$http = axios.create({ baseURL: '/api/' });
Vue.prototype.$httpRoot = axios.create();

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
