<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved2" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col md>
					<div class="mb-3">
						<b-form-checkbox v-model.trim="$v.item.isOmEligible.$model" :state="getValidState($v.item.isOmEligible)">Eligible for O&amp;M Funding Opportunities?</b-form-checkbox>
					</div>
					<div class="mb-3">
						<b-form-checkbox v-model.trim="$v.item.isSrEligible.$model" :state="getValidState($v.item.isSrEligible)">Eligible for Structural Repair Program?</b-form-checkbox>
					</div>
				</b-col>
				<b-col md>
					<div class="mb-3">
						<b-form-checkbox v-model.trim="$v.item.wasDamAssessmentPerformed.$model" :state="getValidState($v.item.wasDamAssessmentPerformed)">Was Dam Assessment Performed?</b-form-checkbox>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col md>
					<b-form-group label="Senate District" :invalid-feedback="requiredFeedback($v.item.senateDistrict)">
						<b-form-input type="number" step="1"
									  v-model.trim.number="$v.item.senateDistrict.$model" :state="getValidState($v.item.senateDistrict)" />
					</b-form-group>

					<b-form-group label="House District" :invalid-feedback="requiredFeedback($v.item.houseDistrict)">
						<b-form-input type="number" step="1"
									  v-model.trim.number="$v.item.houseDistrict.$model" :state="getValidState($v.item.houseDistrict)" />
					</b-form-group>

					<b-form-group label="Watershed" :invalid-feedback="requiredFeedback($v.item.watershed)">
						<b-form-input type="text"
									  v-model.trim="$v.item.watershed.$model" :state="getValidState($v.item.watershed)" />
					</b-form-group>

					<b-form-group label="Regional Flood Planning Group" :invalid-feedback="requiredFeedback($v.item.regionalFloodPlanningGroupId)">
						<b-form-select v-model.trim="$v.item.regionalFloodPlanningGroupId.$model" :state="getValidState($v.item.regionalFloodPlanningGroupId)" :options="regionalFloodPlanningGroups">
							<template #first>
								<b-form-select-option :value="null">-- Select a group --</b-form-select-option>
							</template>
						</b-form-select>
					</b-form-group>

					<b-form-group label="Emergency Action Plan (EAP) Status" :invalid-feedback="requiredFeedback($v.item.eapStatus)">
						<b-form-input type="text"
									  v-model.trim="$v.item.eapStatus.$model" :state="getValidState($v.item.eapStatus)" />
					</b-form-group>

					<b-form-group label="EAP Trigger Event Date" :invalid-feedback="requiredFeedback($v.item.eapTriggerDate)">
						<b-form-input type="date" v-model.trim="$v.item.eapTriggerDate.$model" :state="getValidState($v.item.eapTriggerDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="EAP Notes" :invalid-feedback="requiredFeedback($v.item.eapNotes)">
						<b-form-textarea v-model.trim="$v.item.eapNotes.$model" :state="getValidState($v.item.eapNotes)" rows="3"></b-form-textarea>
					</b-form-group>
				</b-col>
				<b-col md>
					

					<b-form-group label="Dam Assessment Completion Date" :invalid-feedback="requiredFeedback($v.item.damAssessmentCompletionDate)">
						<b-form-input type="date" v-model.trim="$v.item.damAssessmentCompletionDate.$model" :state="getValidState($v.item.damAssessmentCompletionDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="TCEQ Hazard Class" :invalid-feedback="requiredFeedback($v.item.tceqHazardClass)">
						<b-form-input type="text"
									  v-model.trim="$v.item.tceqHazardClass.$model" :state="getValidState($v.item.tceqHazardClass)" />
					</b-form-group>

					<b-form-group label="Inspection Agency" :invalid-feedback="requiredFeedback($v.item.inspectionAgency)">
						<b-form-input type="text"
									  v-model.trim="$v.item.inspectionAgency.$model" :state="getValidState($v.item.inspectionAgency)" />
					</b-form-group>

					<b-form-group label="Inspection Date" :invalid-feedback="requiredFeedback($v.item.inspectionDate)">
						<b-form-input type="date" v-model.trim="$v.item.inspectionDate.$model" :state="getValidState($v.item.inspectionDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Remedial Order Date" :invalid-feedback="requiredFeedback($v.item.remedialOrderDate)">
						<b-form-input type="date" v-model.trim="$v.item.remedialOrderDate.$model" :state="getValidState($v.item.remedialOrderDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, integer } from 'vuelidate/lib/validators';

	export default {
		name: 'DamTxForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			regionalFloodPlanningGroups: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				apiUrl: 'dams/tx',
				editRoute: 'DamEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				}
			}
		},
		validations: {
			item: {
				damId: { required, integer },
				isOmEligible: {},
				isSrEligible: {},
				senateDistrict: { required, integer },
				houseDistrict: { required, integer },
				watershed: {},
				regionalFloodPlanningGroupId: {},
				eapStatus: {},
				eapTriggerDate: {},
				eapNotes: {},
				wasDamAssessmentPerformed: {},
				damAssessmentCompletionDate: {},
				tceqHazardClass: {},
				inspectionAgency: {},
				inspectionDate: {},
				remedialOrderDate: {},

			}
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				if (this.isUpdate) {
					this.item.eapTriggerDate = this.dateForForm(this.item.eapTriggerDate);
					this.item.damAssessmentCompletionDate = this.dateForForm(this.item.damAssessmentCompletionDate);
					this.item.inspectionDate = this.dateForForm(this.item.inspectionDate);
					this.item.remedialOrderDate = this.dateForForm(this.item.remedialOrderDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.damId}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved2');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved2');
			},
			logValidations() {
				this.log(this.$v);
			}
		}
	}
</script>
