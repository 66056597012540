<template>
	<auth-container :page="page">
		<h2 class="mb-4">Dam {{item.nid_id}}</h2>

		<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
			<b-tab title="Overview" active>
				<b-row>
					<b-col md>
						<b-table-simple small class="table-definitions">
							<b-tbody>
								<b-tr>
									<b-th>NID ID</b-th>
									<b-td>{{item.nid_id}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Name</b-th>
									<b-td>{{item.dam_name}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>County</b-th>
									<b-td>{{item.county}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>SWCD</b-th>
									<b-td>{{item.swcd}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Watershed</b-th>
									<b-td>{{item.wshed_name}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>River Basin</b-th>
									<b-td>{{item.river_basin}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>TCEQ Hazard Class</b-th>
									<b-td>{{info.tceqHazardClass}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>NRCS Hazard Class</b-th>
									<b-td>{{item.nrcs_haz}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Hazard Design</b-th>
									<b-td>{{item.dsgn_haz}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Year Completed</b-th>
									<b-td>{{item.yr_comp}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Life Expiration Year</b-th>
									<b-td>{{item.yearEvalLifeExpired}}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</b-col>
					<b-col md>
						<div class="map-container w-100" style="height:400px">
							<l-map ref="leafmap" style="height: 100%; width:100%" :zoom="map.zoom" :center="map.center" @mousemove="mapMove">
								<l-control-layers position="topright"></l-control-layers>
								<l-tile-layer v-for="tileProvider in mapBase.tileProviders"
											  :key="tileProvider.name"
											  :name="tileProvider.name"
											  :visible="tileProvider.visible"
											  :url="tileProvider.url"
											  :attribution="tileProvider.attribution"
											  layer-type="base" />

								<l-layer-group layer-type="overlay" name="SWCDs">
									<l-geo-json :geojson="map.layers.swcds.geojson" :options="map.layers.swcds.options" :options-style="map.layers.swcds.style"></l-geo-json>
								</l-layer-group>
								<l-layer-group layer-type="overlay" name="Dams">
									<l-geo-json :geojson="map.layers.dams.geojson" :options="map.layers.dams.options"></l-geo-json>
								</l-layer-group>

								<l-control position="bottomleft">
									<div v-if="map.currentCoords !== undefined">Cursor position: {{map.currentCoords.lat | number(3)}}, {{map.currentCoords.lng | number(3)}}</div>
								</l-control>
							</l-map>
						</div>
					</b-col>
				</b-row>

				<h3 class="mb-3">Repair Information</h3>

				<b-row>
					<b-col md="6">
						<b-table-simple small class="table-definitions mb-4">
							<b-tr>
								<b-th>Total Repair Need Cost</b-th>
								<b-td>{{money(item.repairNeedCostTotal)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Date Repair Completed</b-th>
								<b-td>{{valueOrNa(item.dateRepairCompleted)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Total Final Repair Cost</b-th>
								<b-td>{{money(item.finalRepairCostTotal)}}</b-td>
							</b-tr>
						</b-table-simple>
					</b-col>
				</b-row>

				<div v-if="!isNullOrEmpty(info)">
					<h3 class="mb-3">Additional Information</h3>

					<b-row>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Eligible for O&amp;M Funding Opportunities?</b-th>
										<b-td>{{boolToText(info.isOmEligible)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Eligible for Structural Repair Program?</b-th>
										<b-td>{{boolToText(info.isSrEligible)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Senate District</b-th>
										<b-td>{{valueOrNa(info.senateDistrict)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>House District</b-th>
										<b-td>{{valueOrNa(info.houseDistrict)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Watershed</b-th>
										<b-td>{{valueOrNa(info.watershed)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Regional Flood Planning Group</b-th>
										<b-td>
											<span v-if="isNullOrEmpty(info.regionalFloodPlanningGroupId)">N/A</span>
											<span v-else>{{info.regionalFloodPlanningGroupId}} - {{regionalFloodPlanningGroup.name}}</span>
										</b-td>
									</b-tr>
									<b-tr>
										<b-th>Emergency Action Plan (EAP) Status</b-th>
										<b-td>{{valueOrNa(info.eapStatus)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>EAP Trigger Event Date</b-th>
										<b-td>{{info.eapTriggerDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>EAP Notes</b-th>
										<b-td>{{valueOrNa(info.eapNotes, 'None')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Was Dam Assessment Performed?</b-th>
										<b-td>{{boolToText(info.wasDamAssessmentPerformed)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Dam Assessment Completion Date</b-th>
										<b-td>{{info.damAssessmentCompletionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>TCEQ Hazard Class</b-th>
										<b-td>{{valueOrNa(info.tceqHazardClass)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Inspection Agency</b-th>
										<b-td>{{valueOrNa(info.inspectionAgency)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Inspection Date</b-th>
										<b-td>{{info.inspectionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Remedial Order Date</b-th>
										<b-td>{{info.remedialOrderDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
					</b-row>
				</div>

				<fixed-action-bar>
					<back-button />
					<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
				</fixed-action-bar>
			</b-tab>
			<b-tab title="Edit NRCS" v-if="!isReadOnly">
				<dam-form ref="damForm" :item="item" is-update @change="changesSaved"></dam-form>
			</b-tab>
			<b-tab title="Edit TX" v-if="!isReadOnly">
				<dam-tx-form ref="damTxForm" :item="itemTx" :regionalFloodPlanningGroups="regionalFloodPlanningGroups" :is-update="!isNullOrEmpty(info)" @change="changesSaved2"></dam-tx-form>
			</b-tab>
			<b-tab title="Change Log">
				<grid-view :api-url="`home/logs/Dams/${itemId}`" use-filter small :fields="table.fields"
						   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
						   collection-description="change log"
						   item-name="log">
					<back-button />
				</grid-view>
			</b-tab>
		</b-tabs>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this dam?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</auth-container>
</template>

<script>
	import L from 'leaflet';
	import { LMap, LTileLayer, LControl, LControlLayers, LGeoJson, LLayerGroup } from 'vue2-leaflet';
	import 'leaflet/dist/leaflet.css';
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';
	import DamForm from './DamForm.vue';
	import DamTxForm from './DamTxForm.vue';

	export default {
		name: 'DamEdit',
		components: {
			DamForm, DamTxForm, LMap, LTileLayer, LControl, LControlLayers, LGeoJson, LLayerGroup, GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				item: {},
				info: {},
				regionalFloodPlanningGroup: {},
				regionalFloodPlanningGroups: [],
				map: {
					zoom: 7,
					center: [31.224, -98.897],
					currentCoords: undefined,
					layers: {
						swcds: {
							geojson: null,
							options: {},
							style: {
								weight: 1,
								opacity: 1,
								color: '#9dd123',
								fillOpacity: 0.5,
								fillColor: '#ceff5e'
							}
						},
						dams: {
							geojson: null,
							options: {},
							style: {
								radius: 6,
								weight: 1,
								opacity: 1,
								color: '#9b3ee8',
								fillOpacity: 0.8,
								fillColor: '#ca93f9'
							}
						}
					}
				},
				table: {
					fields: [
						{ key: 'activityDate', label: 'Date', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } },
						{ key: 'userName', label: 'User', sortable: true },
						{ key: 'description', label: 'Description', sortable: true }
					],
					sort: 'activityDate',
					reverse: true,
					itemsPerPage: 100
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`dams/${this.$route.params.id}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.dam;
					this.info = response.data.stateInfo;
					this.regionalFloodPlanningGroup = response.data.regionalFloodPlanningGroup;
					this.regionalFloodPlanningGroups = response.data.regionalFloodPlanningGroups;

					this.map.center = [this.item.lat_deg, this.item.long_deg];

					const response2 = await this.$http.get(`home/dammap/${this.$route.params.id}`, this.getTokenHeader());
					this.log(response2.data);
					this.map.layers.swcds.geojson = response2.data.swcds;
					this.map.layers.swcds.options = {
						onEachFeature: this.mapSwcdsOnEachFeature
					};

					this.map.layers.dams.geojson = response2.data.dams;
					this.map.layers.dams.options = {
						pointToLayer: (feature, latLng) => L.circleMarker(latLng, this.map.layers.dams.style),
						onEachFeature: this.mapDamsOnEachFeature
					};
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.page.tabIndex = 1;
				this.$refs.damForm.launchToast();
			},
			async changesSaved2() {
				await this.get();
				this.page.tabIndex = 2;
				this.$refs.damTxForm.launchToast();
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`dams/${this.$route.params.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: 'DamList' });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			mapMove(e) {
				this.map.currentCoords = e.latlng;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			},
			mapDamsOnEachFeature() {
				return (feature, layer) => {
					let popText = `<div>Dam ${feature.properties.nid_id} - ${feature.properties.name}</div>`;

					layer.bindTooltip(
						popText,
						{ permanent: false, sticky: true });
				};
			},
			mapSwcdsOnEachFeature() {
				return (feature, layer) => {
					let popText = `<div>SWCD# ${feature.properties.id} - ${feature.properties.name}</div>`;

					layer.bindTooltip(
						popText,
						{ permanent: false, sticky: true });
				};
			},
			itemTx() {
				if (this.isNullOrEmpty(this.info)) {
					return {
						damId: this.itemId,
						isOmEligible: false,
						isSrEligible: false,
						senateDistrict: 0,
						houseDistrict: 0,
						watershed: null,
						regionalFloodPlanningGroupId: null,
						eapStatus: null,
						eapTriggerDate: null,
						eapNotes: null,
						wasDamAssessmentPerformed: false,
						damAssessmentCompletionDate: null,
						tceqHazardClass: null,
						inspectionAgency: null,
						inspectionDate: null,
						remedialOrderDate: null
					};
				}
				return this.info;
			}
		}
	}
</script>