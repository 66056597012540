<template>
	<auth-container :page="page">
		<h2 class="mb-4">Proposal Information</h2>

		<proposal-form :item="item"></proposal-form>
	</auth-container>
</template>

<script>
	import ProposalForm from './ProposalForm.vue';
	import moment from 'moment';
	export default {
		name: 'ProposalCreate',
		components: {
			ProposalForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {}
			}
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				this.item = {
					id: 0,
					fiscalYear: this.currentFiscalYear(),
					sponsorId: '',
					dateReceived: moment().format(),
					proposalNumber: null,
					dateRecorded: null,
					dateApproved: null,
					isActive: false,
					isCanceled: false,
					notes: null
				}
			}
		}
	}
</script>