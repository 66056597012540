<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control Structural Repair Program<br />HUB Log</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-table-simple small class="table-definitions">
				<b-tbody>
					<b-tr>
						<b-th>Invoice #</b-th>
						<b-td>{{$parent.invoice.name}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Contractor</b-th>
						<b-td>{{valueOrNa($parent.item.Contractor)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>VID/EIN of Contractor</b-th>
						<b-td>{{valueOrNa($parent.item.VidEin)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Amount</b-th>
						<b-td>{{money($parent.item.amount)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Notes</b-th>
						<b-td>{{valueOrNa($parent.item.notes, 'None')}}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'SrInvoiceHubPrint',
		components: { PrintMenu }
	}
</script>