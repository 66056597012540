<template>
	<div class="alert alert-info d-print-none my-4">
		This report is formatted to be printed from your browser window.
		Google Chrome is recommended. Choose 'Save as PDF' option from the print menu to save to your computer.
		<div class="d-flex my-2">
			<div class="mr-2">
				<b-button variant="info" @click="print" class="mr-2"><font-awesome-icon :icon="['fas', 'print']" /> Print</b-button>
				<b-button variant="secondary" @click="close">Close</b-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PrintMenu',
		methods: {
			print() {
				window.print();
			},
			close() {
				window.close();
			}
		}
	}
</script>
