<template>
	<auth-container :page="page">
		<h2 class="mb-4">Activity Information</h2>

		<activity-form :item="item"></activity-form>
	</auth-container>
</template>

<script>
	import ActivityForm from './ActivityForm.vue';
	export default {
		name: 'ActivityCreate',
		components: {
			ActivityForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					id: 0,
					practiceCode: null,
					description: null,
					units: null,
					costPerUnit: null,
					comments: null
				}
			}
		}
	}
</script>