<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col md="6" v-for="field in fields" :key="field.name">
					<b-form-group :label="field.label + (field.required ? ' (required)' : '')" :invalid-feedback="requiredFeedback($v.item[field.name])">
						<b-form-input :type="field.type === 'text'? 'text': 'number'" :readonly="isUpdate && field.readOnly"
									  :required="field.required" :step="field.type === 'decimal' ? 'any' : '1'"
									  v-model.trim="$v.item[field.name].$model" :state="getValidState($v.item[field.name])" />
					</b-form-group>
				</b-col>
			</b-row>

			<div v-if="checkboxFields.length > 0">
				<hr class="my-4" />
				<b-row>
					<b-col md="4" v-for="field in checkboxFields" :key="field.name">
						<b-form-checkbox v-model.trim="$v.item[field.name].$model" :state="getValidState($v.item[field.name])">{{field.label}}</b-form-checkbox>
					</b-col>
				</b-row>
			</div>

			<b-form-group label="Shape data in GeoJson format" :invalid-feedback="requiredFeedback($v.item.geometry)">
				<b-form-textarea v-model.trim="$v.item.geometry.$model" :state="getValidState($v.item.geometry)" rows="5"></b-form-textarea>
			</b-form-group>

			<b-form-group label="Additional properties in json format" :invalid-feedback="requiredFeedback($v.item.additionalProperties)">
				<b-form-textarea v-model.trim="$v.item.additionalProperties.$model" :state="getValidState($v.item.additionalProperties)" rows="5"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Upload Dams" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';

	export default {
		name: 'MapLayerForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				apiUrl: 'mapLayers',
				editRoute: 'MapLayerEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				fields: [
					{ name: 'layerName', label: 'Layer Name', type: 'text', required: true, readOnly: false },
					{ name: 'name', label: 'Item Name', type: 'text', required: true, readOnly: false },
					{ name: 'label', label: 'Label', type: 'text', required: true, readOnly: false }
				],
				checkboxFields: []
			}
		},
		validations() {
			let item = {};
			for (let field of this.fields) {
				if (field.type === 'decimal') {
					if (field.required) item[field.name] = { required, decimal };
					else item[field.name] = { decimal };
				} else if (field.type === 'integer') {
					if (field.required) item[field.name] = { required, integer };
					else item[field.name] = { integer };
				} else if (field.type === 'text') {
					if (field.required) item[field.name] = { required };
					else item[field.name] = {};
				} else {
					item[field.name] = {};
				}
			}
			for (let field of this.checkboxFields) {
				item[field.name] = {};
			}

			item.geometry = {};
			item.additionalProperties = {};
			return { item: item };
		},
		methods: {
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;
				this.logValidations();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
				if (this.$v.$invalid) {
					for (let field of this.fields) {
						let invalid = this.$v.item[field.name].$invalid;
						this.log(`${field.name}: ${invalid}`);
					}
				}
			}
		}
	}
</script>
