<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col md="6">
					<b-form-group label="Invoice #" :invalid-feedback="requiredFeedback($v.query.invoiceNumber)">
						<b-typeahead v-model.trim="$v.query.invoiceNumber.$model" required
									 :data="options.invoiceNumber"
									 :serializer="s => s.name" @hit="item.workOrderInvoiceId = $event.id"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Contractor" :invalid-feedback="requiredFeedback($v.item.contractor)">
						<b-typeahead v-model.trim="$v.item.contractor.$model"
									 :data="options.contractor"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="VID/EIN of Contractor" :invalid-feedback="requiredFeedback($v.item.vidEin)">
						<b-typeahead v-model.trim="$v.item.vidEin.$model"
									 :data="options.vidEin"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Amount" :invalid-feedback="requiredFeedback($v.item.amount)">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any"
										  v-model.trim.number="$v.item.amount.$model" :state="getValidState($v.item.amount)" />
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)">
				<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="5"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, decimal } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrInvoiceHubForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			invoice: {
				type: Object,
				required: false
			}
		},
		data() {
			return {
				apiUrl: 'srworkorderinvoicehubs',
				editRoute: 'SrInvoiceHubEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					invoiceNumber: [],
					contractor: [],
					vidEin: []
				},
				query: {
					invoiceNumber: ''
				}
			}
		},
		computed: {
			queryInvoiceNumber() {
				return this.query.invoiceNumber;
			},
			itemContractor() {
				return this.item.contractor;
			},
			itemVidEin() {
				return this.item.vidEin;
			}
		},
		validations: {
			item: {
				workOrderInvoiceId: { required },
				contractor: { required },
				vidEin: {},
				amount: { required, decimal },
				notes: {}
			},
			query: {
				invoiceNumber: { required }
			}
		},
		watch: {
			queryInvoiceNumber: _.debounce(function (query) { this.findInvoices(query) }, 500),
			itemContractor: _.debounce(function (query) { this.find('contractor', query) }, 500),
			itemVidEin: _.debounce(function (query) { this.find('vidEin', query) }, 500)
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				if (!this.isNullOrEmpty(this.invoice)) {
					this.query.invoiceNumber = this.invoice.name;
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async findInvoices(query) {
				try {
					const response = await this.$http.get(`/srworkorderinvoices/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					this.options.invoiceNumber = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>
