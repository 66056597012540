<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<div v-if="!page.saveConfirmShow">
				<b-form @submit.prevent="save">
					<b-form-group label="Project/Dam" :invalid-feedback="requiredFeedback($v.item.damId)">
						<b-form-select v-model.trim="$v.item.damId.$model" :state="getValidState($v.item.damId)" :options="dams">
							<template #first>
								<b-form-select-option :value="null" disabled>-- Select a project/dam --</b-form-select-option>
							</template>
						</b-form-select>
					</b-form-group>

					<b-form-group label="Agreement/PO#" :invalid-feedback="requiredFeedback($v.item.agreementPoNum)">
						<b-form-input type="text"
									  v-model.trim="$v.item.agreementPoNum.$model" :state="getValidState($v.item.agreementPoNum)" />
					</b-form-group>

					<b-form-group label="Funding Amount" :invalid-feedback="requiredFeedback($v.item.fundingAmount)">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any"
										  v-model.trim.number="$v.item.fundingAmount.$model" :state="getValidState($v.item.fundingAmount)" />
						</b-input-group>
					</b-form-group>

					<b-row>
						<b-col md>
							<b-form-group label="Dam Code" :invalid-feedback="requiredFeedback($v.item.damCode)">
								<b-form-input type="text"
											  v-model.trim="$v.item.damCode.$model" :state="getValidState($v.item.damCode)" />
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Status" :invalid-feedback="requiredFeedback($v.item.status)">
								<b-input-group append="%">
									<b-form-input type="number" step="1"
												  v-model.trim.number="$v.item.status.$model" :state="getValidState($v.item.status)" />
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<b-form-group label="Revision Date" :invalid-feedback="requiredFeedback($v.item.revisionDate)">
						<b-form-input type="date" v-model.trim="$v.item.revisionDate.$model" :state="getValidState($v.item.revisionDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)" class="mb-4">
						<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="3"></b-form-textarea>
					</b-form-group>

					<div class="mt-4 mb-3 d-flex align-items-center">
						<save-button :saving="page.saving" class="mr-2" />
						<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
						<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
							<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
							You have errors in the form.
						</div>
					</div>
				</b-form>
			</div>
			<div v-else>
				<b-form-group label="Add a note to describe changes?" description="Optional">
					<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
				</b-form-group>

				<div class="my-3">
					<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
					<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';

	export default {
		name: 'SrWorkOrderDamForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			dams: {
				type: Array,
				required: true
			}
		},
		data() {
			return {
				apiUrl: 'srworkorderdams',
				page: {
					loading: false,
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null,
					loadingAgreements: false
				},
				agreements: []
			}
		},
		validations: {
			item: {
				workOrderId: { required, integer },
				damId: { required, integer },
				fundingAmount: { required, decimal },
				agreementPoNum: {},
				damCode: {},
				status: { required, integer },
				revisionDate: {},
				notes: {}
			}
		},
		created() {
			this.get();
		},
		methods: {
			async get() {
				if (this.isUpdate) {
					this.item.revisionDate = this.dateForForm(this.item.revisionDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;
				this.logValidations();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.page.errors.length < 1) {
						if (this.isUpdate) {
							this.page.saveConfirmShow = true;
						} else {
							try {
								const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
								this.log(response.data);

								this.$emit('change');
							} catch (error) {
								if (this.isApiUnauthorized(error)) this.page.showLogin = true;
								else this.page.errors = this.logError(error);
							}
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			logValidations() {
				this.log(this.$v);
			},
			cancel() {
				this.$emit('cancel');
			}
		}
	}
</script>
