<template>
	<div>
		<auth-container :page="page" v-if="$route.name === 'SrWorkOrderEdit'">
			<h2 class="mb-4">Work Order #{{item.workOrderNumber}}</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview">
					<b-row>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Revision</b-th>
										<b-td>{{valueOrNa(item.revision)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>PO#</b-th>
										<b-td>{{valueOrNa(item.purchaseOrder)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>PO Issuance Date</b-th>
										<b-td>{{item.purchaseOrderDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Name</b-th>
										<b-td>{{valueOrNa(item.name)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Execution Date</b-th>
										<b-td>{{item.executionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Expiration Date</b-th>
										<b-td>{{item.expirationDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Completion Date</b-th>
										<b-td>{{item.completionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Project Manager</b-th>
										<b-td>{{valueOrNa(item.projectManager)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Contractor</b-th>
										<b-td>{{valueOrNa(item.contractor)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Primary POC</b-th>
										<b-td>{{valueOrNa(item.pocPrimary)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Secondary POC</b-th>
										<b-td>{{valueOrNa(item.pocSecondary)}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Funding Type</b-th>
										<b-td>{{valueOrNa(item.fundingType)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Funding Subcategory</b-th>
										<b-td>{{valueOrNa(item.fundingSubcategory)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Fiscal Year</b-th>
										<b-td>{{valueOrNa(item.fundingFiscalYear)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Project Type</b-th>
										<b-td>{{valueOrNa(item.projectType)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Project Subtype</b-th>
										<b-td>{{valueOrNa(item.projectSubtype)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Notes</b-th>
										<b-td>{{valueOrNa(item.notes, 'None')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>

							<b-table-simple small class="table-definitions" bordered>
								<b-tbody>
									<b-tr class="bg-light">
										<b-th>Project</b-th>
										<b-th>Dam</b-th>
									</b-tr>
									<b-tr v-for="(p, i) in projects" :key="i">
										<b-td><router-link :to="`/sr/projects/${p.projectId}`">#{{p.projectNumber}} - {{p.projectName}}</router-link></b-td>
										<b-td>
											<span v-if="isNullOrEmpty(p.damId)">N/A</span>
											<router-link v-else :to="`/dams/${p.damId}`">{{p.damName}}</router-link>
										</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
					</b-row>

					<h3 class="my-3">Work Order Funding by Dam</h3>
					<b-table striped responsive small show-empty :fields="dams.fields" :items="dams.items">
						<template #cell(edit)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'edit']" class="pointer text-primary" @click="editSubItem(data.item.id)" />
						</template>
						<template #cell(dam)="data">
							<router-link :to="`/dams/${data.value.id}`">{{data.value.name}}</router-link>
						</template>
						<template #cell(agreement)="data">
							<router-link :to="`/sr/agreements/${data.value.id}`">{{data.value.name}}</router-link>
						</template>
						<template #cell(delete)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'times']" class="pointer text-danger" @click="deleteSubItem(data.item.id)" />
						</template>
						<template #empty>
							<p class="my-2">This work order does not have any dams entered.</p>
						</template>

						<template #custom-foot>
							<b-tr class="bg-light border-bottom font-weight-bold">
								<b-td colspan="2">Total</b-td>
								<b-td class="text-right">${{ sum(dams.items, 'fundingAmount') | number(2)}}</b-td>
								<b-td colspan="5"></b-td>
							</b-tr>
						</template>
					</b-table>

					<fixed-action-bar>
						<b-button v-if="!isReadOnly" type="button" variant="success" @click="createSubItem" class="mr-2">Add Funding</b-button>
						<b-button type="button" variant="primary" to="print" append target="_blank" class="mr-2">Print</b-button>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Invoices">
					<b-table striped responsive small show-empty :fields="invoices.fields" :items="invoices.items">
						<template #cell(edit)="data">
							<router-link :to="`/sr/workorders/invoices/${data.item.id}`">
								<font-awesome-icon :icon="['fas', 'edit']" />
							</router-link>
						</template>
						<template #cell(dam)="data">
							<router-link :to="`/dams/${data.value.id}`">{{data.value.name}}</router-link>
						</template>
						<template #empty>
							<p class="my-2">This work order does not have any invoices entered.</p>
						</template>

						<template #custom-foot>
							<b-tr class="bg-light border-bottom font-weight-bold">
								<b-td colspan="6">Total</b-td>
								<b-td class="text-right">${{ sum(invoices.items, 'labor') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(invoices.items, 'nonLabor') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(invoices.items, 'subconsultant') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(invoices.items, 'total') | number(2)}}</b-td>
								<b-td colspan="11"></b-td>
							</b-tr>
						</template>
					</b-table>

					<fixed-action-bar>
						<b-button v-if="!isReadOnly" type="button" variant="success" :to="`/sr/workorders/invoices/create?workOrderId=${itemId}`" class="mr-2">Create Invoice</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<sr-work-order-form ref="itemForm" :item="item" :projects="projectRelations" :set-options="setOptions" is-update @change="changesSaved"></sr-work-order-form>
				</b-tab>
				<b-tab title="Files">
					<file-manager :apiUrl="apiUrl" :files="files" :itemId="itemId" itemLabel="application" @change="filesChanged"></file-manager>
				</b-tab>
				<b-tab title="Change Log">
					<log-viewer db-table="SrWorkOrders" :item-id="itemId"></log-viewer>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this work order?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.deleteSubItem.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.deleteSubItem.errors"></error-list>

				<p>
					Are you sure you want to delete this work order item?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.deleteSubItem.saving" @click.native="confirmDeleteSubItem" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.deleteSubItem.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="modal.show" title="Work Order Dam Information" scrollable size="lg" hide-footer>
				<error-list :errors="modal.errors"></error-list>

				<sr-work-order-dam-form :item="modal.item" :dams="damsForForm" :is-update="modal.isUpdate" @change="modalSaved" @cancel="cancelModal"></sr-work-order-dam-form>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import FileManager from '@/components/FileManager';
	import SrWorkOrderForm from './SrWorkOrderForm.vue';
	import SrWorkOrderDamForm from './SrWorkOrderDamForm.vue';

	export default {
		name: 'SrWorkOrderEdit',
		components: {
			SrWorkOrderForm, SrWorkOrderDamForm, FileManager
		},
		data() {
			return {
				apiUrl: 'srworkorders',
				listRoute: 'SrWorkOrderList',
				subItemApiUrl: 'srworkorderdams',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					deleteSubItem: {
						show: false,
						errors: [],
						saving: false,
						id: null
					}
				},
				item: {},
				projects: [],
				files: [],
				setOptions: {},
				dams: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'dam', sortable: true },
						{ key: 'fundingAmount', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'agreementPoNum', label: 'Agreement/PO#', sortable: true },
						{ key: 'damCode', sortable: true },
						{ key: 'status', sortable: true },
						{ key: 'revisionDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'delete', label: '' }
					],
					items: []
				},
				invoices: {
					fields: [
						{ key: 'edit', label: '', stickyColumn: true },
						{ key: 'dam', sortable: true },
						{ key: 'invoiceNumber', label: 'Invoice #', sortable: true },
						{ key: 'invoiceDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'serviceStartDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'serviceEndDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'labor', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'nonLabor', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'subconsultant', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'total', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'workOrderProgress', sortable: true, class: 'text-right', formatter: (value) => { return `${this.numberFormat(value)}%`; } },
						{ key: 'status', sortable: true, class: 'text-right', formatter: (value) => { return `${this.numberFormat(value)}%`; } },
						{ key: 'reviewer', sortable: true },
						{ key: 'approvalDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'contractSpecialist', sortable: true },
						{ key: 'contractSpecialistReceivedDate', label: 'CS Received', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'contractSpecialistProcessedDate', label: 'CS Processed Date', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'accountant', sortable: true },
						{ key: 'accountantReceivedDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'accountantProcessedDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'notes', sortable: true },
					],
					items: []
				},
				modal: {
					item: {},
					show: false,
					loading: false,
					errors: [],
					isUpdate: false
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.workOrder;
					this.projects = response.data.projects;
					this.files = response.data.files;
					this.setOptions = response.data.setOptions;
					this.dams.items = response.data.dams;
					this.invoices.items = response.data.invoices;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
				this.page.tabIndex = 2;
			},
			async filesChanged() {
				await this.get();
				this.page.tabIndex = 3;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			deleteSubItem(id) {
				this.page.deleteSubItem.id = id;
				this.page.deleteSubItem.show = true;
			},
			async confirmDeleteSubItem() {
				if (this.page.deleteSubItem.id !== null) {
					this.page.deleteSubItem.errors = [];
					this.page.deleteSubItem.saving = true;

					try {
						await this.$http.delete(`${this.subItemApiUrl}/${this.page.deleteSubItem.id}`, this.getTokenHeader());
						this.page.deleteSubItem.show = false;
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deleteSubItem.errors = this.logError(error);
					}

					this.page.deleteSubItem.saving = false;
				}
			},
			async editSubItem(id) {
				await this.getModal(id);
				this.modal.show = true;
			},
			createSubItem() {
				this.resetModal();
				this.modal.show = true;
			},
			async modalSaved() {
				this.modal.show = false;
				this.resetModal();
				await this.get();
				this.$bvToast.show('subitem-saved');
			},
			cancelModal() {
				this.modal.show = false;
				this.resetModal();
			},
			resetModal() {
				this.modal.item = {
					workOrderId: Number(this.itemId),
					damId: null,
					fundingAmount: 0,
					agreementId: null,
					damCode: null,
					status: 0,
					revisionDate: null,
					notes: null
				}
				this.modal.isUpdate = false;
			},
			async getModal(id) {
				this.modal.loading = true;
				this.modal.errors = [];

				try {
					const response = await this.$http.get(`${this.subItemApiUrl}/${id}`, this.getTokenHeader());
					this.log(response.data);
					this.modal.item = response.data.item;
					this.modal.isUpdate = true;
				} catch (error) {
					this.modal.errors = this.logError(error);
				}

				this.modal.loading = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			},
			projectRelations() {
				let relations = [];
				for (let p of this.projects) {
					relations.push({ id: p.projectId, name: p.display });
				}
				return relations;
			},
			damsForForm() {
				let dams = [];
				for (let p of this.projects) {
					dams.push({ value: p.damId, text: p.display });
				}
				return dams;
			}
		}
	}
</script>