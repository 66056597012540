<template>
	<div>
		<auth-container v-if="$route.name === 'SrProjectList'" :page="page">
			<h2 class="mb-4">Projects</h2>

			<div class="mx-auto">
				<b-row class="mb-3" align-v="end">
					<b-col lg="6">
						<b-form inline>
							<b-input-group>
								<b-form-input v-model="table.filter" placeholder="Search..." @input="filterChg"></b-form-input>

								<b-input-group-append class="pointer mr-2">
									<b-input-group-text @click="clearFilter">
										<font-awesome-icon :icon="['fas', 'times']" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
							<b-button variant="primary" type="button" @click="table.showAdvancedFilter = !table.showAdvancedFilter">Advanced Filter</b-button>
						</b-form>
					</b-col>
					<b-col lg="6" class="text-right">
						<div class="ml-auto">
							Showing {{showingFirst}} - {{showingLast}}
							of {{table.total}}
						</div>
					</b-col>
				</b-row>

				<b-collapse v-model="table.showAdvancedFilter" class="my-3">
					<b-card bg-variant="light">
						<b-row>
							<b-col lg="4">
								<div class="form-group row">
									<label for="advancedFilter.projectNumber" class="col-lg-5 col-form-label">Project #</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.projectNumber"
													 v-model="advancedFilter.projectNumber"
													 :data="advancedFilter.options.projectNumber"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.appSlo" class="col-lg-5 col-form-label">Application #</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.appNum"
													 v-model="advancedFilter.appNum"
													 :data="advancedFilter.options.appNum"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.dam" class="col-lg-5 col-form-label">Dam NID_ID</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.dam"
													 v-model="advancedFilter.dam"
													 :data="advancedFilter.options.dam"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.name" class="col-lg-5 col-form-label">Project Name</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.name"
													 v-model="advancedFilter.name"
													 :data="advancedFilter.options.name"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.status" class="col-lg-5 col-form-label">Project Status</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.status"
													 v-model="advancedFilter.status"
													 :data="setOptions.statuses"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.category" class="col-lg-5 col-form-label">Category</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.category"
													 v-model="advancedFilter.category"
													 :data="setOptions.categories"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.phase" class="col-lg-5 col-form-label">Phase</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.phase"
													 v-model="advancedFilter.phase"
													 :data="setOptions.phases"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.aeAssignment" class="col-lg-5 col-form-label">A&amp;E Assignment</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.aeAssignment"
													 v-model="advancedFilter.aeAssignment"
													 :data="advancedFilter.options.aeAssignment"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>
							</b-col>
							<b-col lg>
								<div class="form-group row">
									<label for="advancedFilter.landRightsStatus" class="col-lg-3 col-form-label">Land Rights Status</label>
									<div class="col-lg-9">
										<b-typeahead id="advancedFilter.landRightsStatus"
													 v-model="advancedFilter.landRightsStatus"
													 :data="setOptions.landRightsStatuses"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.landRightsStatusDate.start" class="col-lg-3 col-form-label">Land Rights Status Date</label>
									<div class="col-lg-9">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter.landRightsStatusDate.start" v-model="advancedFilter.landRightsStatusDate.start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.landRightsStatusDate.start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter.landRightsStatusDate.end" v-model="advancedFilter.landRightsStatusDate.end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.landRightsStatusDate.end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.coe404PermitStatus" class="col-lg-3 col-form-label">CoE 404 Permit Status</label>
									<div class="col-lg-9">
										<b-typeahead id="advancedFilter.coe404PermitStatus"
													 v-model="advancedFilter.coe404PermitStatus"
													 :data="setOptions.coe404PermitStatuses"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.tpwdArrpStatus" class="col-lg-3 col-form-label">TPWD ARRP Status</label>
									<div class="col-lg-9">
										<b-typeahead id="advancedFilter.tpwdArrpStatus"
													 v-model="advancedFilter.tpwdArrpStatus"
													 :data="setOptions.tpwdArrpStatuses"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.omAgreementStatus" class="col-lg-3 col-form-label">O&amp;M Agreement Status</label>
									<div class="col-lg-9">
										<b-typeahead id="advancedFilter.omAgreementStatus"
													 v-model="advancedFilter.omAgreementStatus"
													 :data="setOptions.omAgreementStatuses"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.sponsorStatus" class="col-lg-3 col-form-label">Sponsor Status</label>
									<div class="col-lg-9">
										<b-typeahead id="advancedFilter.sponsorStatus"
													 v-model="advancedFilter.sponsorStatus"
													 :data="setOptions.sponsorStatuses"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.expectedCompletionDate.start" class="col-lg-3 col-form-label">Expected Completion Date</label>
									<div class="col-lg-9">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter.expectedCompletionDate.start" v-model="advancedFilter.expectedCompletionDate.start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.expectedCompletionDate.start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter.expectedCompletionDate.end" v-model="advancedFilter.expectedCompletionDate.end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.expectedCompletionDate.end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.completionSubmissionDate.start" class="col-lg-3 col-form-label">Completion Letter Submission Date</label>
									<div class="col-lg-9">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter.completionSubmissionDate.start" v-model="advancedFilter.completionSubmissionDate.start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.completionSubmissionDate.start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter.completionSubmissionDate.end" v-model="advancedFilter.completionSubmissionDate.end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.completionSubmissionDate.end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
						<hr class="my-3" />
						<div>
							<b-button variant="primary" type="button" @click="get" class="mr-2">Apply</b-button>
							<b-button variant="secondary" type="button" @click="clearAdvancedFilter" class="mr-2">Clear</b-button>
							<b-button variant="secondary" type="button" @click="table.showAdvancedFilter = false">Close</b-button>
						</div>
					</b-card>
				</b-collapse>

				<b-table striped responsive table-class="table-fixed-height border-bottom table-grid" sticky-header="60vh"
						 small show-empty
						 :items="items" :busy="table.loading"
						 :fields="table.fields"
						 :no-local-sorting="true"
						 :sort-by.sync="table.sort"
						 :sort-desc.sync="table.reverse"
						 @sort-changed="sortChg">
					<template #table-busy>
						<div class="text-center my-4">
							<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
						</div>
					</template>
					<template #cell(edit)="data">
						<router-link :to="`${data.item.applicationId}`" append>
							<font-awesome-icon :icon="['fas', 'edit']" />
						</router-link>
					</template>
					<template #cell(appNum)="data">
						<router-link :to="`/sr/applications/${data.item.applicationId}`">{{data.value}}</router-link>
					</template>
					<template #cell(damId)="data">
						<router-link :to="`/dams/${data.value}`">{{data.item.nid_id}}</router-link>
					</template>
					<template #empty>
						<h4>No records found.</h4>
					</template>
					<template #emptyfiltered>
						<h4>No records found matching "{{table.filter}}".</h4>
					</template>
				</b-table>
			</div>

			<fixed-action-bar>
				<router-link v-if="!isReadOnly" to="create" append class="btn btn-success mr-2">
					Create
				</router-link>
				<json-to-csv :api-url="`${apiUrl}/export`" :filename="apiUrl" class="mr-2" />
				<b-button v-if="isAuthorized(roleNames.superAdmin)" variant="primary" @click="page.showModal = true" class="mr-2">Import</b-button>
				<b-pagination class="ml-auto mb-0" v-if="table.total > table.itemsPerPage" size="md" :total-rows="table.total" v-model="table.currentPage" :per-page="table.itemsPerPage" @change="pageChg" />
			</fixed-action-bar>

			<b-modal v-model="page.showModal" title="Upload" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<p class="mt-3">
					<strong>Recommended developer upload only.</strong>
				</p>

				<div slot="modal-footer">
					<json-to-csv :json-data="template" :filename="`${apiUrl}-template`" text="Download Template" class="mr-2" />
					<b-button type="button" variant="primary" @click="page.showModal = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import _ from 'underscore';

	export default {
		name: 'SrProjectList',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				apiUrl: 'srprojects',
				page: {
					errors: [],
					loading: true,
					showLogin: false,
					saveErrors: [],
					showModal: false
				},
				dropzoneOptions: {
					url: '/api/srprojects/upload/',
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				table: {
					fields: [
						{ key: 'edit', label: '', stickyColumn: true },
						{ key: 'projectNumber', label: 'Project #', sortable: true },
						{ key: 'appNum', label: 'App. #', sortable: true },
						{ key: 'damId', label: 'NID_ID', sortable: true },
						{ key: 'status', sortable: true },
						{ key: 'category', sortable: true },
						{ key: 'phase', sortable: true },
						{ key: 'name', sortable: true },
						{ key: 'aeAssignment', label: 'A&E Assignment', sortable: true },
						{ key: 'landRightsStatus', sortable: true },
						{ key: 'coe404PermitStatus', label: 'CoE 404 Permit Status', sortable: true },
						{ key: 'tpwdArrpStatus', label: 'TPWD ARRP Status', sortable: true },
						{ key: 'omAgreementStatus', label: 'O&M Agreement Status', sortable: true },
						{ key: 'projectManager', sortable: true },
						{ key: 'fcpStaff', label: 'FCP Staff', sortable: true },
						{ key: 'sponsorStatus', sortable: true },
						{ key: 'expectedCompletionDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'completionSubmissionDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } }
					],
					sort: 'projectNumber',
					reverse: true,
					itemsPerPage: 100,
					filter: null,
					currentPage: 1,
					total: 0,
					loading: false,
					showAdvancedFilter: false
				},
				items: [],
				advancedFilter: {
					dam: null,
					appNum: null,
					projectNumber: null,
					name: null,
					status: null,
					category: null,
					phase: null,
					aeAssignment: null,
					landRightsStatus: null,
					landRightsStatusDate: {
						start: null,
						end: null
					},
					coe404PermitStatus: null,
					tpwdArrpStatus: null,
					omAgreementStatus: null,
					sponsorStatus: null,
					expectedCompletionDate: {
						start: null,
						end: null
					},
					completionSubmissionDate: {
						start: null,
						end: null
					},
					options: {
						dam: [],
						appNum: [],
						projectNumber: [],
						name: [],
						aeAssignment: []
					}
				},
				setOptions: {
					statuses: [],
					categories: [],
					phases: [],
					landRightsStatuses: [],
					coe404PermitStatuses: [],
					omAgreementStatuses: [],
					sponsorStatuses: [],
					tpwdArrpStatuses: []
				},
				template: [{
					projectNumber: null,
					appNum: null,
					status: null,
					statusNote: null,
					category: null,
					phase: null,
					name: null,
					aeAssignment: null,
					landRightsStatus: null,
					landRightsStatusDate: null,
					landRightsNotes: null,
					coe404PermitStatus: null,
					coeStatusDate: null,
					coeNotes: null,
					tpwdArrpStatus: null,
					tpwdArrpStatusDate: null,
					tpwdArrpNotes: null,
					omAgreementStatus: null,
					omAgreementStatusDate: null,
					omAgreementNotes: null,
					projectManager: null,
					fcpStaff: null,
					sponsorStatus: null,
					sponsorStatusNote: null,
					expectedCompletionDate: null,
					completionSubmissionDate: null,
					notes: null
				}]
			}
		},
		computed: {
			showingFirst() {
				return this.table.total === 0 ? 0 : (this.table.currentPage - 1) * this.table.itemsPerPage + 1
			},
			showingLast() {
				var max = (this.table.currentPage - 1) * this.table.itemsPerPage + this.table.itemsPerPage
				return max > this.table.total ? this.table.total : max;
			},
			advancedFilterDam() {
				return this.advancedFilter.dam;
			},
			advancedFilterAppNum() {
				return this.advancedFilter.appNum;
			},
			advancedFilterProjectNumber() {
				return this.advancedFilter.projectNumber;
			},
			advancedFilterName() {
				return this.advancedFilter.name;
			},
			advancedFilterAeAssignment() {
				return this.advancedFilter.aeAssignment;
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get',
			advancedFilterAppNum: _.debounce(function (query) { this.find('appNum', query) }, 500),
			advancedFilterDam: _.debounce(function (query) { this.findDams('nid_id', query) }, 500),
			advancedFilterProjectNumber: _.debounce(function (query) { this.find('projectNumber', query) }, 500),
			advancedFilterName: _.debounce(function (query) { this.find('name', query) }, 500),
			advancedFilterAeAssignment: _.debounce(function (query) { this.find('aeAssignment', query) }, 500)
		},
		methods: {
			getDb() {
				let sort = this.table.reverse ? this.table.sort + '_desc' : this.table.sort;
				let filter = this.table.filter !== null ? `&filter=${encodeURIComponent(this.table.filter)}` : '';

				let filterComplex = '';
				let filterValues = [];
				if (!this.isNullOrEmpty(this.advancedFilter.dam)) {
					filterValues.push(`dam:${this.advancedFilter.dam}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.appNum)) {
					filterValues.push(`appNum:${this.advancedFilter.appNum}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.projectNumber)) {
					filterValues.push(`projectNumber:${this.advancedFilter.projectNumber}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.name)) {
					filterValues.push(`name:${this.advancedFilter.name}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.status)) {
					filterValues.push(`status:${this.advancedFilter.status}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.category)) {
					filterValues.push(`category:${this.advancedFilter.category}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.phase)) {
					filterValues.push(`phase:${this.advancedFilter.phase}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.aeAssignment)) {
					filterValues.push(`aeAssignment:${this.advancedFilter.aeAssignment}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.landRightsStatus)) {
					filterValues.push(`landRightsStatus:${this.advancedFilter.landRightsStatus}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.coe404PermitStatus)) {
					filterValues.push(`coe404PermitStatus:${this.advancedFilter.coe404PermitStatus}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.tpwdArrpStatus)) {
					filterValues.push(`tpwdArrpStatus:${this.advancedFilter.tpwdArrpStatus}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.omAgreementStatus)) {
					filterValues.push(`omAgreementStatus:${this.advancedFilter.omAgreementStatus}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.sponsorStatus)) {
					filterValues.push(`sponsorStatus:${this.advancedFilter.sponsorStatus}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.landRightsStatusDate.start) || !this.isNullOrEmpty(this.advancedFilter.landRightsStatusDate.end)) {
					let s = this.advancedFilter.landRightsStatusDate.start;
					let e = this.advancedFilter.landRightsStatusDate.end;
					s = s === null ? 'any' : s;
					e = e === null ? 'any' : e;
					filterValues.push(`landRightsStatusDate:${s},${e}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.expectedCompletionDate.start) || !this.isNullOrEmpty(this.advancedFilter.expectedCompletionDate.end)) {
					let s = this.advancedFilter.expectedCompletionDate.start;
					let e = this.advancedFilter.expectedCompletionDate.end;
					s = s === null ? 'any' : s;
					e = e === null ? 'any' : e;
					filterValues.push(`expectedCompletionDate:${s},${e}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.completionSubmissionDate.start) || !this.isNullOrEmpty(this.advancedFilter.completionSubmissionDate.end)) {
					let s = this.advancedFilter.completionSubmissionDate.start;
					let e = this.advancedFilter.completionSubmissionDate.end;
					s = s === null ? 'any' : s;
					e = e === null ? 'any' : e;
					filterValues.push(`completionSubmissionDate:${s},${e}`);
				}

				if (filterValues.length > 0) filterComplex = '&filterComplex=' + filterValues.join('|');

				return this.$http.get(`${this.apiUrl}?sort=${sort}&page=${this.table.currentPage}&perPage=${this.table.itemsPerPage}${filter}${filterComplex}`, this.getTokenHeader());
			},
			async filterChg() {
				this.table.currentPage = 1;
				_.debounce(await this.get(), 500);
			},
			async pageChg(page) {
				this.table.currentPage = page;
				await this.get();
			},
			async sortChg(ctx) {
				this.table.sort = ctx.sortBy;
				this.table.reverse = ctx.sortDesc;
				this.table.specialSort = undefined;

				await this.get();
			},
			async get() {
				this.page.errors = [];
				this.table.loading = true;

				try {
					const response1 = await this.$http.get(`${this.apiUrl}/options`, this.getTokenHeader());
					this.setOptions = response1.data;

					const response = await this.getDb();
					this.log(response.data);

					if (response.data.items.length == 0 && this.table.currentPage > 1) {
						this.table.currentPage--;
						await this.get();
					}

					this.items = response.data.items;
					this.table.total = response.data.total;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.table.loading = false;
				this.page.loading = false;
			},
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				await this.get();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async findDams(field, query) {
				try {
					const response = await this.$http.get(`/dams/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options.dam = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async clearFilter() {
				this.table.filter = null;
				await this.get();
			},
			async clearAdvancedFilter() {
				this.advancedFilter = {
					dam: null,
					appNum: null,
					projectNumber: null,
					name: null,
					status: null,
					category: null,
					phase: null,
					aeAssignment: null,
					landRightsStatus: null,
					landRightsStatusDate: {
						start: null,
						end: null
					},
					coe404PermitStatus: null,
					tpwdArrpStatus: null,
					omAgreementStatus: null,
					sponsorStatus: null,
					expectedCompletionDate: {
						start: null,
						end: null
					},
					completionSubmissionDate: {
						start: null,
						end: null
					},
					options: {
						dam: [],
						appNum: [],
						projectNumber: [],
						name: [],
						aeAssignment: []
					}
				};

				await this.get();
			}
		}
	}
</script>