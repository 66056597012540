<template>
	<div>
		<auth-container :page="page">
			<h2 class="mb-4">O&amp;M 31.529.A Funding Report</h2>

			<b-card bg-variant="light">
				<b-row>
					<b-col md="6">
						<div class="form-group row">
							<label for="reportOptions.filterType" class="col-lg-3 col-form-label">Query by</label>
							<div class="col-lg-9">
								<b-form-select v-model="reportOptions.filterType" id="reportOptions.filterType">
									<b-form-select-option :value="0">Fiscal Year</b-form-select-option>
									<b-form-select-option :value="1">Calendar Year</b-form-select-option>
									<b-form-select-option :value="2">Date Range</b-form-select-option>
								</b-form-select>
							</div>
						</div>

						<div class="form-group row" v-if="reportOptions.filterType === 2">
							<label for="reportOptions.startDate" class="col-lg-3 col-form-label">Date Range</label>
							<div class="col-lg-9">
								<div class="d-flex align-items-center justify-content-start">
									<div class="mr-2">
										<b-input-group>
											<b-form-datepicker id="reportOptions.startDate" v-model="reportOptions.startDate"
															   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

											<b-input-group-append class="pointer">
												<b-input-group-text @click="reportOptions.startDate = null">
													<font-awesome-icon :icon="['fas', 'times']" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</div>
									<div>
										<b-input-group>
											<b-form-datepicker id="reportOptions.endDate" v-model="reportOptions.endDate"
															   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

											<b-input-group-append class="pointer">
												<b-input-group-text @click="reportOptions.endDate = null">
													<font-awesome-icon :icon="['fas', 'times']" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</div>
								</div>
							</div>
						</div>

						<div class="form-group row" v-else>
							<label for="reportOptions.startYear" class="col-lg-3 col-form-label">Year Range</label>
							<div class="col-lg-9">
								<div class="d-flex align-items-center justify-content-start">
									<div class="mr-2">
										<b-form-input v-model="reportOptions.startYear" type="number" placeholder="Start"></b-form-input>
									</div>
									<div>
										<b-form-input v-model="reportOptions.endYear" type="number" placeholder="End"></b-form-input>
									</div>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>

				<hr class="my-3" />
				<div>
					<json-to-csv :api-url="`omreports/proposalsfunded`" use-api-post :api-form-data="reportOptions"
								 :field-labels="labels.funded"
								 :filename="`om-31.529.a-funded-proposals`"
								 text="Download Funded CSV" class="mr-2"></json-to-csv>
					<json-to-csv :api-url="`omreports/proposalsunfunded`" use-api-post :api-form-data="reportOptions"
								 :field-labels="labels.funded"
								 :filename="`om-31.529.a-unfunded-proposals`"
								 text="Download Un-funded CSV" class="mr-2"></json-to-csv>
				</div>
			</b-card>
		</auth-container>
	</div>
</template>

<script>
	export default {
		name: 'OmProposalFundingReport',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					noData: false
				},
				reportOptions: {
					filterType: 0,
					startYear: null,
					endYear: null,
					startDate: null,
					endDate: null,
					sponsorId: null,
					practiceIds: [],
					trendInterval: 0
				},
				options: {
					fiscalYears: [],
				},
				labels: {
					funded: {
						referenceNumber: 'Reference-Number',
						contractExecutionDate: 'Contract-Execution-Date',
						amount: 'Amount',
						fy: 'FY',
						grantee: 'Grantee'
					},
					unfunded: {
						referenceNumber: 'Reference-Number',
						dateApplicationReceived: 'Date-Application-Received',
						amount: 'Amount',
						applicant: 'Applicant'
					}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`funds/list`, this.getTokenHeader());
					this.log(response.data);

					this.options.fiscalYears = response.data;
					if (this.options.fiscalYears.length > 0) {
						this.reportOptions.startYear = this.options.fiscalYears[0];
						this.reportOptions.endYear = this.options.fiscalYears[0];
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>