<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<div v-if="!page.saveConfirmShow">
				<b-form @submit.prevent="save">
					<b-form-group label="Dam">
						<b-typeahead v-model="query.dam"
									 :data="options.dams"
									 :serializer="s => s.name" @hit="item.damId = $event.id"
									 :max-matches="100" :minMatchingChars="1">
							<template slot="append">
								<b-input-group-text @click="query.dam = ''" class="pointer">
									<font-awesome-icon :icon="['fas', 'times']" />
								</b-input-group-text>
							</template>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Practice Code">
						<b-typeahead v-model="query.practice"
									 :data="options.practices"
									 :serializer="s => s.name" @hit="getPractice($event.id)"
									 :max-matches="100" :minMatchingChars="1">
							<template slot="append">
								<b-input-group-text @click="clearPractice" class="pointer">
									<font-awesome-icon :icon="['fas', 'times']" />
								</b-input-group-text>
							</template>
							<template slot="suggestion" slot-scope="{ data, htmlText }">
								<div>
									<span v-html="htmlText"></span> - {{data.description}}
								</div>
							</template>
						</b-typeahead>
					</b-form-group>

					<b-card v-if="selectedPractice.show" class="my-3">
						<error-list :errors="selectedPractice.errors"></error-list>
						<div v-if="selectedPractice.errors.length < 1 && selectedPractice.item !== null" class="small">
							<div>
								<strong>{{selectedPractice.item.practiceCode}}</strong> - {{selectedPractice.item.description}}
							</div>
							<div>
								Cost: ${{selectedPractice.item.costPerUnit | number(2)}} / {{lower(selectedPractice.item.units)}}
							</div>
							<div v-if="!isNullOrEmpty(selectedPractice.item.comments)">
								Comments: {{selectedPractice.item.comments}}
							</div>
						</div>
					</b-card>

					<b-row>
						<b-col md>
							<b-form-group label="Units Performed" :invalid-feedback="requiredFeedback($v.item.unitsPerformed)">
								<b-form-input type="number" required step="any" @input="calcTotals"
											  v-model.trim="$v.item.unitsPerformed.$model" :state="getValidState($v.item.unitsPerformed)" />
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Unit Cost" :invalid-feedback="requiredFeedback($v.item.unitCost)">
								<b-input-group prepend="$">
									<b-form-input type="number" required step="any" @input="calcTotals"
												  v-model.trim="$v.item.unitCost.$model" :state="getValidState($v.item.unitCost)" />
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md>
							<b-form-group label="Total Invoiced Amount">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any" v-model="calculations.totalInvoiced" readonly />
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="State 90% Amount">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any" v-model="calculations.stateAmount" readonly />
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Required Match">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any" v-model="calculations.requiredMatch" readonly />
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md>
							<b-form-group label="In-Kind Match Deduction" :invalid-feedback="requiredFeedback($v.item.inkindMatchDeduction)">
								<b-input-group prepend="$">
									<b-form-input type="number" required step="any" @input="calcRemainingMatch"
												  v-model.trim="$v.item.inkindMatchDeduction.$model" :state="getValidState($v.item.inkindMatchDeduction)" />

									<template #append>
										<b-button variant="secondary" @click="copyReqMatchToInkind" title="Get from required match amount"><font-awesome-icon :icon="['fas', 'paste']" /></b-button>
									</template>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Remaining Match Required">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any" v-model="calculations.remainingMatch" readonly />
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md>
							<b-form-group label="Total O&amp;M Paid" :invalid-feedback="requiredFeedback($v.item.totalOmPaid)">
								<b-input-group prepend="$">
									<b-form-input type="number" required step="any" @input="calcTotalsFromOm"
												  v-model.trim="$v.item.totalOmPaid.$model" :state="getValidState($v.item.totalOmPaid)" />

									<template #append>
										<b-button variant="secondary" @click="copyStateToTotalOm" title="Get from state 90% amount"><font-awesome-icon :icon="['fas', 'paste']" /></b-button>
									</template>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-checkbox v-model.trim="$v.item.noAdminPayment.$model" @change="calcTotalsFromOm">Do not include state admin. payment</b-form-checkbox>
						</b-col>
					</b-row>

					<b-row>
						<b-col md>
							<b-form-group label="State Admin. Payment">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any" v-model="calculations.stateAdminPaid" readonly />
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Total Paid">
								<b-input-group prepend="$">
									<b-form-input type="number" step="any" v-model="calculations.totalPaid" readonly />
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<b-form-group label="Proposed Cost (Approved O&amp;M)" :invalid-feedback="requiredFeedback($v.item.proposedCost)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim="$v.item.proposedCost.$model" :state="getValidState($v.item.proposedCost)" />
						</b-input-group>
					</b-form-group>

					<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)">
						<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="3"></b-form-textarea>
					</b-form-group>

					<div class="my-3 d-flex align-items-center">
						<save-button :saving="page.saving" class="mr-2" />
						<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
						<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
							<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
							You have errors in the form.
						</div>
					</div>
				</b-form>
			</div>
			<div v-else>
				<b-form-group label="Add a note to describe changes?" description="Optional">
					<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
				</b-form-group>

				<div class="my-3">
					<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
					<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'ReimbursementActivityForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			dam: {
				type: Object,
				required: false
			},
			practice: {
				type: Object,
				required: false
			}
		},
		data() {
			return {
				apiUrl: 'omreimbursementrequestactivities',
				page: {
					loading: true,
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					dams: [],
					practices: []
				},
				query: {
					dam: '',
					practice: ''
				},
				selectedPractice: {
					loading: false,
					errors: [],
					show: false,
					item: null
				},
				calculations: {
					totalInvoiced: 0,
					stateAmount: 0,
					requiredMatch: 0,
					remainingMatch: 0,
					stateAdminPaid: 0,
					totalPaid: 0
				}
			}
		},
		computed: {
			queryDam() {
				return this.query.dam;
			},
			queryPractice() {
				return this.query.practice;
			}
		},
		validations: {
			item: {
				requestId: { required, integer },
				damId: { integer },
				practiceId: { integer },
				unitsPerformed: { required, decimal },
				unitCost: { required, decimal },
				isInkindMatch: {},
				inkindMatchDeduction: { required, decimal },
				totalOmPaid: { required, decimal },
				proposedCost: { required, decimal },
				noAdminPayment: {},
				notes: {}
			}
		},
		watch: {
			queryDam: _.debounce(function (query) { this.findDams(query) }, 500),
			queryPractice: _.debounce(function (query) { this.findPractices(query) }, 500)
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.calcTotals();
				this.calcTotalsFromOm();
				this.calcRemainingMatch();

				if (!this.isNullOrEmpty(this.dam)) this.query.dam = this.dam.name;
				if (!this.isNullOrEmpty(this.practice)) {
					this.query.practice = this.practice.name;
					await this.getPractice(this.item.practiceId);
				}
				this.page.loading = false;
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;
				this.logValidations();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.page.errors.length < 1) {
						this.item.isInkindMatch = this.item.inkindMatchDeduction > 0;

						if (this.isUpdate) {
							this.page.saveConfirmShow = true;
						} else {
							try {
								const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
								this.log(response.data);

								this.$emit('change');
							} catch (error) {
								if (this.isApiUnauthorized(error)) this.page.showLogin = true;
								else this.page.errors = this.logError(error);
							}
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			logValidations() {
				this.log(this.$v);
			},
			async findDams(query) {
				try {
					const response = await this.$http.get(`/dams/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					this.options.dams = !this.isNullOrEmpty(response.data) ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async findPractices(query) {
				try {
					const response = await this.$http.get(`/omactivities/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					this.options.practices = !this.isNullOrEmpty(response.data) ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			cancel() {
				this.$emit('cancel');
			},
			async getPractice(id) {
				this.item.practiceId = id;
				this.selectedPractice.show = true;
				this.selectedPractice.loading = true;
				this.selectedPractice.errors = [];

				try {
					const response = await this.$http.get(`omactivities/${id}`, this.getTokenHeader());
					this.log(response.data);
					this.selectedPractice.item = response.data.activity;
				} catch (error) {
					this.selectedPractice.errors = this.logError(error);
				}

				this.selectedPractice.loading = false;
			},
			clearPractice() {
				this.query.practice = '';
				this.selectedPractice.item = null;
				this.selectedPractice.show = false;
			},
			calcTotals() {
				let total = this.roundAsNumber(this.item.unitsPerformed * this.item.unitCost, 2);

				this.calculations.totalInvoiced = total;
				this.calculations.stateAmount = this.roundAsNumber(total * 0.9, 2);
				this.calculations.requiredMatch = this.roundAsNumber(total * 0.1, 2);
			},
			copyReqMatchToInkind() {
				this.item.inkindMatchDeduction = this.calculations.requiredMatch;
				this.calcRemainingMatch();
			},
			copyStateToTotalOm() {
				this.item.totalOmPaid = this.calculations.totalInvoiced;
				this.calcTotalsFromOm();
			},
			calcTotalsFromOm() {
				this.calculations.stateAdminPaid = this.item.noAdminPayment ? 0 : this.roundAsNumber(this.item.totalOmPaid * 0.05, 2);
				this.calculations.totalPaid = this.item.noAdminPayment ? this.item.totalOmPaid : this.roundAsNumber(this.item.totalOmPaid * 1.05, 2);
			},
			calcRemainingMatch() {
				this.calculations.remainingMatch = this.calculations.requiredMatch - this.item.inkindMatchDeduction;
			}
		}
	}
</script>
