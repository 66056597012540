<template>
	<auth-container :page="page">
		<h2 class="mb-4">SWCD# {{item.id}}</h2>

		<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
			<b-tab title="Overview" active>
				<b-row>
					<b-col md>
						<b-table-simple small>
							<b-tbody>
								<b-tr>
									<b-th>SWCD#</b-th>
									<b-td>{{item.id}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Name</b-th>
									<b-td>{{item.name}}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</b-col>
					<b-col md>
						<div class="map-container w-100" style="height:400px">
							<l-map ref="leafmap" style="height: 100%; width:100%" :zoom="map.zoom" :bounds="map.bounds" :center="map.center" @mousemove="mapMove">
								<l-control-layers position="topright"></l-control-layers>
								<l-tile-layer v-for="tileProvider in mapBase.tileProviders"
											  :key="tileProvider.name"
											  :name="tileProvider.name"
											  :visible="tileProvider.visible"
											  :url="tileProvider.url"
											  :attribution="tileProvider.attribution"
											  layer-type="base" />

								<l-layer-group layer-type="overlay" name="SWCDs">
									<l-geo-json :geojson="map.layers.swcds.geojson" :options="map.layers.swcds.options" :options-style="map.layers.swcds.style"></l-geo-json>
								</l-layer-group>

								<l-control position="bottomleft">
									<div v-if="map.currentCoords !== undefined">Cursor position: {{map.currentCoords.lat | number(3)}}, {{map.currentCoords.lng | number(3)}}</div>
								</l-control>
							</l-map>
						</div>
					</b-col>
				</b-row>

				<fixed-action-bar>
					<back-button />
					<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
				</fixed-action-bar>
			</b-tab>
			<b-tab title="Edit" v-if="!isReadOnly">
				<swcd-form ref="swcdForm" :item="item" is-update @change="changesSaved"></swcd-form>
			</b-tab>
			<b-tab title="Change Log">
				<grid-view :api-url="`home/logs/Swcds/${itemId}`" use-filter small :fields="table.fields"
						   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
						   collection-description="change log"
						   item-name="log">
					<back-button />
				</grid-view>
			</b-tab>
		</b-tabs>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this SWCD?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</auth-container>
</template>

<script>
	import L from 'leaflet';
	import { LMap, LTileLayer, LControl, LControlLayers, LGeoJson, LLayerGroup } from 'vue2-leaflet';
	import 'leaflet/dist/leaflet.css';
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';
	import SwcdForm from './SwcdForm.vue';
	var bbox = require('geojson-bbox');

	export default {
		name: 'SwcdEdit',
		components: {
			SwcdForm, LMap, LTileLayer, LControl, LControlLayers, LGeoJson, LLayerGroup, GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				item: {},
				map: {
					zoom: 7,
					bounds: null,
					center: [31.224, -98.897],
					currentCoords: undefined,
					layers: {
						swcds: {
							geojson: null,
							options: {},
							style: {
								weight: 1,
								opacity: 1,
								color: '#9dd123',
								fillOpacity: 0.5,
								fillColor: '#ceff5e'
							}
						}
					}
				},
				table: {
					fields: [
						{ key: 'activityDate', label: 'Date', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } },
						{ key: 'userName', label: 'User', sortable: true },
						{ key: 'description', label: 'Description', sortable: true }
					],
					sort: 'activityDate',
					reverse: true,
					itemsPerPage: 100
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`swcds/${this.$route.params.id}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.swcd;

					const response2 = await this.$http.get(`home/swcdmap/${this.$route.params.id}`, this.getTokenHeader());
					this.log(response2.data);
					this.map.layers.swcds.geojson = response2.data.swcds;
					this.map.layers.swcds.options = {
						onEachFeature: this.mapSwcdsOnEachFeature
					};
					this.setMapBounds();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.page.tabIndex = 1;
				this.$refs.swcdForm.launchToast();
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`swcds/${this.$route.params.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: 'SwcdList' });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			mapMove(e) {
				this.map.currentCoords = e.latlng;
			},
			setMapBounds() {
				if (!this.isNullOrEmpty(this.item.geometry)) {
					let bounds = bbox(this.map.layers.swcds.geojson);
					this.log(bounds);
					this.map.bounds = L.latLngBounds([[bounds[3], bounds[2]], [bounds[1], bounds[0]]]);
				} 
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			},
			mapSwcdsOnEachFeature() {
				return (feature, layer) => {
					let popText = `<div>SWCD# ${feature.properties.id} - ${feature.properties.name}</div>`;

					layer.bindTooltip(
						popText,
						{ permanent: false, sticky: true });
				};
			}
		}
	}
</script>