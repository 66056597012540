<template>
	<div id="login">
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<div v-if="user.userName !== undefined && !user.emailConfirmed" class="container-fluid py-4">
				<b-card bg-variant="light" style="max-width:400px" class="mx-auto text-center">
					<error-list :errors="account.confirm.errors"></error-list>
					<b-card-text v-if="!account.confirm.sent">
						Please check your email to confirm your account.
					</b-card-text>
					<b-card-text v-else>
						Confirmation sent! Please check your email.
						If you do not receive anything after one hour, please try again or <a :href="`mailto:${contactEmail}`">contact support</a>.
					</b-card-text>
					<save-button :saving="account.confirm.saving" text="Re-send email" @click.native="resendConfirmation" />
				</b-card>
			</div>
			<div v-else>
				<b-form :validated="page.validated" @submit.prevent="login" class="form-signin py-3" v-if="!recovery.show">
					<h1 v-if="showHeader" class="h3 my-3 font-weight-normal">Please log in</h1>

					<error-list :errors="page.errors"></error-list>

					<label for="email" class="sr-only">User name</label>
					<b-form-input id="userName" type="text" v-model="form.userName" placeholder="User name" required autofocus />

					<label for="password" class="sr-only">Password</label>
					<b-form-input id="password" type="password" v-model="form.password" placeholder="Password" required />

					<div class="mt-3">
						<save-button :saving="page.saving" @click.native="page.validated = true" text="Log in" />
					</div>
					<p class="mt-3 mb-0" v-if="false">
						<b-link @click="loadRecoveryForm">Forgot your password?</b-link>
						<br /><router-link to="/register">Register for a new account.</router-link>
					</p>
				</b-form>

				<b-form :validated="page.validated" @submit.prevent="recover" class="form-signin py-3" v-if="recovery.show">
					<h1 v-if="showHeader" class="h3 my-3 font-weight-normal">Recover your account</h1>

					<div v-if="recovery.sent">
						<p>
							Request received. If an account matches the email address provided, you will receive instructions for resetting your password.
							Please contact support if you have any problems.
						</p>
					</div>
					<div v-else>
						<p>
							Enter the email address associated with your account and we'll send you instructions for resetting your password.
						</p>

						<error-list :errors="page.errors"></error-list>

						<label for="email" class="sr-only">Email address</label>
						<b-form-input id="email" type="email" v-model="recovery.email" placeholder="Email address" required autofocus />

						<div class="mt-3">
							<save-button :saving="page.saving" @click.native="page.validated = true" text="Send recovery information" />
						</div>
					</div>

					<p class="mt-3 mb-0">
						<b-link @click="recovery.show = false">Back to log-in form</b-link>
					</p>
				</b-form>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'LoginForm',
		props: {
			showHeader: {
				type: Boolean,
				default: false
			},
			noRedirect: {
				type: Boolean,
				default: false
			},
			redirectRoute: {
				type: Object,
				default: null
			}
		},
		data: function() {
			return {
				page: {
					errors: [],
					loading: false,
					validated: false,
					saving: false
				},
				form: {
					userName: '',
					password: ''
				},
				recovery: {
					email: '',
					show: false,
					sent: false
				},
				account: {
					confirm: {
						errors: [],
						saving: false,
						sent: false
					}
				}
			}
		},
		methods: {
			async login() {
				this.page.errors = [];
				this.page.saving = true;
				this.page.validated = true;
				if (!this.noRedirect) this.page.loading = true;

				try {
					const response = await this.$http.post('auth/login', this.form);
					this.log(response.data);

					localStorage.setItem('auth_token', response.data.auth_token);

					if (!this.noRedirect) {
						if (!this.isNullOrEmpty(this.redirectRoute)) {
							this.$router.push(this.redirectRoute).catch(err => { this.log(err); });
						}
						window.location.reload(true);
					} else {
						this.$store.commit('login', {
							token: response.data.auth_token,
							user: response.data.identity
						});
						this.page.validated = false;

						this.$emit('success', true);
					}
				} catch (error) {
					this.page.errors = this.logError(error);
					this.logout(false);
					this.$emit('success', false);
					this.page.loading = false;
				}

				this.page.saving = false;
			},
			loadRecoveryForm() {
				this.page.errors = [];
				this.page.validated = false;
				this.recovery.show = true;
			},
			async recover() {
				this.page.errors = [];
				this.page.saving = true;
				this.page.validated = true;

				try {
					const response = await this.$http.post('account/forgotpassword', { email: this.recovery.email });
					this.log(response.data);
					this.recovery.sent = true;
					this.page.validated = false;
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.saving = false;
			},
			async resendConfirmation() {
				this.account.confirm.errors = [];
				this.account.confirm.saving = true;

				try {
					const response = await this.$http.post('account/resendconfirmation', {}, this.getTokenHeader());
					this.log(response.data);
					this.account.confirm.sent = true;
				} catch (error) {
					this.account.confirm.errors = this.logError(error);
				}

				this.account.confirm.saving = false;
			}
		}
	}
</script>

<style scoped>
	.form-signin {
		width: 100%;
		max-width: 400px;
		margin: auto;
	}

		.form-signin .form-control {
			position: relative;
			box-sizing: border-box;
			height: auto;
			padding: 10px;
		}

			.form-signin .form-control:focus {
				z-index: 2;
			}

		.form-signin input[type="text"] {
			margin-bottom: -1px;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}

		.form-signin input[type="password"] {
			margin-bottom: 10px;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
</style>
