<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Design Phase</h2>

		<sr-design-form :item="item" :project="project" :set-options="setOptions"></sr-design-form>
	</auth-container>
</template>

<script>
	import SrDesignForm from './SrDesignForm.vue';
	export default {
		name: 'SrDesignCreate',
		components: {
			SrDesignForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					applicationId: null,
					designer: null,
					m30Agent: null,
					m30Status: null,
					m30Date: null,
					m30Notes: null,
					m60Agent: null,
					m60Status: null,
					m60Date: null,
					m60Notes: null,
					m90Agent: null,
					m90Status: null,
					m90Date: null,
					m90Notes: null,
					finalAgent: null,
					finalStatus: null,
					finalDate: null,
					finalNotes: null,
					finalContractDate: null
				},
				project: null,
				setOptions: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response2 = await this.$http.get(`srdesigns/options`, this.getTokenHeader());
					this.setOptions = response2.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				if (!this.isNullOrEmpty(this.$route.query.appId)) {
					try {
						const response = await this.$http.get(`srprojects/getname/${this.$route.query.appId}`, this.getTokenHeader());
						this.project = response.data;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.loading = false;
			}
		}
	}
</script>