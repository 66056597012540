<template>
	<auth-container :page="page">
		<h2 class="mb-4">Sponsor Information</h2>

		<sponsor-form :item="item"></sponsor-form>
	</auth-container>
</template>

<script>
	import SponsorForm from './SponsorForm.vue';
	export default {
		name: 'SponsorCreate',
		components: {
			SponsorForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					id: 0,
					name: null,
					type: null,
					address: null,
					city: null,
					zip: null
				}
			}
		}
	}
</script>