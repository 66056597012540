<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<b-form @submit.prevent="save">
				<b-form-group label="Project" :invalid-feedback="requiredFeedback($v.query.project)">
					<b-typeahead v-model.trim="$v.query.project.$model" required placeholder="Type to search projects by number, name, or dam..."
								 :data="options.projects == null ? [] : options.projects"
								 :serializer="s => s.name" @hit="item.id = $event.id;"
								 :max-matches="100" :minMatchingChars="1">
					</b-typeahead>
				</b-form-group>

				<b-form-group label="Report Date" :invalid-feedback="requiredFeedback($v.item.reportDate)">
					<b-form-input type="date" v-model.trim="$v.item.reportDate.$model" :state="getValidState($v.item.reportDate)"></b-form-input>
				</b-form-group>

				<div class="mt-4 mb-3 d-flex align-items-center">
					<save-button :saving="page.saving" class="mr-2" />
					<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
					<b-button v-if="isAuthorized(roleNames.admin) && isUpdate" type="button" variant="danger" @click="remove" class="ml-auto">Delete</b-button>
				</div>
			</b-form>
		</div>
	</div>
</template>

<script>
	import _ from 'underscore';
	import { required, integer } from 'vuelidate/lib/validators';

	export default {
		name: 'SrPhaseForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			project: {
				type: Object,
				required: false
			}
		},
		data() {
			return {
				page: {
					loading: false,
					errors: [],
					validated: false,
					saving: false
				},
				options: {
					projects: []
				},
				query: {
					project: ''
				}
			}
		},
		validations: {
			item: {
				id: { required, integer },
				name: { required },
				include: {},
				reportDate: {}
			},
			query: {
				project: { required }
			}
		},
		watch: {
			queryProject: _.debounce(function (query) { this.findProjects(query) }, 500)
		},
		created() {
			this.get();
		},
		computed: {
			queryProject() {
				return this.query.project;
			}
		},
		methods: {
			async get() {
				if (!this.isNullOrEmpty(this.project)) {
					this.query.project = this.project.name;
				}
				if (this.isUpdate) {
					this.item.reportDate = this.dateForForm(this.item.reportDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.page.errors.length < 1) {
						try {
							const response = await this.$http.put(`srprojects/savephase`, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$emit('change');
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async remove() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.page.errors.length < 1) {
						try {
							let data = {
								id: this.item.id,
								name: this.item.name,
								include: false,
								reportDate: null
							};
							const response = await this.$http.put(`srprojects/savephase`, data, this.getTokenHeader());
							this.log(response.data);

							this.$emit('change');
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			cancel() {
				this.$emit('cancel');
			},
			async findProjects(query) {
				try {
					//const response = await this.$http.get(`/srprojects/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					const response = await this.$http.post(`/srprojects/find-id-post`, { value: query }, this.getTokenHeader());
					this.options.projects = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>
